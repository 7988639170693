import type { OBSEvent } from '@/interfaces/OBSEvent';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): OBSEvent => {
  const data = doc.data();

  return {
    id: doc.id,
    channelId: data?.channelId,
    obsId: data?.obsId,
    type: data?.type,
    userId: data?.userId,
    createdAt: data?.createdAt.toDate(),
  };
};
