import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Divider, List, CircularProgress } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {
  selectIsVisibleOnMobile,
  selectDrawerLoading,
  selectDrawerItems,
} from '@/store/selectors/drawer';
import { getDrawerStages, getDrawerCamps } from '@/store/asyncThunks/drawer';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import Profile from './Profile';
import StyledDrawer from './styled/Drawer';
import Nav from '../styled/Nav';
import AddLinkButton from './styled/AddLinkButton';
import ListItemText from '../styled/ListItemText';
import ListItem from '../styled/ListItem';
import ProgressContainer from '../styled/ProgressContainer';
import DisplayName from './DisplayName';
import ListSubheader from './styled/ListSubheader';
import CampName from './CampName';

interface DrawerProps {
  itemPath: string;
  showAddLink?: boolean;
  onAddLinkClick?: () => void;
}

const Drawer: FC<DrawerProps> = ({
  showAddLink = false,
  itemPath,
  onAddLinkClick,
}) => {
  const dispatch = useAppDispatch();

  const items = useAppSelector(selectDrawerItems);
  const loading = useAppSelector(selectDrawerLoading);
  const isVisibleOnMobile = useAppSelector(selectIsVisibleOnMobile);

  const params = useParams<{ id?: string }>();
  const [activeItem, setActiveItem] = useState<string | null>(null);

  useEffect(() => {
    setActiveItem(params.id ?? null);
  }, [params.id]);

  useEffect(() => {
    const promise = dispatch(getDrawerStages({}));
    return () => {
      promise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    const promise = dispatch(getDrawerCamps({}));
    return () => {
      promise.abort();
    };
  }, [dispatch]);

  return (
    <StyledDrawer
      isVisibleOnMobile={isVisibleOnMobile}
      anchor="left"
      open
      variant="persistent"
    >
      <Profile />
      <Divider />
      {loading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      <List
        component={Nav}
        style={{ marginBottom: 120 }}
        subheader={<ListSubheader>Screen list</ListSubheader>}
      >
        {items.map((item) => {
          switch (item.type) {
            case 'stage':
              return (
                <ListItem
                  key={item.id}
                  button
                  selected={activeItem === item.id}
                  component={Link}
                  to={`${itemPath}/${item.id}`}
                >
                  <ListItemText primary={<DisplayName stage={item} />} />
                </ListItem>
              );
            case 'camp':
              return (
                <ListItem
                  key={item.id}
                  button
                  selected={activeItem === item.id}
                  component={Link}
                  to={`camp${itemPath}/${item.id}`}
                >
                  <ListItemText primary={<CampName camp={item} />} />
                </ListItem>
              );
            default:
              return <ListItem />;
          }
        })}
      </List>
      {showAddLink && (
        <AddLinkButton
          onClick={onAddLinkClick}
          variant="contained"
          color="primary"
        >
          Add link
        </AddLinkButton>
      )}
    </StyledDrawer>
  );
};

export default Drawer;
