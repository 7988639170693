import styled from 'styled-components';
import { MobileStepper } from '@mui/material';

export default styled(MobileStepper).attrs({
  variant: 'progress',
  position: 'static',
})`
  background: transparent;

  .MuiMobileStepper-progress {
    width: 100%;
  }
`;
