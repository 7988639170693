import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getStage, getCurrentlyPlayed } from '@/store/asyncThunks/stage';
import { getEffects } from '@/store/effect';
import { selectToken } from '@/store/selectors/auth';
import { selectStage } from '@/store/selectors/stage';
import { useAppDispatch } from '@/store';
import { useAppSelector } from '@/store/hooks';

const emptyPromise = {
  abort() {},
};

const useAddEffectsData = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const stage = useAppSelector(selectStage);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id) {
      promise = dispatch(getStage(params.id));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id) {
      promise = dispatch(getCurrentlyPlayed(params.id));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && stage) {
      promise = dispatch(getEffects({ stageId: stage.id, event: stage.event }));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token, stage.id, stage.event]);
};

export default useAddEffectsData;
