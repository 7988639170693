import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';
import breakpoint from '@/breakpoint';

export default styled(Avatar)`
  &.MuiAvatar-root {
    width: 40px;
    height: 40px;

    ${breakpoint('xl')`
      width: 48px;
      height: 48px;
    `}
  }
`;
