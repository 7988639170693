/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { TableRow } from '@mui/material';
import withTheme from '@mui/styles/withTheme';

export default withTheme(styled(({ active, ...rest }) => (
  <TableRow {...rest} />
))<{
  active: boolean;
}>`
  border: ${(props) =>
    props.active && `2px solid ${props.theme.palette.primary.main}`};
  cursor: pointer;
`);
