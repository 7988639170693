/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import dot from 'dot-object';
import {
  salvationSettings,
  stagesCollection,
  stageSettingsCollection,
} from '@/firebaseConfig';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { onValue, update } from 'firebase/database';
import axios from 'axios';
import type {
  SalvationSettings,
  Settings,
  UpdateSettings,
} from '../interfaces';

const apiService: ApiService = apiServiceFactory();

export const getSalvationSettings = createAsyncThunk(
  'settings/getSalvationSettings',
  () =>
    new Promise<SalvationSettings>((resolve) => {
      onValue(
        salvationSettings,
        (snapshot) => {
          resolve(snapshot.val());
        },
        { onlyOnce: true }
      );
    })
);

export const updateSalvationSettings = createAsyncThunk(
  'settings/updateSalvationSettings',
  async (values: Partial<SalvationSettings>) => {
    await update(salvationSettings, values);

    return new Promise<SalvationSettings>((resolve) => {
      onValue(
        salvationSettings,
        (snapshot) => {
          resolve(snapshot.val());
        },
        { onlyOnce: true }
      );
    });
  }
);

export const getStageSettings = createAsyncThunk(
  'settings/getStageSettings',
  async (stageId: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getSubresource<Settings>(
      'stages',
      stageId,
      'settings',
      {},
      source.token
    );
  }
);

export const updateStageSettings = createAsyncThunk(
  'settings/updateStageSettings',
  async ({ stageId, values }: { stageId: string; values: UpdateSettings }) => {
    const stageRef = await getDoc(doc(stagesCollection, stageId));
    const stageData = stageRef.data();
    const ref = doc(stageSettingsCollection, stageId);
    let d = await getDoc(ref);

    if (!d.exists()) {
      await setDoc(
        ref,
        { ...values, stageId, type: stageData?.event },
        { merge: true }
      );
    } else {
      await updateDoc(ref, dot.dot(values));
    }

    d = await getDoc(ref);
    return d.data();
  }
);
