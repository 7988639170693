import type { FC } from 'react';
import React, { memo } from 'react';
import { Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout as logoutFunc } from '@/store/asyncThunks/auth';
import { selectUser } from '@/store/selectors/auth';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import ProfileBox from './styled/ProfileBox';
import Avatar from './styled/Avatar';
import ProfileBoxRight from './styled/ProfileBoxRight';
import Email from './styled/Email';

const Profile: FC = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      <ProfileBox>
        <Avatar alt={user?.displayName} src={user?.photoURL ?? ''} />
        <ProfileBoxRight>
          <Email>{user?.email}</Email>
          <Button color="primary" onClick={() => dispatch(logoutFunc())}>
            Log out
          </Button>
        </ProfileBoxRight>
      </ProfileBox>
      {user?.fireworksAdmin && (
        <>
          <Divider />
          <Button onClick={() => navigate('/fireworks')}>Fireworks</Button>
          <Divider />
          <Button onClick={() => navigate('/teleport')}>Teleport</Button>
        </>
      )}
    </>
  );
};

export default memo(Profile);
