import type { FC, ChangeEvent } from 'react';
import React, {
  useState,
  useMemo,
  useEffect,
  Suspense,
  useCallback,
} from 'react';
import type { SelectChangeEvent } from '@mui/material';
import {
  Tab,
  Table,
  TableBody,
  Divider,
  CircularProgress,
  TableRow as MaterialTableRow,
  TableCell,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import _ from 'lodash';
import type { CampLink } from '@myclipo/bm-admin-common';
import { useAppSelector } from '@/store/hooks';
import { selectFestivalDates } from '@/store/selectors/stage';
import { removeCampLinksBulk as removeCampLinksBulkFunc } from '@/store/asyncThunks/camp';
import {
  selectCampLinks,
  selectCampLinksLoading,
} from '@/store/selectors/camp';
import { useAppDispatch } from '@/store';
import TableHead from './TableHead';
import ProgressContainer from '../styled/ProgressContainer';
import EditLink from './EditLink';
import MobileHidden from '../styled/MobileHidden';
import MobileVisible from '../styled/MobileVisible';
import Tabs from './styled/Tabs';

interface CampScheduleProps {
  onDateTabChange: (date: string) => void;
}

const CampSchedule: FC<CampScheduleProps> = ({ onDateTabChange }) => {
  const dispatch = useAppDispatch();

  const links = useAppSelector(selectCampLinks);
  const campLinksLoading = useAppSelector(selectCampLinksLoading);
  const festivalDates = useAppSelector(selectFestivalDates);

  const TableRow = React.lazy(() => import('./TableRow'));

  const groupedLinks = useMemo(() => _.groupBy(links, 'date'), [links]);

  const [tab, setTab] = useState(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [editLinkOpen, setEditLinkOpen] = useState<boolean>(false);
  const [currentCampLink, setCurrentCampLink] = useState<CampLink | null>(null);
  const [currentGroup, setCurrentGroup] = useState<string>('');

  const allSelected = useMemo(
    () => selectedRows.length > 0 && selectedRows.length === links.length,
    [selectedRows, links]
  );

  const currentLinks = useMemo<CampLink[]>(
    () => _.get(groupedLinks, currentGroup, []),
    [groupedLinks, currentGroup]
  );

  useEffect(() => {
    onDateTabChange(festivalDates[tab]);
    setCurrentGroup(festivalDates[tab]);
    setSelectedRows([]);
  }, [festivalDates, tab, onDateTabChange]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setTab(event.target.value as number);
  };

  const handleEditLinkClose = () => {
    setEditLinkOpen(false);
    setCurrentCampLink(null);
  };

  const handleEditLink = (link: CampLink) => {
    setCurrentCampLink(link);
    setEditLinkOpen(true);
  };

  const handleSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      const allIds = currentLinks.map((video) => video.id);
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(_.without(selectedRows, id));
    }
  };

  const handleDeleteAll = useCallback(async () => {
    dispatch(
      removeCampLinksBulkFunc({ ids: selectedRows, date: currentGroup })
    );
  }, [dispatch, currentGroup, selectedRows]);

  return (
    <>
      {campLinksLoading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      {!campLinksLoading && (
        <>
          <MobileHidden>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="inherit"
              onChange={handleTabChange}
            >
              {festivalDates.map((group: string) => (
                <Tab key={group} label={group} />
              ))}
            </Tabs>
          </MobileHidden>
          <MobileVisible>
            <Divider />
            <Select
              fullWidth
              value={tab}
              onChange={handleSelectChange}
              displayEmpty
              style={{ textAlign: 'center', padding: 8 }}
            >
              {festivalDates.map((group: string, index: number) => (
                <MenuItem key={group} value={index}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </MobileVisible>
          <Divider />
          <Table>
            <TableHead
              allSelected={allSelected}
              onSelectAll={handleSelectAll}
              onDeleteAll={handleDeleteAll}
            />
            <TableBody>
              {currentLinks.map((video) => (
                <Suspense key={video.id} fallback={<tr />}>
                  <TableRow
                    link={video}
                    selected={selectedRows.indexOf(video.id) !== -1}
                    onSelect={handleRowSelect}
                    onEditLink={handleEditLink}
                  />
                </Suspense>
              ))}
              {links.length === 0 && (
                <MaterialTableRow>
                  <TableCell align="center" colSpan={5}>
                    <Typography variant="h6">No result</Typography>
                  </TableCell>
                </MaterialTableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
      {currentCampLink && (
        <EditLink
          open={editLinkOpen}
          onClose={handleEditLinkClose}
          link={currentCampLink}
        />
      )}
    </>
  );
};

export default CampSchedule;
