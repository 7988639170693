import styled from 'styled-components';
import { Container } from '@mui/material';
import { smartphoneBreakpoint } from '@/breakpoint';

export default styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  background-image: url('/assets/bm_background_blur.webp');
  background-repeat: no-repeat;
  background-size: cover;

  ${smartphoneBreakpoint`
    align-items: start;
  `}
`;
