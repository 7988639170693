/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doc, getDocs, updateDoc } from 'firebase/firestore/lite';
import { teleportCollection } from '@/firebaseConfig';
import type { TeleportState } from '@/store/interfaces';

export const getTeleport = createAsyncThunk(
  'teleport/getTeleport',
  async (): Promise<TeleportState> => {
    const snapshot = await getDocs(teleportCollection);
    const [d] = snapshot.docs;

    const data = d.data();
    return { id: d.id, enabled: !!data.enabled, loading: !!data.loading };
  }
);

export const updateTeleport = createAsyncThunk(
  'teleport/updateTeleport',
  async ({ id, value }: { id: string; value: boolean }) => {
    const ref = doc(teleportCollection, id);
    await updateDoc(ref, { enabled: value });
    return value;
  }
);

const initialState: TeleportState = {
  id: '',
  enabled: false,
  loading: false,
};

export const teleportSlice = createSlice({
  name: 'teleport',
  initialState,
  reducers: {
    setEnabled(state, { payload }: PayloadAction<TeleportState['enabled']>) {
      state.enabled = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeleport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeleport.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTeleport.fulfilled, (state, action) => {
        state.id = action.payload.id;
        state.enabled = action.payload.enabled;
        state.loading = false;
      });
    builder.addCase(updateTeleport.fulfilled, (state, action) => {
      state.enabled = action.payload;
    });
  },
});

export const { setEnabled } = teleportSlice.actions;

export default teleportSlice.reducer;
