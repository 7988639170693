import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { memo } from 'react';
import PickerContainer from './styled/PickerContainer';

const HeaderBasic: FC = () => (
  <PickerContainer>
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={2} />
      <Grid item xs={1}>
        <div style={{ paddingLeft: 8 }}>
          <Typography variant="h6">Enabled</Typography>
        </div>
      </Grid>
    </Grid>
  </PickerContainer>
);

export default memo(HeaderBasic);
