import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  Typography,
  Slider,
  CircularProgress,
} from '@mui/material';
import type {
  OBSVideo as OBSVideoEntity,
  UpdateOBSVideo,
} from '@myclipo/bm-admin-common';
import { OBSVideoServiceType } from '@myclipo/bm-admin-common';
import { compact, isEmpty } from 'lodash';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SchedulerControlledContext from '@/context/SchedulerControlledContext';
import { selectOBSChannelStatus } from '@/store/selectors/obs';
import { selectIsObsAdmin, selectIsSuperAdmin } from '@/store/selectors/auth';
import { startStream, stopStream } from '@/store/asyncThunks/obs';
import { useAppDispatch } from '@/store';
import { useNavigate } from 'react-router-dom';
import StyledTableRow from '../Playlist/TableRow/styled/TableRow';
import Title from '../Playlist/TableRow/styled/Title';
import BottomText from '../Playlist/TableRow/styled/BottomText';
import SliderContainer from '../Playlist/TableRow/styled/SliderContainer';
import ControlsContainer from '../styled/ControlsContainer';

interface TableRowProps {
  obsVideo: OBSVideoEntity;
  active: boolean;
  onRowClick: (id: string, url: string | null) => void;
  onSettingChange: (id: string, data: UpdateOBSVideo) => void;
}

const explodeUrl = (videoUrl: string | null) => {
  if (videoUrl && videoUrl.length > 0) {
    const url = new URL(videoUrl);

    const splitUrl = url.pathname.split('/');
    const [host, path, streamKey] = compact(splitUrl);

    return [url.protocol, host, path, streamKey];
  }
  return ['', '', '', ''];
};

const TableRow: FC<TableRowProps> = ({
  obsVideo,
  active,
  onRowClick,
  onSettingChange,
}) => {
  const { schedulerControlled } = useContext(SchedulerControlledContext);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const dispatch = useAppDispatch();
  const obsChannelStatus = useSelector(selectOBSChannelStatus);
  const isObsAdmin = useSelector(selectIsObsAdmin);

  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const status = useMemo(
    () => obsChannelStatus[obsVideo.id],
    [obsVideo.id, obsChannelStatus]
  );

  const formattedUrl = useMemo<string>(() => {
    const urlObs = obsVideo.urlObs ?? '';
    if (urlObs.length > 0) {
      const [protocol, host, path] = explodeUrl(urlObs);

      return `${protocol}//${host}/${path}`;
    }
    return '';
  }, [obsVideo]);

  const streamKey = useMemo<string>(() => {
    if (!isEmpty(obsVideo.urlObs)) {
      const [, , , key] = explodeUrl(obsVideo.urlObs);

      return key;
    }

    const [, , , key] = explodeUrl(obsVideo.url);

    return key;
  }, [obsVideo.urlObs]);

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleGreenScreenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSettingChange(obsVideo.id, { greenScreen: checked });
    },
    [onSettingChange, obsVideo.id]
  );

  const handleMuteAudioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSettingChange(obsVideo.id, { audio: { muted: checked } });
    },
    [onSettingChange, obsVideo.id]
  );

  const handleScreenSizeChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(obsVideo.id, {
        screenSize: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, obsVideo.id]
  );

  const handleScreenOffsetYChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(obsVideo.id, {
        screenOffsetY: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, obsVideo.id]
  );

  const handleScreenBrightnessChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(obsVideo.id, {
        screenBrightness: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, obsVideo.id]
  );

  const handleClick = useCallback(() => {
    if (!schedulerControlled || isSuperAdmin) {
      onRowClick(obsVideo.id, obsVideo.url);
    }
  }, [onRowClick, obsVideo.id, schedulerControlled]);

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleStartChannelAgain = useCallback(() => {
    dispatch(startStream(obsVideo.id));
    setAnchorEl(null);
  }, [dispatch, startStream, obsVideo.id]);

  const handleStopOnlyChannel = useCallback(() => {
    dispatch(stopStream(obsVideo.id));
    setAnchorEl(null);
  }, [dispatch, stopStream, obsVideo.id]);

  return (
    <StyledTableRow active={!!active} onClick={handleClick}>
      <TableCell style={{ width: 180, textAlign: 'center' }} align="left">
        {status === 'Running' && (
          <PauseCircleOutlineIcon
            style={{ fontSize: 50 }}
            color={active ? 'primary' : undefined}
          />
        )}
        {['Idle', 'Not available'].includes(status) && (
          <PlayCircleOutlineIcon
            style={{ fontSize: 50 }}
            color={active ? 'primary' : undefined}
          />
        )}
        {!['Idle', 'Running', 'Not available'].includes(status) && (
          <CircularProgress />
        )}
      </TableCell>
      <TableCell align="left" onClick={(event) => event.stopPropagation()}>
        <Title active={!!active}>{obsVideo.title}</Title>
        <BottomText active={!!active}>OBS Server:</BottomText>
        <BottomText active={!!active}>{formattedUrl}</BottomText>

        <BottomText active={!!active}>
          Stream key: {streamKey}
          &nbsp;
          <CopyToClipboard text={streamKey} onCopy={handleCopy}>
            <IconButton size="large">
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </CopyToClipboard>
          {copied && 'Copied!'}
        </BottomText>
        {obsVideo.serviceType === OBSVideoServiceType.Medialive && (
          <BottomText active={!!active}>
            Channel status:
            {status}
          </BottomText>
        )}

        <ControlsContainer>
          <FormControlLabel
            style={{ flex: 1 }}
            control={
              <Checkbox
                checked={obsVideo.greenScreen}
                onChange={handleGreenScreenChange}
                color="primary"
                onClick={(event) => event.stopPropagation()}
              />
            }
            label="Green screen"
          />

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen size
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}%`}
              defaultValue={obsVideo.screenSize}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              step={10}
              marks
              min={50}
              max={200}
              onChangeCommitted={handleScreenSizeChange}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen offset Y
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}cm`}
              defaultValue={obsVideo.screenOffsetY}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}cm`}
              step={10}
              marks
              min={-250}
              max={250}
              onChangeCommitted={handleScreenOffsetYChange}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen brightness
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}`}
              defaultValue={obsVideo.screenBrightness}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}`}
              step={0.1}
              marks
              min={0}
              max={1}
              onChangeCommitted={handleScreenBrightnessChange}
            />
          </SliderContainer>

          <FormControlLabel
            style={{ flex: 1, marginLeft: 8 }}
            control={
              <Checkbox
                checked={obsVideo.audio.muted}
                onChange={handleMuteAudioChange}
                color="primary"
                onClick={(event) => event.stopPropagation()}
              />
            }
            label="Mute audio"
          />
        </ControlsContainer>
      </TableCell>
      <TableCell align="right" onClick={(event) => event.stopPropagation()}>
        {!schedulerControlled && (
          <>
            <IconButton onClick={handleMoreClick} size="large">
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMoreClose}
            >
              {obsVideo.serviceType === OBSVideoServiceType.Medialive && (
                <>
                  <MenuItem
                    disabled={status !== 'Idle'}
                    onClick={handleStartChannelAgain}
                  >
                    Start streaming channel (incurs costs)
                  </MenuItem>
                  <MenuItem
                    disabled={status === 'Idle'}
                    onClick={handleStopOnlyChannel}
                  >
                    Stop streaming channel
                  </MenuItem>
                </>
              )}
              {isObsAdmin && (
                <MenuItem
                  onClick={() =>
                    navigate(`/obs-channel-history/${obsVideo.id}`)
                  }
                >
                  Channel history
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

export default TableRow;
