/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doc, getDocs, updateDoc } from 'firebase/firestore/lite';
import { fireworksCollection } from '@/firebaseConfig';
import type { RootState } from '.';

export interface FireworkState {
  id: string;
  isEnabled: boolean;
  loading: boolean;
}

export const getFireworksEnabled = createAsyncThunk(
  'firework/getFireworksEnabled',
  async () => {
    const snapshot = await getDocs(fireworksCollection);
    const [d] = snapshot.docs;
    const data = d.data();

    return { id: d.id, isEnabled: !!data.isEnabled, ...data };
  }
);

export const updateFireworksEnabled = createAsyncThunk(
  'firework/updateFireworksEnabled',
  async ({ id, value }: { id: string; value: boolean }) => {
    await updateDoc(doc(fireworksCollection, id), { isEnabled: value });
    return value;
  }
);

const initialState: FireworkState = {
  id: '',
  isEnabled: false,
  loading: false,
};

export const fireworkSlice = createSlice({
  name: 'firework',
  initialState,
  reducers: {
    setEnabled(state, { payload }: PayloadAction<FireworkState['isEnabled']>) {
      state.isEnabled = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFireworksEnabled.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFireworksEnabled.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFireworksEnabled.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.isEnabled = action.payload.isEnabled;
      state.loading = false;
    });
    builder.addCase(updateFireworksEnabled.fulfilled, (state, action) => {
      state.isEnabled = action.payload;
    });
  },
});

export const selectAreFireworksEnabled = (state: RootState) =>
  state.firework.isEnabled;
export const selectFireworksId = (state: RootState) => state.firework.id;
export const selectFireworksLoading = (state: RootState) =>
  state.firework.loading;

export const { setEnabled } = fireworkSlice.actions;

export default fireworkSlice.reducer;
