import type { UserWithAccess } from '@myclipo/bm-admin-common';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): UserWithAccess => {
  const data = doc.data();
  return {
    id: doc.id,
    userId: data?.userId,
    stageId: data?.stageId,
    start: data?.start ?? null,
    end: data?.end ?? null,
    email: data?.email,
    isDJ: data?.isDJ ?? false,
  };
};
