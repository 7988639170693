import type { FC } from 'react';
import React from 'react';
import { CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auth as firebaseAuth } from '@/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import Card from '../styled/Card';
import CardActions from '../styled/CardActions';
import Button from '../styled/FullWidthButton';
import TextField from './styled/TextField';
import Stepper from '../styled/Stepper';
import CardForm from '../styled/CardForm.';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().min(2, 'Too short').required('Required'),
});

const AccountRecoveryCard: FC = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async ({ email }, { resetForm }) => {
      try {
        await sendPasswordResetEmail(firebaseAuth, email);
      } catch (error) {
        resetForm();
      }

      resetForm();
      navigate('/account-recovery/complete');
    },
  });
  return (
    <CardForm onSubmit={formik.handleSubmit}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            Account recovery
          </Typography>
          <Stepper
            steps={3}
            activeStep={1}
            nextButton={<div />}
            backButton={<div />}
          />
          <TextField
            name="email"
            label="Enter your email"
            fullWidth
            margin="normal"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
          />
        </CardContent>
        <CardActions vertical>
          <Button onClick={() => navigate('/login')}>Back</Button>
          <Button type="submit">Next</Button>
        </CardActions>
      </Card>
    </CardForm>
  );
};

export default AccountRecoveryCard;
