import type { FC } from 'react';
import React, { useCallback } from 'react';
import StyledDialog from '@/components/styled/Dialog';
import DialogContent from '@/components/styled/SearchListDialogContent';
import DialogTitle from '@/components/styled/DialogTitle';
import IconContainer from '@/components/styled/DialogIconContainer';
import {
  Typography,
  Divider,
  IconButton,
  Grid,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import FullWidthButton from '@/components/styled/FullWidthButton';
import * as Yup from 'yup';
import { useAppDispatch } from '@/store';
import { addStages } from '@/store/asyncThunks/stage';
import { useNavigate } from 'react-router-dom';

interface DialogProps {
  onClose?: () => void;
  open: boolean;
}

const validationSchema = Yup.object().shape({
  event: Yup.string().required(),
  worldName: Yup.string().optional(),
  amount: Yup.number().required().min(1),
});

const Dialog: FC<DialogProps> = ({ onClose = () => {}, open }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const formik = useFormik({
    validationSchema,
    initialValues: {
      event: '',
      worldName: '',
      amount: 1,
    },
    onSubmit: async (values) => {
      const ids = await dispatch(addStages(values)).unwrap();
      if (ids.length > 0) {
        navigate(`/stage/${ids[0]}`);
      }
      onClose();
    },
  });

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <DialogTitle>
        <IconContainer>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </IconContainer>
        <Typography align="center" variant="h5">
          Add screens
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xs={12}>
              <TextField
                name="event"
                label="Event"
                variant="outlined"
                fullWidth
                value={formik.values.event}
                onChange={formik.handleChange}
                error={!!(formik.touched.event && formik.errors.event)}
                helperText={formik.touched.event && formik.errors.event}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="worldName"
                label="World name"
                variant="outlined"
                fullWidth
                value={formik.values.worldName}
                onChange={formik.handleChange}
                error={!!(formik.touched.worldName && formik.errors.worldName)}
                helperText={formik.touched.worldName && formik.errors.worldName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="amount"
                label="Amount"
                type="number"
                variant="outlined"
                fullWidth
                inputProps={{ min: 1, step: 1 }}
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={!!(formik.touched.amount && formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
              />
            </Grid>
          </Grid>
          <FullWidthButton type="submit">Add screens</FullWidthButton>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

export default Dialog;
