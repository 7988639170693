import type { FC } from 'react';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Typography,
  Divider,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  List,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { debounce, includes, without } from 'lodash';
import { useParams } from 'react-router-dom';
import type { User } from '@myclipo/bm-admin-common';
import { UserRole } from '@myclipo/bm-admin-common';
import SearchField from '@/components/styled/SearchField';
import FullWidthButton from '@/components/styled/FullWidthButton';
import DialogContent from '@/components/styled/SearchListDialogContent';
import DialogTitle from '@/components/styled/DialogTitle';
import IconContainer from '@/components/styled/DialogIconContainer';
import StyledDialog from '@/components/styled/Dialog';
import {
  selectDialogUsersLoading,
  selectDialogUsers,
} from '@/store/selectors/dialog';
import ProgressContainer from '@/components/styled/ProgressContainer';
import Nav from '@/components/styled/Nav';
import ListItemText from '@/components/styled/ListItemText';
import ListItem from '@/components/styled/ListItem';
import { addUserWithAccess as addUserWithAccessFunc } from '@/store/asyncThunks/camp';
import useIsHD from '@/hooks/useIsHD';
import { getDialogUsers } from '@/store/asyncThunks/dialog';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';

interface DialogProps {
  onClose: (event: any) => void;
  open: boolean;
  disabledTabs?: UserRole[];
}

const tabRoleMap = new Map([[0, UserRole.Admin]]);

const Dialog: FC<DialogProps> = ({
  onClose,
  open,

  disabledTabs,
}) => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(selectDialogUsers);
  const usersLoading = useAppSelector(selectDialogUsersLoading);

  const [tab, setTab] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeItems, setActiveItems] = useState<string[]>([]);
  const params = useParams<{ id: string }>();
  const isHD = useIsHD();
  const getUsersDebounced = useMemo(
    () =>
      debounce(
        (arg?: Record<string, unknown>) => dispatch(getDialogUsers(arg)),
        500
      ),
    [dispatch]
  );

  useEffect(() => {
    if (includes(disabledTabs ?? [], UserRole.Admin)) {
      setTab(1);
    }
  }, [disabledTabs]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    setSearchValue('');
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleListItemClick = (id: string) => {
    if (includes(activeItems, id)) {
      setActiveItems(without(activeItems, id));
    } else {
      setActiveItems([...activeItems, id]);
    }
  };

  const handleClose = useCallback(() => {
    setActiveItems([]);
    setSearchValue('');
    onClose({});
  }, [onClose]);

  const handleAddUserToDisplay = useCallback(() => {
    activeItems.forEach((activeItem) => {
      const userWithAccess = {
        userId: activeItem,
      };
      dispatch(addUserWithAccessFunc({ campId: params.id!, userWithAccess }));
    });
    handleClose();
  }, [dispatch, activeItems, params.id, handleClose]);

  useEffect(() => {
    getUsersDebounced({ email: searchValue, role: tabRoleMap.get(tab) });
  }, [searchValue, getUsersDebounced, tab]);

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <DialogTitle>
        <IconContainer>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </IconContainer>
        <Typography align="center" variant="h5">
          Add user to camp
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Tabs
          centered
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab value={0} label="Display moderator" />
        </Tabs>
        <Divider />
        <SearchField
          placeholder="Search user"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
        {usersLoading && (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        )}
        {!usersLoading && (
          <List component={Nav} style={{ maxHeight: isHD ? 180 : 120 }}>
            {users.map((user: User) => (
              <ListItem
                key={user.uid}
                button
                selected={includes(activeItems, user.uid)}
                onClick={() => handleListItemClick(user.uid)}
              >
                <ListItemText primary={user.email} />
              </ListItem>
            ))}
          </List>
        )}
        <FullWidthButton onClick={handleAddUserToDisplay}>
          Add user to camp
        </FullWidthButton>
      </DialogContent>
    </StyledDialog>
  );
};

export default Dialog;
