import mapOBSEvent from '@/helpers/mapOBSEvent';
import type { OBSVideo, Stage, UpdateOBSVideo } from '@myclipo/bm-admin-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { chunk, flatten, orderBy as orderByLodash } from 'lodash';
import {
  obsEventsCollection,
  obsLinksCollection,
  stagesCollection,
} from '@/firebaseConfig';
import mapOBSLink from '@/helpers/mapOBSLink';
import mapStage from '@/helpers/mapStage';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import {
  arrayRemove,
  arrayUnion,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
  limit,
} from 'firebase/firestore/lite';
import {
  addDrawerOBSStage,
  removeDrawerOBSStage,
  updateDrawerOBS,
} from '../drawer';

const apiService: ApiService = apiServiceFactory();

export const getUsedChannelAndStageIds = createAsyncThunk(
  'obs/getUsedChannelAndStageIds',
  async (currentObsId: string) => {
    const { docs } = await getDocs(obsLinksCollection);

    const channelIds: number[] = [];
    const stageIds: string[] = [];

    docs.forEach((d) => {
      if (d.id !== currentObsId) {
        const data = d.data();

        if (data.channelId) {
          channelIds.push(data.channelId);
        }

        if (data.stageId) {
          stageIds.push(...data.stageId);
        }
      }
    });

    return { stageIds, channelIds };
  }
);

export const addStageToObs = createAsyncThunk(
  'obs/addStageToObs',
  async ({ stage, id }: { stage: Stage; id: string }, { dispatch }) => {
    const ref = doc(obsLinksCollection, id);
    await updateDoc(ref, {
      stageId: arrayUnion(stage.id),
    });

    dispatch(addDrawerOBSStage({ id, stage }));
    return { stage, id };
  }
);

export const removeStageFromObs = createAsyncThunk(
  'obs/removeStageFromObs',
  async ({ stageId, id }: { stageId: string; id: string }, { dispatch }) => {
    const ref = doc(obsLinksCollection, id);

    await updateDoc(ref, {
      stageId: arrayRemove(stageId),
    });

    dispatch(removeDrawerOBSStage({ id, stageId }));
    return { stageId, id };
  }
);

export const getOBSLinks = createAsyncThunk(
  'obs/getObsLinks',
  async (stageId: string, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    try {
      return await apiService.getAllSubresources<OBSVideo>(
        'stages',
        stageId,
        'obs-videos',
        {},
        source.token
      );
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data);
      }

      throw err;
    }
  }
);

export const getObsLinkById = createAsyncThunk(
  'obs/getObsLinkById',
  async (id: string) => {
    const ref = doc(obsLinksCollection, id);

    const d = await getDoc(ref);

    if (!d.exists()) {
      return null;
    }

    return mapOBSLink(d);
  }
);

export const getOBSStages = createAsyncThunk(
  'obs/getOBSStages',
  async (stageIds: string[]) => {
    const allDocs = await Promise.all(
      chunk(stageIds, 10).map(async (ch) => {
        const q = query(stagesCollection, where(documentId(), 'in', ch));
        const { docs } = await getDocs(q);
        return docs;
      })
    );

    const stages = flatten(allDocs).map(mapStage);
    return orderByLodash(stages, 'number');
  }
);

export const updateOBSLink = createAsyncThunk(
  'obs/updateOBSLink',
  async (
    { id, obs }: { id: string; obs: UpdateOBSVideo },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await apiService.update('obs', id, obs);
      dispatch(updateDrawerOBS({ ...obs, id }));

      return { ...obs, id };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const startStream = createAsyncThunk(
  'obs/startStream',
  async (id: string, { rejectWithValue }) => {
    try {
      await apiService.create(`obs/${id}/start-stream`, {});
      return { id };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const stopStream = createAsyncThunk(
  'obs/stopStream',
  async (id: string, { rejectWithValue }) => {
    try {
      await apiService.create(`obs/${id}/stop-stream`, {});
      return { id };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const getChannelHistory = createAsyncThunk(
  'obs/getChannelHistory',
  async (id: string) => {
    const q = query(
      obsEventsCollection,
      where('obsId', '==', id),
      orderBy('createdAt', 'desc'),
      limit(100)
    );
    const { docs } = await getDocs(q);

    return docs.map(mapOBSEvent);
  }
);
