import type { FC } from 'react';
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import ReactPlayer from './styled/ReactPlayer';
import ProgressContainer from './styled/ProgressContainer';

interface PlayerProps {
  url?: string;
}

const Player: FC<PlayerProps> = ({ url }) => {
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  return (
    <>
      {!playerReady && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      <ReactPlayer
        url={url}
        controls
        style={{ display: !playerReady && 'none' }}
        onReady={() => setPlayerReady(true)}
      />
    </>
  );
};

export default React.memo(Player);
