import React, { useState, forwardRef } from 'react';
import ScheduleComponent from '@/components/CampSchedule';
import EnterURL from '@/components/CampSchedule/EnterURL';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';

const Schedule = forwardRef<HTMLButtonElement>((props, ref) => {
  const [currentDateTab, setCurrentDateTab] = useState<string>('');

  return (
    <>
      <ScheduleComponent onDateTabChange={setCurrentDateTab} />
      <EnterURL ref={ref} date={currentDateTab} />
    </>
  );
});

export default Sentry.withErrorBoundary(Schedule, {
  fallback: ErrorFallbackComponent,
});
