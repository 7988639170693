/* eslint-disable import/prefer-default-export */
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { setSuccessMessage, setErrorMessage } from '../flashMessage';

const actions = { setSuccessMessage, setErrorMessage };

export const showMessage =
  (
    message: string,
    type: 'success' | 'error'
  ): ThunkAction<void, unknown, unknown, AnyAction> =>
  (dispatch) => {
    const action = get(actions, `set${capitalize(type)}Message`) as any;

    if (action !== undefined) {
      dispatch(action(message));

      setTimeout(() => {
        dispatch(action(null));
      }, 6000);
    }
  };
