import styled from 'styled-components';

export default styled.div`
  flex: 1;
  min-width: 25%;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
`;
