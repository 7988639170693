import type { FC } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import Head from '@/components/AccessToDisplays/Head';
import AccessToDisplaysComponent from '@/components/AccessToDisplays';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from '../styled/PanelContainer';
import useAccessToDisplaysData from './useAccessToDisplaysData';

const AccessToDisplays: FC = () => {
  const params = useParams<{ id: string }>();
  useAccessToDisplaysData();

  return params.id ? (
    <PanelContainer>
      <Head />
      <Divider />
      <AccessToDisplaysComponent />
    </PanelContainer>
  ) : (
    <div />
  );
};

export default Sentry.withErrorBoundary(AccessToDisplays, {
  fallback: ErrorFallbackComponent,
});
