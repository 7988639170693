/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import emptyStageSettings from '@/helpers/emptyStageSettings';
import type { SettingsState } from '@/store/interfaces';
import {
  getSalvationSettings,
  getStageSettings,
  updateSalvationSettings,
  updateStageSettings,
} from './asyncThunks/settings';

const initialState: SettingsState = {
  loading: false,
  current: emptyStageSettings(),
  salvation: {
    leftTower: {
      color: '',
      on: false,
    },
    rightTower: {
      color: '',
      on: false,
    },
    trees: {
      color: '',
      on: false,
    },
    title: {
      color: '',
      on: false,
    },
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStageSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStageSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getStageSettings.fulfilled, (state, action) => {
        Object.assign(state.current, action.payload);
        state.loading = false;
      });

    builder.addCase(updateStageSettings.fulfilled, (state, action) => {
      Object.assign(state.current, action.payload);
    });
    builder.addCase(getSalvationSettings.fulfilled, (state, action) => {
      state.salvation = action.payload;
    });
    builder.addCase(updateSalvationSettings.fulfilled, (state, action) => {
      state.salvation = action.payload;
    });
  },
});

export default settingsSlice.reducer;
