import type { RootState } from '..';

export const selectCamp = (state: RootState) => state.camp.current;
export const selectCampLinks = (state: RootState) => state.camp.links;
export const selectCampLinksLoading = (state: RootState) =>
  state.camp.campLinksLoading;
export const selectCampLoading = (state: RootState) => state.camp.campLoading;
export const selectCampUsersWithAccess = (state: RootState) =>
  state.camp.usersWithAccess;
export const selectLiveLinks = (state: RootState) => state.camp.liveLinks;
export const selectBackupLinks = (state: RootState) => state.camp.backupLinks;
export const selectLiveLinksLoading = (state: RootState) =>
  state.camp.liveLinksLoading;
export const selectBackupLinksLoading = (state: RootState) =>
  state.camp.backupLinksLoading;
