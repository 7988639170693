import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Divider,
  CircularProgress,
  Backdrop,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { find, pick } from 'lodash';
import Alert from '@mui/material/Alert';
import type {
  BackupVideo as BackupVideoEntity,
  UpdateBackupVideo,
  UpdateOBSVideo,
  UpdateSchedulerPlaylist,
  UpdateStreamImage,
} from '@myclipo/bm-admin-common';
import SchedulerControlledContext from '@/context/SchedulerControlledContext';
import {
  selectStageLoading,
  selectBackupVideos,
  selectStage,
  selectSchedulerPlaylist,
  selectAllVideos,
  selectAllVideosLoading,
} from '@/store/selectors/stage';
import {
  removeBackupVideo,
  updateBackupVideo,
  changeCurrentlyPlayed,
  updateSchedulerPlaylistSettings,
} from '@/store/asyncThunks/stage';
import { useAppDispatch } from '@/store';
import { selectOBSUpdating, selectOBSVideos } from '@/store/selectors/obs';
import { updateOBSLink } from '@/store/asyncThunks/obs';
import { isYoutubeLink } from '@/helpers/youtubeLinks';
import { setForceRefreshStages } from '@/store/drawer';
import { selectImages } from '@/store/selectors/image';
import { deleteStageImage, updateStageImage } from '@/store/asyncThunks/image';
import { useAppSelector } from '@/store/hooks';
import CurrentImage from '@/components/AddLink/Image/CurrentImage';
import { MediaType, VideoType } from '@myclipo/bm-admin-common';
import SchedulerPlaylistTableRow from '@/components/AddLink/SchedulerPlaylist/TableRow';
import ImageTableRow from '@/components/AddLink/Image/TableRow';
import ProgressContainer from '../styled/ProgressContainer';
import ChangeActiveVideo from './ChangeActiveVideo';
import EnterURL from './EnterURL';
import AddImage from './AddImage';
import Player from './Player';
import OBSVideoTableRow from './OBSVideo/TableRow';
import PlaylistTableRow from './Playlist/TableRow';

const BackupVideo: FC = () => {
  const dispatch = useAppDispatch();

  const obsUpdating = useAppSelector(selectOBSUpdating);
  const stageLoading = useAppSelector(selectStageLoading);
  const backupVideos = useAppSelector(selectBackupVideos);
  const stage = useAppSelector(selectStage);
  const obses = useAppSelector(selectOBSVideos);
  const images = useAppSelector(selectImages);
  const allVideos = useAppSelector(selectAllVideos);
  const allVideosLoading = useAppSelector(selectAllVideosLoading);
  const schedulerPlaylist = useAppSelector(selectSchedulerPlaylist);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [obsConfirmOpen, setOBSConfirmOpen] = useState<boolean>(false);
  const [imageConfirmOpen, setImageConfirmOpen] = useState<boolean>(false);
  const [schedulerPlaylistConfirmOpen, setSchedulerPlaylistConfirmOpen] =
    useState<boolean>(false);

  const [current, setCurrent] = useState<string | null>(null);

  const schedulerControlledContext = useMemo(
    () => pick(stage, 'schedulerControlled'),
    [stage.schedulerControlled]
  );

  const obsesMap = useMemo(
    () => new Map(obses.map((obs) => [obs.id, obs])),
    [obses]
  );

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setCurrent(null);
  };

  const handleOBSConfirmClose = () => {
    setOBSConfirmOpen(false);
    setCurrent(null);
  };

  const handleImageConfirmClose = () => {
    setImageConfirmOpen(false);
    setCurrent(null);
  };

  const handleSchedulerPlaylistConfirmClose = () => {
    setSchedulerPlaylistConfirmOpen(false);
    setCurrent(null);
  };

  const handleRowClick = (id: string) => {
    setCurrent(id);
    setConfirmOpen(true);
  };

  const handleOBSRowClick = (id: string) => {
    setCurrent(id);
    setOBSConfirmOpen(true);
  };

  const handleImageRowClick = (id: string) => {
    setCurrent(id);
    setImageConfirmOpen(true);
  };

  const handleSchedulerPlaylistRowClick = (id: string) => {
    setCurrent(id);
    setSchedulerPlaylistConfirmOpen(true);
  };

  const setNoVideo = useCallback(async () => {
    await dispatch(
      changeCurrentlyPlayed({
        stageId: stage.id,
        type: MediaType.None,
      })
    );
  }, [dispatch, stage]);

  const handleOBSConfirm = useCallback(async () => {
    if (current) {
      setPendingAction(true);

      if (stage.obs?.id === current) {
        await setNoVideo();
      } else {
        await dispatch(
          changeCurrentlyPlayed({
            stageId: stage.id,
            type: MediaType.Obs,
            videoId: current,
          })
        );
      }

      setPendingAction(false);
    }

    setOBSConfirmOpen(false);
    setCurrent(null);
  }, [dispatch, stage.id, stage.obs, current, setNoVideo]);

  const handleImageConfirm = useCallback(async () => {
    if (current) {
      setPendingAction(true);

      if (stage.image?.id === current) {
        await setNoVideo();
      } else {
        await dispatch(
          changeCurrentlyPlayed({
            stageId: stage.id,
            type: MediaType.Image,
            videoId: current,
          })
        );
      }

      setPendingAction(false);
    }

    setImageConfirmOpen(false);
    setCurrent(null);
  }, [dispatch, stage.id, stage.obs, current, setNoVideo]);

  const handleConfirm = useCallback(async () => {
    if (current) {
      setPendingAction(true);

      if (stage.backupVideo?.id === current) {
        await setNoVideo();
      } else {
        await dispatch(
          changeCurrentlyPlayed({
            stageId: stage.id,
            type: MediaType.BackupVideo,
            videoId: current,
          })
        );
      }

      setPendingAction(false);
      dispatch(setForceRefreshStages(true));
    }

    setConfirmOpen(false);
    setCurrent(null);
  }, [dispatch, stage.id, current, stage.backupVideo, setNoVideo]);

  const handleSchedulerPlaylistConfirm = useCallback(async () => {
    if (current) {
      setPendingAction(true);

      if (stage.schedulerPlaylist?.id === current) {
        await setNoVideo();
      } else {
        await dispatch(
          changeCurrentlyPlayed({
            stageId: stage.id,
            type: MediaType.SchedulerPlaylist,
            videoId: current,
          })
        );
      }

      setPendingAction(false);
      dispatch(setForceRefreshStages(true));
    }

    setSchedulerPlaylistConfirmOpen(false);
    setCurrent(null);
  }, [dispatch, stage.id, current, stage.schedulerPlaylist, setNoVideo]);

  const handleRowRemove = useCallback(
    async (id: string) => {
      setPendingAction(true);

      await dispatch(removeBackupVideo({ id, stageId: stage.id }));

      setPendingAction(false);
    },
    [dispatch, stage.id]
  );

  const playlistVideos = useMemo<BackupVideoEntity[]>(
    () =>
      backupVideos.map((backupVideo) => ({
        ...backupVideo,
        isActive:
          stage.backupVideo?.id === backupVideo.id &&
          !!stage.backupVideo?.active,
      })),
    [backupVideos, stage.backupVideo]
  );

  const activeVideo = useMemo(
    () => find(playlistVideos, 'isActive'),
    [playlistVideos]
  );

  const activeImage = useMemo(
    () => (stage.image?.active ? find(images, { id: stage.image.id }) : null),
    [stage.image, images]
  );

  const handleSettingChange = useCallback(
    async (id: string, data: UpdateBackupVideo) => {
      setPendingAction(true);

      await dispatch(
        updateBackupVideo({
          id,
          video: data,
        })
      );

      setPendingAction(false);
    },
    [dispatch]
  );

  const handleOBSSettingChange = useCallback(
    async (id: string, data: UpdateOBSVideo) => {
      setPendingAction(true);

      await dispatch(
        updateOBSLink({
          id,
          obs: data,
        })
      );

      setPendingAction(false);
    },
    [dispatch]
  );

  const handleImageSettingChange = useCallback(
    async (id: string, data: UpdateStreamImage) => {
      setPendingAction(true);

      await dispatch(
        updateStageImage({
          id,
          ...data,
        })
      );

      setPendingAction(false);
    },
    [dispatch]
  );

  const handleSchedulerPlaylistSettingChange = useCallback(
    async (data: UpdateSchedulerPlaylist) => {
      setPendingAction(true);

      await dispatch(
        updateSchedulerPlaylistSettings({
          stageId: stage.id,
          ...data,
        })
      );

      setPendingAction(false);
    },
    [dispatch, stage.id]
  );

  const handleImageRemove = useCallback(
    async (id: string) => {
      setPendingAction(true);

      await dispatch(deleteStageImage(id));

      setPendingAction(false);
    },
    [dispatch]
  );

  return (
    <>
      <Divider />
      <Alert severity="warning">
        Remember that the screen only changes its size when the stream is active
        (it works after some time, please wait patiently)
      </Alert>
      {stage.schedulerControlled && (
        <Alert severity="warning">
          Screen is currently controlled by Scheduler. Any changes to the
          currently played stream cannot be made.
        </Alert>
      )}
      {!!stage.backupVideo?.active &&
        activeVideo &&
        isYoutubeLink(activeVideo.url) && (
          <Alert severity="warning">
            Youtube live-streams are not supported. Youtube videos are often
            blocked. Please be advised that Youtube is not reliable.
          </Alert>
        )}
      {activeImage && <CurrentImage image={activeImage} />}
      {obses.length > 0 && !!stage.obs?.active && (
        <Player
          url={obsesMap.get(stage.obs?.id)?.url ?? undefined}
          type="obs"
        />
      )}
      {!!stage.schedulerPlaylist?.active && (
        <Player url={schedulerPlaylist.url} type="obs" />
      )}
      {!!stage.backupVideo?.active && (
        <Player url={activeVideo?.url} type="backup-video" />
      )}
      <Divider />
      {(stageLoading || allVideosLoading) && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      <SchedulerControlledContext.Provider value={schedulerControlledContext}>
        <Table>
          <TableBody>
            {allVideos.map((video) => {
              switch (video.type) {
                case VideoType.ObsVideo:
                  return (
                    <OBSVideoTableRow
                      key={video.id}
                      obsVideo={video}
                      active={stage.obs?.id === video.id && !!stage.obs?.active}
                      onRowClick={handleOBSRowClick}
                      onSettingChange={handleOBSSettingChange}
                    />
                  );
                case VideoType.SchedulerPlaylist:
                  return (
                    <SchedulerPlaylistTableRow
                      key={video.id}
                      schedulerPlaylist={video}
                      active={!!stage.schedulerPlaylist?.active}
                      onRowClick={handleSchedulerPlaylistRowClick}
                      onSettingChange={handleSchedulerPlaylistSettingChange}
                    />
                  );
                case VideoType.Image:
                  return (
                    <ImageTableRow
                      key={video.id}
                      image={video}
                      active={
                        stage.image?.active
                          ? stage.image?.id === video.id
                          : false
                      }
                      onClick={handleImageRowClick}
                      onRemove={handleImageRemove}
                      onSettingChange={handleImageSettingChange}
                    />
                  );
                case VideoType.BackupVideo:
                  return (
                    <PlaylistTableRow
                      key={video.id}
                      active={
                        stage.backupVideo?.active
                          ? stage.backupVideo?.id === video.id
                          : false
                      }
                      playlistVideo={video}
                      onClick={handleRowClick}
                      onRemove={handleRowRemove}
                      onSettingChange={handleSettingChange}
                    />
                  );
                default:
                  return <TableRow />;
              }
            })}
            {allVideos.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Typography variant="h6">No result</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SchedulerControlledContext.Provider>
      <EnterURL />
      <Divider />
      <AddImage />
      <ChangeActiveVideo
        open={confirmOpen}
        onConfirm={handleConfirm}
        onClose={handleConfirmClose}
      />
      <ChangeActiveVideo
        open={obsConfirmOpen}
        onConfirm={handleOBSConfirm}
        onClose={handleOBSConfirmClose}
      />
      <ChangeActiveVideo
        open={imageConfirmOpen}
        onConfirm={handleImageConfirm}
        onClose={handleImageConfirmClose}
      />

      <ChangeActiveVideo
        open={schedulerPlaylistConfirmOpen}
        onConfirm={handleSchedulerPlaylistConfirm}
        onClose={handleSchedulerPlaylistConfirmClose}
      />
      <Backdrop style={{ zIndex: 5000 }} open={pendingAction || obsUpdating}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BackupVideo;
