import styled from 'styled-components';
import { Button } from '@mui/material';
import breakpoint from '@/breakpoint';

const margin = 32;

export default styled(Button)`
  height: 50px;
  position: absolute;
  bottom: 0;
  min-width: calc(100% - ${2 * margin}px);

  &.MuiButtonBase-root {
    margin: ${margin}px;
    text-transform: none;
    font-size: 15px;

    ${breakpoint('lg')`
      font-size: 16px;
    `}

    ${breakpoint('xl')`
      font-size: 22px;
    `}
  }

  ${breakpoint('lg')`
     height: 58px;
  `}

  ${breakpoint('xl')`
    height: 68px;
  `}
`;
