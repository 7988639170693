import { useAppDispatch } from '@/store';
import { getChannelHistory } from '@/store/asyncThunks/obs';
import { selectOBSChannelHistory } from '@/store/selectors/obs';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import formatISO from 'date-fns/formatISO';
import type { FC } from 'react';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import ListItemIcon from '@mui/material/ListItemIcon';
import { upperFirst } from 'lodash';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from './styled/PanelContainer';

const ObsChannelHistory: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const channelHistory = useSelector(selectOBSChannelHistory);

  useEffect(() => {
    if (params.id) {
      const promise = dispatch(getChannelHistory(params.id));

      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [params.id, dispatch]);

  return params.id ? (
    <PanelContainer>
      <Grid style={{ padding: 24 }} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Channel {params.id} history</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <Divider />
            {channelHistory.map((item) => (
              <Fragment key={item.id}>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={upperFirst(item.type)}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {item.userId}
                        </Typography>{' '}
                        {formatISO(item.createdAt)}
                      </>
                    }
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </PanelContainer>
  ) : (
    <div />
  );
};

export default Sentry.withErrorBoundary(ObsChannelHistory, {
  fallback: ErrorFallbackComponent,
});
