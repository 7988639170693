import type { FC } from 'react';
import React from 'react';
import { Typography } from '@mui/material';
import StyledDialog from '@/components/styled/Dialog';
import Button from '@/components/styled/Button';
import ButtonsContainer from './styled/ButtonsContainer';
import DialogTitle from './styled/DialogTitle';
import DialogContent from './styled/DialogContent';

interface ChangeActiveVideoProps {
  onClose: (event: any) => void;
  onConfirm: () => void;
  open: boolean;
}

const ChangeActiveVideo: FC<ChangeActiveVideoProps> = ({
  onClose,
  onConfirm,
  open,
}) => (
  <StyledDialog onClose={onClose} open={open}>
    <DialogTitle>
      <Typography align="center" variant="h5">
        Change active video
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="subtitle1" gutterBottom align="center">
        Are you sure you want to change the active video?
      </Typography>
      <ButtonsContainer>
        <Button onClick={onConfirm}>Yes</Button>
        <Button onClick={onClose}>No</Button>
      </ButtonsContainer>
    </DialogContent>
  </StyledDialog>
);

export default ChangeActiveVideo;
