import styled from 'styled-components';
import { smartphoneBreakpoint } from '@/breakpoint';

export default styled.div`
  color: #fff;
  padding: 40px;
  display: flex;
  align-items: center;

  ${smartphoneBreakpoint`
    padding: 16px;
  `}
`;
