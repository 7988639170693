import type { FC } from 'react';
import React from 'react';
import {
  Table,
  TableBody,
  TableRow as MaterialTableRow,
  TableCell,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import type { CampLink, BackupCampLink } from '@myclipo/bm-admin-common';

import TableRow from './TableRow';

interface LinklistProps {
  links: Array<CampLink | BackupCampLink>;
  onRowClick?: (id: string, url: string) => void;
  onRowRemove?: (id: string, url: string) => void;
}

const Linklist: FC<LinklistProps> = ({ links, onRowClick, onRowRemove }) => (
  <Table>
    <TableBody>
      {links.map((link) => (
        <TableRow
          key={link.url}
          active={_.get(link, 'isActive', false)}
          link={link}
          onClick={onRowClick}
          onRemove={onRowRemove}
        />
      ))}
      {links.length === 0 && (
        <MaterialTableRow>
          <TableCell align="center" colSpan={5}>
            <Typography variant="h6">No result</Typography>
          </TableCell>
        </MaterialTableRow>
      )}
    </TableBody>
  </Table>
);

export default Linklist;
