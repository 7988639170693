/* eslint-disable no-param-reassign */
import type { Stage } from '@myclipo/bm-admin-common';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';
import {
  addStageToObs,
  getOBSLinks,
  getObsLinkById,
  getOBSStages,
  getUsedChannelAndStageIds,
  removeStageFromObs,
  startStream,
  stopStream,
  updateOBSLink,
  getChannelHistory,
} from './asyncThunks/obs';
import type { OBSState } from './interfaces/obs';

const initialState: OBSState = {
  list: [],
  ids: [],
  loading: false,
  updating: false,
  channelStatus: {},
  stages: [],
  searchedStages: [],
  usedChannelIds: [],
  channelHistory: [],
};

export const obsSlice = createSlice({
  name: 'obs',
  initialState,
  reducers: {
    setChannelStatus: (
      state,
      { payload }: PayloadAction<{ id: string; status: string }>
    ) => {
      state.channelStatus[payload.id] = payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUsedChannelAndStageIds.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ channelIds: number[]; stageIds: string[] }>
      ) => {
        state.usedChannelIds = payload.channelIds;
      }
    );
    builder.addCase(
      removeStageFromObs.fulfilled,
      (state, { payload }: PayloadAction<{ stageId: string; id: string }>) => {
        if (state.list) {
          const current = state.list.find((c) => c.id === payload.id);
          if (current && Array.isArray(current.stageId)) {
            current.stageId = current.stageId.filter(
              (s) => s !== payload.stageId
            );
          }
        }
      }
    );
    builder.addCase(
      addStageToObs.fulfilled,
      (state, { payload }: PayloadAction<{ stage: Stage; id: string }>) => {
        if (state.list) {
          const current = state.list.find((c) => c.id === payload.id);
          if (current && Array.isArray(current.stageId)) {
            current.stageId.push(payload.stage.id);
          }
        }
      }
    );
    builder.addCase(getOBSStages.fulfilled, (state, action) => {
      state.stages = action.payload;
    });

    builder
      .addCase(getOBSLinks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOBSLinks.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOBSLinks.fulfilled, (state, action) => {
        state.list = action.payload;
        state.ids = map(action.payload, 'id');
        state.loading = false;
      });

    builder
      .addCase(getObsLinkById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getObsLinkById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getObsLinkById.fulfilled, (state, action) => {
        state.list = action.payload ? [action.payload] : [];
        state.loading = false;
      });

    builder
      .addCase(updateOBSLink.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateOBSLink.fulfilled, (state, { payload }) => {
        state.list = state.list.map((current) =>
          current.id === payload.id ? Object.assign(current, payload) : current
        );
        state.updating = false;
      });

    builder.addCase(startStream.fulfilled, (state, { payload }) => {
      if (state.list) {
        state.list = state.list.map((current) =>
          current.id === payload.id
            ? { ...current, isStreaming: true }
            : current
        );
      }
    });
    builder.addCase(stopStream.fulfilled, (state, { payload }) => {
      if (state.list) {
        state.list = state.list.map((current) =>
          current.id === payload.id
            ? { ...current, isStreaming: false }
            : current
        );
      }
    });

    builder
      .addCase(getChannelHistory.pending, (state) => {
        state.channelHistory = [];
      })
      .addCase(getChannelHistory.rejected, (state) => {
        state.channelHistory = [];
      })
      .addCase(getChannelHistory.fulfilled, (state, { payload }) => {
        state.channelHistory = payload;
      });
  },
});

export const { setChannelStatus } = obsSlice.actions;

export default obsSlice.reducer;
