/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { chunk, flatten, identity, map, pickBy } from 'lodash';
import type {
  BackupVideo,
  CurrentlyPlayed,
  GroupedVideos,
  LiveVideo,
  MediaType,
  NewUserWithAccess,
  NewVideo,
  SchedulerPlaylist,
  Stage,
  UpdateBackupVideo,
  UpdateSchedulerPlaylist,
  UpdateStage,
  UpdateVideo,
  UserWithAccess,
  Video,
} from '@myclipo/bm-admin-common';
import { UserRole } from '@myclipo/bm-admin-common';
import axios from 'axios';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import {
  acceptedInvitesCollection,
  db,
  festivalDatesCollection,
  rateAdminStagesCollection,
  stagesCollection,
  usersWithAccessCollection,
  videosCollection,
} from '@/firebaseConfig';
import mapUserWithAccess from '@/helpers/mapUserWithAccess';
import type { QuerySnapshot } from 'firebase/firestore/lite';
import {
  arrayUnion,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore/lite';
import type { RootState } from '..';
import { updateDrawerStage } from '../drawer';

const apiService: ApiService = apiServiceFactory();

export const addStages = createAsyncThunk(
  'stage/addStages',
  async (
    {
      event,
      amount,
      worldName,
    }: { event: string; worldName: string; amount: number },
    { rejectWithValue }
  ) => {
    try {
      const { ids } = await apiService.create<
        { event: string; worldName: string; amount: number },
        { ids: string[] }
      >('stages', {
        event,
        amount,
        worldName,
      });

      return ids;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data);
      }

      throw err;
    }
  }
);

export const getStage = createAsyncThunk(
  'stage/getStage',
  async (id: string, { rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    try {
      const { stage } = await apiService.get<{ stage: Stage }>(
        'stages',
        id,
        {
          currentlyPlayedFields: ['null'],
        },
        source.token
      );

      return stage;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data);
      }

      throw err;
    }
  }
);

export const getStageVideos = createAsyncThunk(
  'stage/getStageVideos',
  async (id: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getSubresource<GroupedVideos>(
      'stages',
      id,
      'videos',
      {
        groupedVideos: true,
      },
      source.token
    );
  }
);

export const getLiveVideos = createAsyncThunk(
  'stage/getLiveVideos',
  async (stageId: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllSubresources<LiveVideo>(
      'stages',
      stageId,
      'videos/current',
      {},
      source.token
    );
  }
);

export const getBackupVideos = createAsyncThunk(
  'stage/getBackupVideos',
  async (stageId: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllSubresources<BackupVideo>(
      'stages',
      stageId,
      'backup-videos',
      {},
      source.token
    );
  }
);

export const refreshBackupVideos = createAsyncThunk(
  'stage/refreshBackupVideos',
  async (stageId: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllSubresources<BackupVideo>(
      'stages',
      stageId,
      'backup-videos',
      {},
      source.token
    );
  }
);

export const updateStage = createAsyncThunk(
  'stage/updateStage',
  async (
    { id, stage }: { id: string; stage: UpdateStage },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const cleanedObject = pickBy(stage, identity);

      await apiService.update<UpdateStage, void>('stages', id, cleanedObject);

      dispatch(refreshBackupVideos(id));
      dispatch(updateDrawerStage({ ...cleanedObject, id }));

      return cleanedObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateBurningManStage = createAsyncThunk(
  'stage/updateBurningManStage',
  async (
    { id, stage }: { id: string; stage: UpdateStage },
    { rejectWithValue }
  ) => {
    try {
      await apiService.update<UpdateStage, void>('stages', id, stage);

      return stage;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const addUserWithAccess = createAsyncThunk(
  'stage/addUserWithAccess',
  async (
    {
      stageId,
      userWithAccess,
    }: { stageId: string; userWithAccess: NewUserWithAccess },
    { rejectWithValue }
  ) => {
    try {
      return await apiService.create<NewUserWithAccess, UserWithAccess>(
        `stages/${stageId}/users-with-access`,
        userWithAccess
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const addUserWithAccessBulk = createAsyncThunk<
  UserWithAccess[],
  { stageId: string; userWithAccess: NewUserWithAccess[] }
>(
  'stage/addUserWithAccessBulk',
  async ({ stageId, userWithAccess }, { rejectWithValue }) => {
    try {
      return await apiService.create<NewUserWithAccess[], UserWithAccess[]>(
        `stages/${stageId}/users-with-access`,
        userWithAccess
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateUserWithAccess = createAsyncThunk(
  'stage/updateUserWithAccess',
  async (
    {
      id,
      userWithAccess,
    }: {
      id: string;
      userWithAccess: Partial<NewUserWithAccess>;
    },
    { rejectWithValue }
  ) => {
    try {
      const userWithAccessRef = doc(usersWithAccessCollection, id);

      await updateDoc(userWithAccessRef, userWithAccess);
      const updatedUserWithAccess = await getDoc(userWithAccessRef);

      return mapUserWithAccess(updatedUserWithAccess);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const getStageUsersWithAccess = createAsyncThunk<
  UserWithAccess[],
  { stageId: string; role?: UserRole[] }
>('stage/getStageUsersWithAccess', async ({ stageId, role }) => {
  const conditions = [where('stageId', '==', stageId)];

  if (role?.includes(UserRole.DJ) && !role?.includes(UserRole.Admin)) {
    conditions.push(where('isDJ', '==', true));
  } else if (role?.includes(UserRole.Admin) && !role?.includes(UserRole.DJ)) {
    conditions.push(where('isDJ', '==', false));
  }

  const snapshot = await getDocs(
    query(usersWithAccessCollection, orderBy('email'), ...conditions)
  );

  return snapshot.docs.map(mapUserWithAccess);
});

export const getAcceptedInvitesForUsersWithAccess = createAsyncThunk(
  'stage/getAcceptedInvitesForUsersWithAccess',
  async (userIds: string[]) => {
    const promises: Promise<QuerySnapshot>[] = [];

    chunk(userIds, 10).forEach((ch) => {
      const promise = getDocs(
        query(
          acceptedInvitesCollection,
          where(documentId(), 'in', ch),
          where('accepted', '==', false)
        )
      );

      promises.push(promise);
    });

    const result = await Promise.all(promises);
    const processed = result.map((snapshot) =>
      snapshot.docs.map((d) => {
        const data = d.data();
        return { id: d.id, accepted: !!data.accepted };
      })
    );

    return flatten(processed);
  }
);

export const removeUserWithAccess = createAsyncThunk(
  'stage/removeUserWithAccess',
  async (id: string) => {
    await deleteDoc(doc(usersWithAccessCollection, id));

    return { id };
  }
);

export const addVideo = createAsyncThunk(
  'stage/addVideo',
  async ({ stageId, url, date }: NewVideo, { rejectWithValue }) => {
    try {
      return await apiService.create<NewVideo, Video>('videos', {
        url,
        date,
        stageId,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateVideo = createAsyncThunk(
  'stage/updateVideo',
  async (
    { id, video }: { id: string; video: UpdateVideo },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    try {
      const result = await apiService.update<UpdateVideo, Video>(
        'videos',
        id,
        video
      );

      dispatch(getLiveVideos(state.stage.current.id));

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const removeVideo = createAsyncThunk(
  'stage/removeVideo',
  async (
    { id, date }: { id: string; date: string },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;

    await deleteDoc(doc(videosCollection, id));
    dispatch(getLiveVideos(state.stage.current.id));

    return { id, date };
  }
);

export const removeVideosBulk = createAsyncThunk(
  'stage/removeVideosBulk',
  async (
    { ids, date }: { ids: string[]; date: string },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const batch = writeBatch(db);
    ids.forEach((id) => {
      batch.delete(doc(videosCollection, id));
    });

    await batch.commit();
    dispatch(getLiveVideos(state.stage.current.id));

    return { ids, date };
  }
);

export const addMirror = createAsyncThunk(
  'stage/addMirror',
  async (
    { id, url }: { id: string; url: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    try {
      const videoRef = doc(videosCollection, id);

      await updateDoc(videoRef, {
        mirrors: arrayUnion(url),
      });

      dispatch(getStageVideos(state.stage.current.id));
      dispatch(getLiveVideos(state.stage.current.id));

      return { id, url };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const removeMirror = createAsyncThunk(
  'stage/removeMirror',
  async (
    { id, url }: { id: string; url: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;

    try {
      await apiService.removeSubresource('videos', id, 'mirrors', '', {
        url,
      });

      dispatch(getStageVideos(state.stage.current.id));
      dispatch(getLiveVideos(state.stage.current.id));

      return { id, url };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const addBackupVideo = createAsyncThunk(
  'stage/addBackupVideo',
  async (
    { stageId, url }: { stageId: string; url: string },
    { rejectWithValue }
  ) => {
    try {
      return await apiService.create<{ url: string }, BackupVideo>(
        `stages/${stageId}/backup-videos`,
        { url }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateBackupVideo = createAsyncThunk(
  'stage/updateBackupVideo',
  async (
    { id, video }: { id: string; video: UpdateBackupVideo },
    { rejectWithValue }
  ) => {
    try {
      await apiService.update('backup-videos', id, video);

      return { id, ...video };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const removeBackupVideo = createAsyncThunk(
  'stage/removeBackupVideo',
  async (
    { id, stageId }: { id: string; stageId: string },
    { rejectWithValue }
  ) => {
    try {
      await apiService.remove('backup-videos', id);
      await updateDoc(doc(stagesCollection, stageId), {
        backupVideo: { active: false },
      });

      return { id };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const getCurrentlyPlayed = createAsyncThunk(
  'stage/getCurrentlyPlayed',
  async (stageId: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getSubresource<CurrentlyPlayed>(
      'stages',
      stageId,
      'videos/currently-played',
      { full: true },
      source.token
    );
  }
);

export const getSchedulerPlaylist = createAsyncThunk(
  'stage/getSchedulerPlaylist',
  async (stageId: string) =>
    apiService.getSubresource<SchedulerPlaylist>(
      'stages',
      stageId,
      'scheduler-playlist'
    )
);

export const changeCurrentlyPlayed = createAsyncThunk(
  'stage/changeCurrentlyPlayed',
  async (
    {
      stageId,
      videoId,
      type,
    }: {
      stageId: string;
      videoId?: string;
      type: MediaType;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiService.request(
        'put',
        `/stages/${stageId}/change-currently-played`,
        { id: videoId, type }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }

    return { id: videoId, type };
  }
);

export const updateSchedulerPlaylistSettings = createAsyncThunk(
  'stage/updateSchedulerPlaylistSettings',
  async (
    { stageId, ...data }: UpdateSchedulerPlaylist & { stageId: string },
    { rejectWithValue }
  ) => {
    try {
      await apiService.update(
        'stages',
        `${stageId}/scheduler-playlist-settings`,
        data
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
    return data;
  }
);

export const getFestivalDates = createAsyncThunk(
  'stage/getFestivalDates',
  async () => {
    const { docs } = await getDocs(
      query(festivalDatesCollection, orderBy('date'))
    );

    return docs.map((d) => d.data().date);
  }
);

export const getRateAdminStageIds = createAsyncThunk(
  'stage/getRateAdminStageIds',
  async () => {
    const { docs } = await getDocs(rateAdminStagesCollection);
    return map(docs, 'id');
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return state.stage.rateAdminStages.length === 0;
    },
  }
);

export const addRateAdminStage = createAsyncThunk(
  'stage/addRateAdminStage',
  async (stageId: string, { rejectWithValue }) => {
    try {
      await apiService.create('rate-admin-stages', { stageId });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
    return { stageId };
  }
);

export const removeRateAdminStage = createAsyncThunk(
  'stage/removeRateAdminStage',
  async (stageId: string, { rejectWithValue }) => {
    try {
      await apiService.remove('rate-admin-stages', stageId);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
    return { stageId };
  }
);
