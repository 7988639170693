import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getCamp,
  getCampUsersWithAccess,
  getCampLinks,
} from '@/store/asyncThunks/camp';
import { selectToken } from '@/store/selectors/auth';
import { getFestivalDates } from '@/store/asyncThunks/stage';
import { useAppDispatch } from '@/store';

const emptyPromise = {
  abort() {},
};

const useCampData = () => {
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);

  useEffect(() => {
    const promise: any = dispatch(getFestivalDates());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && token) {
      promise = dispatch(getCamp(params.id));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && token) {
      promise = dispatch(getCampLinks(params.id));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && token) {
      promise = dispatch(getCampUsersWithAccess({ campId: params.id }));
    }
    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token]);
};

export default useCampData;
