import styled from 'styled-components';
import { Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';

export default withTheme(styled(Typography).attrs({
  variant: 'h6',
  gutterBottom: true,
})`
  color: ${(props) => props.theme.palette.primary.main};
`);
