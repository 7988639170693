import styled from 'styled-components';
import { Box } from '@mui/material';
import breakpoint from '@/breakpoint';

export default styled(Box)`
  display: flex;
  align-items: center;
  padding: 36px 24px;

  ${breakpoint('lg')`
      padding: 40px 27px;
  `}

  ${breakpoint('xl')`
      padding: 48px 32px;
  `}
`;
