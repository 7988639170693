import styled from 'styled-components';

export default styled.div`
  left: 0;
  right: 0;
  margin: auto;
  max-width: 40px;
  padding: 32px;
  display: flex;
  align-items: center;
`;
