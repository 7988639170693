import type { FC } from 'react';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Divider, Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StageEvent } from '@myclipo/bm-admin-common';
import Head from '@/components/Stage/Head';
import HeadSmall from '@/components/Stage/HeadSmall';
import useIsMobile from '@/hooks/useIsMobile';
import { selectStage, selectStageNotFound } from '@/store/selectors/stage';
import { selectDrawerStages } from '@/store/selectors/drawer';
import NotFound from '@/components/NotFound';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import Effects from '../__common/Effects';
import useStageData from './useStageData';
import PanelContainer from '../styled/PanelContainer';

const BurningManOptions = React.lazy(
  () => import('@/components/AddLink/BurningManOptions')
);
const SalvationStageSettings = React.lazy(
  () => import('@/components/SalvationStageSettings')
);
const UsersWithAccess = React.lazy(
  () => import('@/components/Stage/UsersWithAccess')
);
const BackupVideo = React.lazy(
  () => import('@/components/AddLink/BackupVideo')
);

const Stage: FC = () => {
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const isMobile = useIsMobile();
  const stage = useSelector(selectStage);
  const notFound = useSelector(selectStageNotFound);
  const stages = useSelector(selectDrawerStages);
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  useStageData();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    setTab(1);
  }, [params.id]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [params.id, containerRef.current]);

  useEffect(() => {
    if (params.id === undefined && stages.length > 0) {
      navigate(`${location.pathname}/${stages[0].id}`);
    }
  }, [stages.length, navigate, params]);

  if (notFound) {
    return (
      <PanelContainer ref={containerRef}>
        <NotFound />
      </PanelContainer>
    );
  }

  return params.id ? (
    <PanelContainer ref={containerRef}>
      <Head />
      <Divider />
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab value={1} label="Streaming now" />
        {stage.event === StageEvent.Salvation &&
          stage.id === import.meta.env.REACT_APP_SALVATION_MAIN_STAGE && (
            <Tab value={2} label="Stage settings" />
          )}
        <Tab value={0} label="Users who have access" />
        {stage.isBurningMan && <Tab value={3} label="Burningman options" />}
        {stage.event !== StageEvent.Salvation && (
          <Tab value={4} label="Effects" />
        )}
      </Tabs>

      {tab === 0 && (
        <>
          <Divider />
          <Suspense fallback={<div>Loading...</div>}>
            <UsersWithAccess />
          </Suspense>
        </>
      )}
      {tab === 1 && (
        <>
          <Divider />
          {!isMobile && <HeadSmall title="Streaming now" />}
          {/* <ScheduledOnDisplay /> */}
          <Suspense fallback={<div>Loading...</div>}>
            <BackupVideo />
          </Suspense>
        </>
      )}
      {stage.event === StageEvent.Salvation && tab === 2 && (
        <>
          <Divider />
          <HeadSmall title="Stage settings" />
          <Divider />
          <Suspense fallback={<div>Loading...</div>}>
            <SalvationStageSettings
              withBottomPadding
              stageId={params.id ?? null}
            />
          </Suspense>
        </>
      )}
      {stage.isBurningMan && tab === 3 && (
        <>
          <Divider />
          <Suspense fallback={<div>Loading...</div>}>
            <BurningManOptions />
          </Suspense>
        </>
      )}
      {stage.event !== StageEvent.Salvation && tab === 4 && <Effects />}
    </PanelContainer>
  ) : (
    <div />
  );
};

export default Sentry.withErrorBoundary(Stage, {
  fallback: ErrorFallbackComponent,
});
