import Typography from '@mui/material/Typography';
import React, { memo } from 'react';

const NotFound = () => (
  <div style={{ padding: 24 }}>
    <Typography variant="h3" component="div" gutterBottom>
      Not Found
    </Typography>
  </div>
);

export default memo(NotFound);
