import type { FC } from 'react';
import React from 'react';
import { Typography } from '@mui/material';
import StyledDialog from '@/components/styled/Dialog';
import Button from '@/components/styled/Button';
import DialogTitle from '../styled/DialogTitle';
import ButtonsContainer from './styled/ButtonsContainer';
import DialogContent from '../styled/DialogContent';

interface DisableDisplayProps {
  onClose: (event: any) => void;
  onConfirm: () => void;
  open: boolean;
}

const DisableDisplay: FC<DisableDisplayProps> = ({
  onClose,
  onConfirm,
  open,
}) => (
  <StyledDialog onClose={onClose} open={open}>
    <DialogTitle>
      <Typography align="center" variant="h5">
        Disable screen
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="subtitle1" gutterBottom align="center">
        You are sure that you want to disable the screen?
      </Typography>
      <ButtonsContainer>
        <Button onClick={onConfirm}>Yes</Button>
        <Button onClick={onClose}>No</Button>
      </ButtonsContainer>
    </DialogContent>
  </StyledDialog>
);

export default DisableDisplay;
