import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import type { ElementType, FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OBSVideoServiceType } from '@myclipo/bm-admin-common';
import type { CancelToken } from 'axios';
import axios from 'axios';
import apiServiceFactory from '@/services/apiServiceFactory';
import { updateOBSLink } from '@/store/asyncThunks/obs';
import { selectToken } from '@/store/selectors/auth';
import {
  selectFirstOBSVideo,
  selectOBSUsedChannelIds,
} from '@/store/selectors/obs';
import Backdrop from '@mui/material/Backdrop';
import { useAppDispatch } from '@/store';
import Button from '../styled/Button';

interface OBSChannel {
  id: number | string;
  name: string;
  input?: string;
  output: string;
  cloudfrontDistributionId?: string;
}

const apiService = apiServiceFactory();

const validationSchema = Yup.object().shape({
  url: Yup.string().trim().url().required(),
  urlObs: Yup.string()
    .trim()
    .matches(/^rtmp(s?):\/\/\S+/s, 'Not valid rtmp url.')
    .required(),
  serviceType: Yup.string().oneOf(['medialive', 'ivs', 'other']).required(),
  channelId: Yup.mixed().optional(),
  account: Yup.string().trim().optional(),
  region: Yup.string().trim().optional(),
});

const Form: FC = () => {
  const [channels, setChannels] = useState<OBSChannel[]>([]);
  const [channelsLoading, setChannelsLoading] = useState(false);
  const [channelInputLoading, setChannelInputLoading] = useState(false);
  const token = useSelector(selectToken);
  const currentObs = useSelector(selectFirstOBSVideo);
  const usedChannelIds = useSelector(selectOBSUsedChannelIds);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      url: currentObs?.url ?? '',
      urlObs: currentObs?.urlObs ?? '',
      serviceType: OBSVideoServiceType.Ivs,
      channelId: currentObs?.channelId ?? -1,
      account: currentObs?.account ?? '',
      region: currentObs?.region ?? '',
      cloudfrontDistributionId: currentObs?.cloudfrontDistributionId ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await dispatch(updateOBSLink({ id: currentObs!.id, obs: values }));
    },
  });

  const filteredChannels = useMemo(
    () =>
      channels.filter(
        (channel) =>
          !usedChannelIds.includes(channel.id) ||
          channel.id === formik.values.channelId
      ),
    [channels, usedChannelIds, formik.values.channelId]
  );

  const isAws = useMemo(
    () =>
      [OBSVideoServiceType.Medialive, OBSVideoServiceType.Ivs].includes(
        formik.values.serviceType
      ),
    [formik.values.serviceType]
  );

  useEffect(() => {
    if (currentObs) {
      formik.setValues({
        url: currentObs.url ?? '',
        urlObs: currentObs.urlObs ?? '',
        serviceType: currentObs.serviceType,
        channelId: currentObs.channelId ?? -1,
        account: currentObs.account ?? '',
        region: currentObs.region ?? '',
        cloudfrontDistributionId: currentObs?.cloudfrontDistributionId ?? '',
      });
    }
  }, [currentObs]);

  const loadChannelInput = useCallback(
    async (channelId: string) => {
      try {
        setChannelInputLoading(true);
        apiService.token = token as string;

        const { input } = await apiService.get<{ input: string }>(
          `obs-channels/${formik.values.serviceType}/${formik.values.account}/${formik.values.region}/inputs`,
          encodeURIComponent(channelId)
        );

        await formik.setFieldValue('urlObs', input);
        // eslint-disable-next-line no-empty
      } catch {
        await formik.setFieldValue('urlObs', '');
      } finally {
        setChannelInputLoading(false);
      }
    },
    [
      formik.values.serviceType,
      formik.values.account,
      formik.values.region,
      token,
    ]
  );

  const loadChannels = useCallback(
    async (cancelToken: CancelToken) => {
      setChannelsLoading(true);
      apiService.token = token as string;

      try {
        const data = await apiService.getAll<OBSChannel>(
          `obs-channels/${formik.values.serviceType}/${formik.values.account}/${formik.values.region}`,
          {},
          false,
          cancelToken
        );
        setChannels(data);
      } catch (err) {
        setChannels([]);
      } finally {
        setChannelsLoading(false);
      }
    },
    [
      formik.values.serviceType,
      formik.values.account,
      formik.values.region,
      token,
    ]
  );

  useEffect(() => {
    setChannels([]);

    const source = axios.CancelToken.source();
    if (
      formik.values.account.length > 0 &&
      formik.values.region.length > 0 &&
      isAws
    ) {
      loadChannels(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [formik.values.account, formik.values.region, isAws, loadChannels]);

  const IconComponent: ElementType = useCallback(
    (props: any) =>
      channelsLoading ? (
        <CircularProgress size={20} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ArrowDropDownIcon {...props} />
      ),
    [channelsLoading]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            name="url"
            value={formik.values.url}
            onChange={formik.handleChange}
            label="Url"
            placeholder="Url"
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={formik.isSubmitting}
            error={!!formik.errors.url}
            helperText={formik.errors.url}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="urlObs"
            value={formik.values.urlObs}
            onChange={formik.handleChange}
            label="Streaming url"
            placeholder="Streaming url"
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={formik.isSubmitting}
            error={!!formik.errors.urlObs}
            helperText={formik.errors.urlObs}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Service type</InputLabel>
            <Select
              style={{ color: '#fff' }}
              placeholder="Service type"
              name="serviceType"
              value={formik.values.serviceType}
              onChange={formik.handleChange}
              fullWidth
              displayEmpty
              disabled={formik.isSubmitting}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="medialive">AWS Medialive</MenuItem>
              <MenuItem value="ivs">AWS IVS</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {isAws && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink>AWS Account</InputLabel>
                <Select
                  style={{ color: '#fff' }}
                  placeholder="AWS Account"
                  name="account"
                  value={formik.values.account}
                  onChange={formik.handleChange}
                  fullWidth
                  displayEmpty
                  disabled={formik.isSubmitting}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="fabien">Fabien</MenuItem>
                  <MenuItem value="bonzoq">Bonzoq</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink>AWS Region</InputLabel>
                <Select
                  style={{ color: '#fff' }}
                  placeholder="AWS Region"
                  name="region"
                  value={formik.values.region}
                  onChange={formik.handleChange}
                  fullWidth
                  displayEmpty
                  disabled={formik.isSubmitting || !formik.values.account}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="us-west-2">Oregon (us-west-2)</MenuItem>
                  <MenuItem value="us-east-1">N. Virginia (us-east-1)</MenuItem>
                  <MenuItem value="eu-central-1">
                    Frankfurt (eu-central-1)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink>AWS Channel</InputLabel>
                <Select
                  style={{ color: '#fff' }}
                  placeholder="AWS Channel"
                  name="channelId"
                  value={formik.values.channelId}
                  onChange={formik.handleChange}
                  fullWidth
                  displayEmpty
                  disabled={
                    formik.isSubmitting ||
                    channelsLoading ||
                    filteredChannels.length === 0
                  }
                  IconComponent={IconComponent}
                >
                  {filteredChannels.length === 0 && formik.values.account && (
                    <MenuItem value={-1}>
                      <em>None Available</em>
                    </MenuItem>
                  )}
                  {filteredChannels.length === 0 && !formik.values.account && (
                    <MenuItem value={-1}>
                      <em>None</em>
                    </MenuItem>
                  )}
                  {filteredChannels.length > 0 && (
                    <MenuItem value={-1}>
                      <em>None</em>
                    </MenuItem>
                  )}
                  {filteredChannels.map((channel) => (
                    <MenuItem
                      key={channel.id}
                      value={channel.id}
                      onClick={async () => {
                        await formik.setFieldValue('url', channel.output);
                        await formik.setFieldValue(
                          'cloudfrontDistributionId',
                          channel.cloudfrontDistributionId ?? ''
                        );

                        if (channel.input) {
                          await formik.setFieldValue('urlObs', channel.input);
                        } else {
                          await loadChannelInput(channel.id as string);
                        }
                      }}
                    >
                      {channel.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {formik.values.serviceType === OBSVideoServiceType.Medialive && (
              <Grid item xs={6}>
                <TextField
                  name="cloudfrontDistributionId"
                  value={formik.values.cloudfrontDistributionId}
                  onChange={formik.handleChange}
                  label="Cloudfront Distribution ID"
                  placeholder="Cloudfront Distribution ID"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.cloudfrontDistributionId}
                  helperText={formik.errors.cloudfrontDistributionId}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Backdrop open={channelInputLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default Form;
