import type { FC } from 'react';
import React, { memo, useState } from 'react';
import Container from './styled/Container';
import UserDisplays from './UserDisplays';
import Dialog from './Dialog';
import Button from '../styled/Button';

const AccessToDisplays: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <UserDisplays />
        <Button onClick={() => setOpen(true)}>Add screen to user</Button>
      </Container>
      <Dialog open={open} onClose={handleClose} />
    </>
  );
};

export default memo(AccessToDisplays);
