import styled from 'styled-components';
import { ListItemText } from '@mui/material';
import breakpoint from '@/breakpoint';

export default styled(ListItemText)`
  padding-left: 16px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;

    ${breakpoint('lg')`
      font-size: 13px;
    `}

    ${breakpoint('xl')`
      font-size: 14px;
    `}
  }
`;
