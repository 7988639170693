import type { FC } from 'react';
import React, { useState, useEffect, Suspense, useMemo } from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  CircularProgress,
} from '@mui/material';
import { UserRole } from '@myclipo/bm-admin-common';
import { selectUserRole } from '@/store/selectors/auth';
import {
  selectCampUsersWithAccess,
  selectCampLoading,
} from '@/store/selectors/camp';
import useIsMobile from '@/hooks/useIsMobile';
import { useAppSelector } from '@/store/hooks';
import StyledTableRow from '@/components/CampAndStageCommon/styled/TableRow';
import Container from '@/components/CampAndStageCommon/styled/Container';
import ProgressContainer from '@/components/styled/ProgressContainer';
import Dialog from './Dialog';

const UsersWithAccess: FC = () => {
  const usersWithAccess = useAppSelector(selectCampUsersWithAccess);
  const stageLoading = useAppSelector(selectCampLoading);
  const userRole = useAppSelector(selectUserRole);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [disabledDialogTabs, setDisabledDialogTabs] = useState<UserRole[]>([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    setPage(0);
  }, [usersWithAccess]);

  useEffect(() => {
    if (userRole.includes(UserRole.Admin)) {
      setDisabledDialogTabs([UserRole.Admin]);
    }
  }, [userRole]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TableHeadComponent = useMemo(
    () =>
      isMobile
        ? React.lazy(() => import('./MobileTableHead'))
        : React.lazy(() => import('./TableHead')),
    [isMobile]
  );

  const TableRowComponent = useMemo(
    () =>
      isMobile
        ? React.lazy(() => import('./MobileTableRow'))
        : React.lazy(() => import('./TableRow')),
    [isMobile]
  );

  const ButtonComponent = useMemo(
    () =>
      !isMobile
        ? React.lazy(() => import('../../styled/Button'))
        : React.lazy(() => import('../../styled/FullWidthButton')),
    [isMobile]
  );

  return (
    <>
      {stageLoading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      {!stageLoading && (
        <Container>
          <Table>
            <Suspense fallback={<thead />}>
              <TableHeadComponent />
            </Suspense>
            <TableBody>
              {usersWithAccess
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((userWithAccess) => (
                  <Suspense key={userWithAccess.id} fallback={<tr />}>
                    <TableRowComponent userWithAccess={userWithAccess} />
                  </Suspense>
                ))}
              {usersWithAccess.length === 0 && (
                <StyledTableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography variant="h6">No result</Typography>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersWithAccess.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Suspense fallback={<div />}>
            <ButtonComponent onClick={handleClickOpen}>
              Add user to camp
            </ButtonComponent>
          </Suspense>
        </Container>
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        disabledTabs={disabledDialogTabs}
      />
    </>
  );
};

export default UsersWithAccess;
