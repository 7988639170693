import styled from 'styled-components';
import { Typography } from '@mui/material';

export default styled(Typography).attrs({
  variant: 'subtitle2',
})`
  color: #ffffff8a;
  margin-top: 32px;
  padding-bottom: 16px;
`;
