import type { FC } from 'react';
import React, { memo } from 'react';
import { Typography } from '@mui/material';
import SignInWithGoogleButtonBase from './styled/SignInWithGoogleButtonBase';
import SignInWithGoogleLogo from './styled/SignInWithGoogleLogo';

interface SignInWithGoogleButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SignInWithGoogleButton: FC<SignInWithGoogleButtonProps> = ({
  onClick,
}) => (
  <SignInWithGoogleButtonBase onClick={onClick}>
    <SignInWithGoogleLogo />
    <Typography component="span" variant="button" color="inherit">
      Sign In with Google
    </Typography>
  </SignInWithGoogleButtonBase>
);

export default memo(SignInWithGoogleButton);
