import type { ChangeEvent, FC } from 'react';
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  Suspense,
} from 'react';
import {
  Table,
  TableBody,
  TableRow as MaterialTableRow,
  TableCell,
  Checkbox,
  Typography,
  TableHead,
  CircularProgress,
  TableFooter,
  TablePagination,
} from '@mui/material';
import _ from 'lodash';
import type { Stage, UserWithAccess } from '@myclipo/bm-admin-common';
import { removeStage as removeStageFunc } from '@/store/user';
import {
  selectUserStages,
  selectUserStagesLoading,
  selectUserCurrentlyPlayed,
  selectUserCurrentlyPlayedLoading,
} from '@/store/selectors/user';
import { getUserCurrentlyPlayed as getUserCurrentlyPlayedFunc } from '@/store/asyncThunks/user';
import { selectUserWithAccessList } from '@/store/userWithAccess';
import { removeUserWithAccess as removeUserWithAccessFunc } from '@/store/asyncThunks/stage';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import DisableDisplay from './DisableDisplay';
import ProgressContainer from '../../styled/ProgressContainer';

const TableRow = React.lazy(() => import('./TableRow'));

const emptyPromise = {
  abort() {},
};

const UserDisplays: FC = () => {
  const dispatch = useAppDispatch();

  const stages = useAppSelector(selectUserStages);
  const stagesLoading = useAppSelector(selectUserStagesLoading);
  const usersWithAccess = useAppSelector(selectUserWithAccessList);
  const currentlyPlayed = useAppSelector(selectUserCurrentlyPlayed);
  const currentlyPlayedLoading = useAppSelector(
    selectUserCurrentlyPlayedLoading
  );

  const [currentStage, setCurrentStage] = useState<Stage | null>(null);
  const [disableDisplayOpen, setDisableDisplayOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const groupedUsersWithAccess = useMemo(
    () => _.groupBy(usersWithAccess, 'stageId'),
    [usersWithAccess]
  );

  const stageIds = useMemo(() => stages.map((stage) => stage.id), [stages]);
  const stageCurrentlyPlayedMap = useMemo(
    () => new Map(currentlyPlayed.map((c) => [c.stageId, c])),
    [currentlyPlayed]
  );

  useEffect(() => {
    let promise: any = emptyPromise;

    if (stageIds.length > 0) {
      promise = dispatch(getUserCurrentlyPlayedFunc(stageIds));
    }

    return () => {
      promise.abort();
    };
  }, [stageIds, dispatch]);

  const handleDisable = useCallback(async () => {
    if (currentStage) {
      const accessesToBeDeleted: UserWithAccess[] = _.get(
        groupedUsersWithAccess,
        currentStage.id,
        []
      );
      await Promise.all(
        accessesToBeDeleted.map(({ id }) =>
          dispatch(removeUserWithAccessFunc(id))
        )
      );
      dispatch(removeStageFunc(currentStage.id));
    }
    setDisableDisplayOpen(false);
  }, [currentStage, groupedUsersWithAccess, dispatch]);

  const handleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectedRows(stageIds);
      } else {
        setSelectedRows([]);
      }
    },
    [stageIds]
  );

  const handleRowSelect = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(_.without(selectedRows, id));
    }
  };

  const handleDisableClick = (stage: Stage) => {
    setDisableDisplayOpen(true);
    setCurrentStage(stage);
  };

  const handleDisableClose = () => {
    setDisableDisplayOpen(false);
    setCurrentStage(null);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {stagesLoading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      {!stagesLoading && (
        <Table>
          <TableHead>
            <MaterialTableRow tabIndex={-1}>
              <TableCell padding="checkbox" colSpan={4}>
                <Checkbox color="primary" onChange={handleSelectAll} />
              </TableCell>
            </MaterialTableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? stages.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : stages
            ).map((stage) => (
              <Suspense key={stage.id} fallback={<tr />}>
                <TableRow
                  key={stage.id}
                  stage={stage}
                  selected={selectedRows.indexOf(stage.id) !== -1}
                  liveVideo={stageCurrentlyPlayedMap.get(stage.id)}
                  currentlyPlayedLoading={currentlyPlayedLoading}
                  usersWithAccess={_.get(groupedUsersWithAccess, stage.id, [])}
                  onDisable={handleDisableClick}
                  onSelect={handleRowSelect}
                />
              </Suspense>
            ))}
            {stages.length === 0 && (
              <MaterialTableRow key="no-result">
                <TableCell align="center" colSpan={4}>
                  <Typography variant="h6">No result</Typography>
                </TableCell>
              </MaterialTableRow>
            )}
          </TableBody>
          <TableFooter>
            <MaterialTableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                colSpan={4}
                count={stages.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </MaterialTableRow>
          </TableFooter>
        </Table>
      )}
      <DisableDisplay
        open={disableDisplayOpen}
        onClose={handleDisableClose}
        onConfirm={handleDisable}
      />
    </>
  );
};

export default UserDisplays;
