import type { Stage } from '@myclipo/bm-admin-common';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): Stage => {
  const data = doc.data();

  return {
    id: doc.id,
    number: data?.number,
    name: data?.name,
    backupVideo: data?.backupVideo ?? null,
    obs: data?.obs ?? null,
    image: data?.image ?? null,
    schedulerPlaylist: data?.schedulerPlaylist ?? null,
    isBurningMan: !!data?.isBurningMan,
    screenIsHidden: !!data?.screenIsHidden,
    screenIsPlaying: !!data?.screenIsPlaying,
    burnStarted: !!data?.burnStarted,
    burnFinished: !!data?.burnFinished,
    isLaserOn: !!data?.isLaserOn,
    rate: data?.rate,
    crowdEnabled: data?.crowdEnabled,
    rateControl: data?.rateControl,
    event: data?.event,
    schedulerControlled: !!data?.schedulerControlled,
  };
};
