import type { FC } from 'react';
import React from 'react';
import { Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setIsVisibleOnMobile as setIsVisibleOnMobileFunc } from '@/store/drawer';
import { useAppDispatch } from '@/store';
import HeaderContainer from '../styled/HeaderContainer';

const Head: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <HeaderContainer maxWidth={false}>
      <IconButton
        onClick={() => dispatch(setIsVisibleOnMobileFunc(true))}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4">Add link</Typography>
    </HeaderContainer>
  );
};

export default Head;
