import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectStage } from '@/store/selectors/stage';
import { addUserWithAccessBulk } from '@/store/asyncThunks/stage';
import { setSuccessMessage } from '@/store/flashMessage';
import { useAppDispatch } from '@/store';
import useUserEmailsMap from './useUserEmailsMap';

export default (activeItems: string[]) => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const stage = useSelector(selectStage);
  const { userEmailsMap } = useUserEmailsMap();

  const handleAddUserToDisplayBySelected = useCallback(async () => {
    let emails: string[] = [];
    const userWithAccess = activeItems.map((activeItem) => ({
      userId: activeItem,
    }));

    emails = activeItems.map(
      (activeItem) => userEmailsMap.get(activeItem) as string
    );

    await dispatch(
      addUserWithAccessBulk({ stageId: params.id!, userWithAccess })
    );

    if (emails.length > 1) {
      dispatch(
        setSuccessMessage(
          `Users with emails ${emails.join(', ')} have been granted access to ${
            stage.name
          }`
        )
      );
    } else if (emails.length === 1) {
      setSuccessMessage(
        `User with email ${emails.join(', ')} has been granted access to ${
          stage.name
        }`
      );
    }
  }, [userEmailsMap, activeItems, params.id, stage.name, dispatch]);

  return { handleAddUserToDisplayBySelected };
};
