import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Typography,
  Slider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import type { StreamImage, UpdateStreamImage } from '@myclipo/bm-admin-common';
import SchedulerControlledContext from '@/context/SchedulerControlledContext';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '@/store/selectors/auth';
import StyledTableRow from '../../Playlist/TableRow/styled/TableRow';
import BottomText from '../../Playlist/TableRow/styled/BottomText';
import Title from '../../Playlist/TableRow/styled/Title';
import ControlsContainer from '../../styled/ControlsContainer';
import SliderContainer from '../../Playlist/TableRow/styled/SliderContainer';
import Img from './styled/Img';

interface Props {
  image: StreamImage;
  active?: boolean;
  onClick: (id: string) => void;
  onRemove?: (id: string) => void;
  onSettingChange: (id: string, data: UpdateStreamImage) => void;
}

const TableRow: FC<Props> = ({
  image,
  active = false,
  onClick,
  onRemove,
  onSettingChange,
}) => {
  const { schedulerControlled } = useContext(SchedulerControlledContext);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const handleClick = useCallback(() => {
    if (!schedulerControlled || isSuperAdmin) {
      onClick(image.id);
    }
  }, [onClick, image.id, schedulerControlled]);

  const handleRemove = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      if (onRemove) {
        onRemove(image.id);
      }
    },
    [onRemove, image.id]
  );

  const handleGreenScreenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSettingChange(image.id, { greenScreen: checked });
    },
    [onSettingChange, image.id]
  );

  const handleScreenSizeChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(image.id, {
        screenSize: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, image.id]
  );

  const handleScreenOffsetYChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(image.id, {
        screenOffsetY: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, image.id]
  );

  const handleScreenBrightnessChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(image.id, {
        screenBrightness: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, image.id]
  );

  return (
    <StyledTableRow active={!!active} onClick={handleClick}>
      <TableCell
        sx={{
          width: 220,
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
          p: 0,
        }}
        align="left"
      >
        <PlayCircleOutlineIcon
          sx={{
            fontSize: 50,
            zIndex: 1000,
            mt: 3,
            position: 'relative',
          }}
          color={active ? 'primary' : undefined}
        />
        <Img
          src={image.url}
          alt={image.title}
          loading="lazy"
          placeholderSrc="https://placehold.co/220x140"
        />
      </TableCell>
      <TableCell align="left" onClick={(event) => event.stopPropagation()}>
        <Title active={!!active}>{image.title}</Title>
        {image.description.length > 0 && (
          <BottomText active={!!active}>{image.description}</BottomText>
        )}
        {image.description.length === 0 && (
          <BottomText active={!!active}>
            <em>None</em>
          </BottomText>
        )}

        <ControlsContainer>
          <FormControlLabel
            sx={{ flex: 1 }}
            control={
              <Checkbox
                checked={image.greenScreen}
                onChange={handleGreenScreenChange}
                name="checkedB"
                color="primary"
                onClick={(event) => event.stopPropagation()}
                disabled={image.shared}
              />
            }
            label="Green screen"
          />

          <SliderContainer>
            <Typography sx={{ flex: 1, textAlign: 'center' }}>
              Screen size
            </Typography>
            <Slider
              sx={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}%`}
              defaultValue={image.screenSize}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              step={10}
              marks
              min={50}
              max={200}
              onChangeCommitted={handleScreenSizeChange}
              disabled={image.shared}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography sx={{ flex: 1, textAlign: 'center' }}>
              Screen offset Y
            </Typography>
            <Slider
              sx={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}cm`}
              defaultValue={image.screenOffsetY}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}cm`}
              step={10}
              marks
              min={-250}
              max={250}
              onChangeCommitted={handleScreenOffsetYChange}
              disabled={image.shared}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography sx={{ flex: 1, textAlign: 'center' }}>
              Screen brightness
            </Typography>
            <Slider
              sx={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}`}
              defaultValue={image.screenBrightness}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}`}
              step={0.1}
              marks
              min={0}
              max={1}
              onChangeCommitted={handleScreenBrightnessChange}
              disabled={image.shared}
            />
          </SliderContainer>
        </ControlsContainer>
      </TableCell>
      <TableCell align="right">
        {onRemove && !image.shared && !schedulerControlled && (
          <IconButton color="primary" onClick={handleRemove} size="large">
            <CloseIcon />
          </IconButton>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

export default TableRow;
