import type { OBSVideo } from '@myclipo/bm-admin-common';
import type { FC } from 'react';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import StyledListItem from '@/components/styled/ListItem';
import ListItemText from '@/components/styled/ListItemText';

const ListItem: FC<{ obs: OBSVideo; activeItem: string | null }> = ({
  obs,
  activeItem,
}) => {
  const primaryText = useMemo(() => {
    const names = obs.stages?.map((stage) => stage.name);

    if (names === undefined || names.length === 0) {
      return 'None';
    }

    return names.join(', ');
  }, [obs.stages]);

  return (
    <StyledListItem
      button
      selected={activeItem === obs.id}
      component={Link}
      to={`/obs-configuration/${obs.id}`}
    >
      <ListItemText
        primary={primaryText}
        secondary={<strong>{obs.serviceType}</strong>}
      />
    </StyledListItem>
  );
};

export default memo(ListItem);
