import type { FC } from 'react';
import React from 'react';
import { Typography, IconButton, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { selectStage } from '@/store/selectors/stage';
import { setIsVisibleOnMobile as setIsVisibleOnMobileFunc } from '@/store/drawer';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import Subtitle from './styled/Subtitle';
import HeaderContainer from './styled/HeaderContainer';
import MobileHidden from '../styled/MobileHidden';
import MobileVisible from '../styled/MobileVisible';

const Head: FC = () => {
  const dispatch = useAppDispatch();
  const stage = useAppSelector(selectStage);

  return (
    <>
      <HeaderContainer maxWidth={false}>
        <IconButton
          onClick={() => dispatch(setIsVisibleOnMobileFunc(true))}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        <MobileHidden>
          <Typography variant="h4">Add effects</Typography>
        </MobileHidden>
        <MobileVisible style={{ width: 'calc(100% - 96px)' }}>
          <Typography variant="h3" align="center">
            Add effects
          </Typography>
        </MobileVisible>
      </HeaderContainer>
      <Container maxWidth={false}>
        <Subtitle>{stage.name}</Subtitle>
      </Container>
    </>
  );
};

export default Head;
