import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteConfirmationDialog: FC<Props> = ({ open, onClose, onConfirm }) => (
  <Dialog open={open}>
    <DialogTitle>
      Are you sure you want to delete this configuration?
    </DialogTitle>

    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteConfirmationDialog;
