import { find } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectDialogUsers } from '@/store/selectors/dialog';
import { selectStage } from '@/store/selectors/stage';
import { addUserWithAccessBulk } from '@/store/asyncThunks/stage';
import { setSuccessMessage } from '@/store/flashMessage';
import { useAppDispatch } from '@/store';

export default (searchValue: string) => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const stage = useSelector(selectStage);
  const users = useSelector(selectDialogUsers);

  const handleAddUserToDisplayByEmail = useCallback(async () => {
    const user = find(users, { email: searchValue });

    if (user) {
      await dispatch(
        addUserWithAccessBulk({
          stageId: params.id!,
          userWithAccess: [
            {
              userId: user.uid,
            },
          ],
        })
      );

      dispatch(
        setSuccessMessage(
          `User with email ${user.email} has been granted access to ${stage.name}`
        )
      );
    }
  }, [params.id, searchValue, dispatch, stage.name, users]);

  return { handleAddUserToDisplayByEmail };
};
