import type { FC } from 'react';
import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import type { SnackbarCloseReason } from '@mui/material';
import { Snackbar } from '@mui/material';
import { useAppDispatch } from '@/store';
import { selectSuccessMessage } from '@/store/selectors/flashMessage';
import { setSuccessMessage } from '@/store/flashMessage';
import Alert from './Alert';

const Success: FC = () => {
  const dispatch = useAppDispatch();
  const successMessage = useSelector(selectSuccessMessage);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setSnackbarOpen(!!successMessage);
  }, [successMessage]);

  const handleSnackbarClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
    dispatch(setSuccessMessage(null));
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  );
};

export default memo(Success);
