import { persistStore } from 'redux-persist';
import { store } from '.';

let persistor: any;

export default () => {
  if (!persistor) {
    persistor = persistStore(store);
  }
  return persistor;
};
