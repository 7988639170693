import Dialog from '@/components/CreateScreen/Dialog';
import type { FC } from 'react';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from './styled/PanelContainer';

// eslint-disable-next-line arrow-body-style
const CreateScreen: FC = () => {
  const [open, setOpen] = useState(true);

  return (
    <PanelContainer>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      />
    </PanelContainer>
  );
};

export default Sentry.withErrorBoundary(CreateScreen, {
  fallback: ErrorFallbackComponent,
});
