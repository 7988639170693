import styled from 'styled-components';
import breakpoint, {
  tabletBreakpoint,
  smartphoneBreakpoint,
} from '@/breakpoint';

export default styled.div`
  margin-left: 260px;
  height: 100%;
  color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;

  ${breakpoint('lg')`
      margin-left: 315px;
  `}

  ${tabletBreakpoint`
    margin-left: 0px;
  `}

  ${smartphoneBreakpoint`
    margin-left: 0px;
  `}
`;
