import type { RootState } from '..';

export const selectDialogUsers = (state: RootState) => state.dialog.users;
export const selectDialogUsersLoading = (state: RootState) =>
  state.dialog.usersLoading;
export const selectDialogHasMoreStages = (state: RootState) =>
  state.dialog.nextStage !== null;
export const selectDialogStages = (state: RootState) => state.dialog.stages;
export const selectDialogStagesLoading = (state: RootState) =>
  state.dialog.stagesLoading;
