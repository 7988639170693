import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import SignInWithGoogleLogo from './SignInWithGoogleLogo';
import breakpoint from '../../../breakpoint';

export default styled(ButtonBase).attrs({
  focusVisible: true,
  classes: { root: 'MuiButton-contained MuiButton-root' },
})`
  height: 50px;
  background-color: #3e82f7;
  color: #fff;
  margin-left: 0 !important;
  margin-top: 16px;
  background-position: 18px;
  width: 100%;
  padding-left: 64px;

  &::before {
    width: 18px;
    background-color: #fff;
  }

  ${SignInWithGoogleLogo} {
    position: absolute;
    left: 2px;
  }

  * {
    font-size: 15px;
    text-transform: none;

    ${breakpoint('lg')`
     font-size: 16px;
  `}

    ${breakpoint('xl')`
        font-size: 22px;
    `}
  }

  ${breakpoint('lg')`
     height: 58px;
  `}

  ${breakpoint('xl')`
    height: 68px;
  `}
`;
