import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ObsDrawer from '@/components/Drawer/ObsDrawer';
import Form from '@/components/ObsConfiguration/Form';
import StagesSearch from '@/components/ObsConfiguration/StagesSearch';
import StagesTable from '@/components/ObsConfiguration/StagesTable';
import {
  getObsLinkById,
  getOBSStages,
  getUsedChannelAndStageIds,
} from '@/store/asyncThunks/obs';
import { selectDrawerObsLinks } from '@/store/selectors/drawer';
import { selectFirstOBSVideo } from '@/store/selectors/obs';
import DeleteConfirmationDialog from '@/components/ObsConfiguration/DeleteConfirmationDialog';
import { removeOBSLink } from '@/store/asyncThunks/drawer';
import { useAppDispatch } from '@/store';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from './styled/PanelContainer';

const ObsConfiguration: FC = () => {
  const params = useParams<{ id?: string }>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentObs = useSelector(selectFirstOBSVideo);
  const obsLinks = useSelector(selectDrawerObsLinks);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (params.id === undefined && obsLinks.length > 0) {
      navigate(`${location.pathname}/${obsLinks[0].id}`);
    }
  }, [obsLinks.length, navigate, params.id]);

  useEffect(() => {
    if (currentObs?.id) {
      const promise = dispatch(getUsedChannelAndStageIds(currentObs.id));

      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [dispatch, currentObs?.id]);

  useEffect(() => {
    if (params.id) {
      const promise = dispatch(getObsLinkById(params.id));

      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [params.id]);

  useEffect(() => {
    if (currentObs) {
      const promise = dispatch(getOBSStages(currentObs.stageId as string[]));

      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [currentObs]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = useCallback(() => {
    setOpenDialog(true);
    setAnchorEl(null);
  }, [setOpenDialog, setAnchorEl]);

  const handleDeleteConfirmation = useCallback(async () => {
    if (currentObs) {
      await dispatch(removeOBSLink(currentObs.id));

      navigate('/obs-configuration');
    }
    setOpenDialog(false);
  }, [currentObs, dispatch, setOpenDialog]);

  return (
    <>
      <ObsDrawer />
      {params.id && (
        <PanelContainer>
          <div style={{ padding: 48 }}>
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item>
                <IconButton onClick={handleClick} size="large">
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleDeleteClick}
                >
                  <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Form />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {currentObs && <StagesTable obsId={currentObs.id} />}
              </Grid>
              <Grid item xs={12}>
                {currentObs && <StagesSearch obsId={currentObs.id} />}
              </Grid>
            </Grid>
          </div>
        </PanelContainer>
      )}
      <DeleteConfirmationDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        onConfirm={handleDeleteConfirmation}
      />
    </>
  );
};

export default Sentry.withErrorBoundary(ObsConfiguration, {
  fallback: ErrorFallbackComponent,
});
