import styled from 'styled-components';
import { Typography } from '@mui/material';

export default styled(Typography).attrs({
  variant: 'subtitle2',
})`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
`;
