import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import TermsOfUseContainer from './styled/TermsOfUseContainer';

const TermsOfUse: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TermsOfUseContainer>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          gutterBottom
          color="textPrimary"
          align="center"
        >
          Terms of Submission to the Dusty Multiverse
        </Typography>
        <Typography variant="body1" gutterBottom color="textPrimary">
          1. By submitting this content, you provide appropriate licenses for
          its use in the Dusty Multiverse. When you submit or provide access to
          any content (the &quot;Content&quot;) for use within the Dusty
          Multiverse through Clipo, Inc.’s (&quot;Clipo&quot;) products or
          services (the &quot;Services&quot;), you grant Clipo and its
          affiliates a non-exclusive, worldwide, royalty-free, sublicensable,
          transferable right and license to use, host, publish, display
          (publicly or otherwise), perform (publicly or otherwise), distribute,
          transmit, modify, adapt (including, without limitation, in order to
          conform it to the requirements of any networks, devices, services, or
          media through which the Services are available), and create derivative
          works of the Content.&nbsp;The rights you grant in this license are
          for the purpose of presenting the Content in and/or through the
          Services.
        </Typography>
        <Typography variant="body1" gutterBottom color="textPrimary">
          2. You have all necessary rights, licenses and permissions to submit
          this content and have it presented publicly in the Dusty Multiverse.
          You represent and warrant that you have all necessary rights,
          licenses, and permissions to grant the above licenses and that the
          Content submitted by you, and the submission of such Content, do not
          and will not violate any intellectual property rights (including but
          not limited to copyrights and trademark rights) of any third party.
          You agree that you will indemnify, defend, and hold harmless Clipo,
          its parents, subsidiaries, affiliates, customers, vendors, hosts,
          officers, and employees from any liability, damage or cost (including
          reasonable attorneys&apos; fees and costs) from any claim or demand
          made by any third party due to violation of these representations and
          warranties, or otherwise arising out of any submitted Content.
        </Typography>
      </Container>
    </TermsOfUseContainer>
  );
};

export default Sentry.withErrorBoundary(TermsOfUse, {
  fallback: ErrorFallbackComponent,
});
