/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getUser,
  getUserCurrentlyPlayed,
  getUserStages,
  updateUserStage,
} from './asyncThunks/user';
import type { UserState } from './interfaces';

const initialState: UserState = {
  stages: [],
  currentlyPlayed: [],
  stagesLoading: false,
  currentlyPlayedLoading: false,
  current: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addStage: (state, action) => {
      state.stages = [...state.stages, action.payload];
    },
    removeStage: (state, action) => {
      state.stages = state.stages.filter(
        (stage) => stage.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCurrentlyPlayed.fulfilled, (state, action) => {
        state.currentlyPlayedLoading = false;
        state.currentlyPlayed = action.payload;
      })
      .addCase(getUserCurrentlyPlayed.pending, (state) => {
        state.currentlyPlayedLoading = true;
      })
      .addCase(getUserCurrentlyPlayed.rejected, (state) => {
        state.currentlyPlayedLoading = false;
      });

    builder
      .addCase(getUserStages.fulfilled, (state, action) => {
        state.stagesLoading = false;
        state.stages = action.payload;
      })
      .addCase(getUserStages.pending, (state) => {
        state.stagesLoading = true;
        state.stages = [];
      })
      .addCase(getUserStages.rejected, (state) => {
        state.stagesLoading = false;
        state.stages = [];
      });

    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.current = null;
      });

    builder.addCase(updateUserStage.fulfilled, (state, action) => {
      state.stages = [
        ...state.stages.filter((stage) => stage.id !== action.payload.id),
        action.payload,
      ];
    });
  },
});

export const { addStage, removeStage } = userSlice.actions;

export default userSlice.reducer;
