import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { selectStage } from '@/store/selectors/stage';
import { addBackupVideo } from '@/store/asyncThunks/stage';
import { useAppDispatch } from '@/store';
import EnterURLContainer from './styled/EnterURLContainer';
import AddLinkButton from './styled/AddLinkButton';

const validationSchema = Yup.object().shape({
  url: Yup.string().url().min(2, 'Too short').required('Required'),
});

const EnterURL: FC = () => {
  const dispatch = useAppDispatch();
  const stage = useSelector(selectStage);

  const handleAddLink = useCallback(
    (url: string) => {
      dispatch(addBackupVideo({ stageId: stage.id, url }));
    },
    [dispatch, stage]
  );

  const formik = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema,
    onSubmit: async ({ url }, { resetForm }) => {
      await handleAddLink(url);
      resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <EnterURLContainer>
        <TextField
          name="url"
          label="Enter URL"
          placeholder="Enter URL"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.url}
          onChange={formik.handleChange}
          error={!!formik.errors.url}
          helperText={formik.errors.url}
        />
      </EnterURLContainer>
      <AddLinkButton disabled={formik.isSubmitting} type="submit">
        Add link to display
      </AddLinkButton>
    </form>
  );
};

export default EnterURL;
