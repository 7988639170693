import type { FC } from 'react';
import React from 'react';
import FireworksComponent from '@/components/Fireworks';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from './styled/PanelContainer';

const Fireworks: FC = () => (
  <PanelContainer>
    <FireworksComponent />
  </PanelContainer>
);

export default Sentry.withErrorBoundary(Fireworks, {
  fallback: ErrorFallbackComponent,
});
