import type { FC } from 'react';
import React, { useState, useEffect, createRef } from 'react';
import {
  useParams,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import _ from 'lodash';
import Drawer from '@/components/Drawer';
import Head from '@/components/CampAddLink/Head';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from '../styled/PanelContainer';
import Schedule from './Schedule';
import useCampAddLinkData from './useCampAddLinkData';
import Live from './Live';
import BackupLink from './BackupLink';

const CampAddLink: FC = () => {
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState<string>('');
  const enterURLRef = createRef<HTMLButtonElement>();
  useCampAddLinkData();

  // eslint-disable-next-line no-console
  console.log(location);

  // useEffect(() => {
  //   const path = location.pathname.replace(href, '').replace('/', '');
  //   setTab(path);
  // }, [location.pathname, href]);

  useEffect(() => {
    setTab('');
  }, [params.id]);

  useEffect(() => {
    const basePath = _.replace(location.pathname, ':id', params.id as string);
    navigate(`${basePath}/${tab}`);
  }, [tab]);

  const handleDrawerAddLinkClick = () => {
    setTab('');
    if (!enterURLRef.current) {
      return;
    }
    enterURLRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <Drawer
        itemPath="/add-link"
        onAddLinkClick={handleDrawerAddLinkClick}
        showAddLink
      />
      {params.id && (
        <PanelContainer>
          <Head />
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab value="" label="Schedule" />
            <Tab value="live" label="Live" />
            <Tab value="backup-link" label="Backup link" />
          </Tabs>
          <Routes>
            <Route
              path={`${location.pathname}/`}
              element={<Schedule ref={enterURLRef} />}
            />
            <Route path={`${location.pathname}/live`} element={<Live />} />
            <Route
              path={`${location.pathname}/backup-link`}
              element={<BackupLink />}
            />
          </Routes>
        </PanelContainer>
      )}
    </>
  );
};

export default Sentry.withErrorBoundary(CampAddLink, {
  fallback: ErrorFallbackComponent,
});
