/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FlashMessageState } from '@/store/interfaces';

const initialState: FlashMessageState = {
  successMessage: null,
  errorMessage: null,
};

export const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState,
  reducers: {
    setSuccessMessage: (
      state,
      { payload }: PayloadAction<FlashMessageState['successMessage']>
    ) => {
      state.successMessage = payload;
    },
    setErrorMessage: (
      state,
      { payload }: PayloadAction<FlashMessageState['errorMessage']>
    ) => {
      state.errorMessage = payload;
    },
  },
});

export const { setSuccessMessage, setErrorMessage } = flashMessageSlice.actions;

export default flashMessageSlice.reducer;
