import type { FC } from 'react';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  CircularProgress,
  IconButton,
  TextField,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserRole } from '@myclipo/bm-admin-common';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { selectRateAdminStages, selectStage } from '@/store/selectors/stage';
import {
  addRateAdminStage,
  removeRateAdminStage,
  updateStage,
} from '@/store/asyncThunks/stage';
import { setIsVisibleOnMobile } from '@/store/drawer';
import { selectUserRole } from '@/store/selectors/auth';
import { useAppSelector } from '@/store/hooks';
import HeaderContainer from '../styled/HeaderContainer';
import MobileHidden from '../styled/MobileHidden';
import MobileVisible from '../styled/MobileVisible';
import StageNameForm from './styled/StageNameForm';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
});

const Head: FC = () => {
  const dispatch = useAppDispatch();
  const stage = useAppSelector(selectStage);
  const userRole = useAppSelector(selectUserRole);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const params = useParams<{ id: string }>();
  const rateAdminStages = useAppSelector(selectRateAdminStages);

  const isStageNameEditor = useMemo(
    () => userRole.includes(UserRole.StageNameEditor),
    [userRole]
  );
  const isStageEditor = useMemo(
    () => userRole.includes(UserRole.StageEditor),
    [userRole]
  );
  const isRateAdminStage = useMemo(
    () => rateAdminStages.includes(stage.id),
    [rateAdminStages, stage.id]
  );

  useEffect(() => {
    setIsEditing(false);
  }, [params.id]);

  const formik = useFormik({
    initialValues: {
      name: stage?.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (stage !== null) {
        await dispatch(
          updateStage({
            id: stage.id,
            stage: validationSchema.cast(values)!,
          })
        );
      }

      setIsEditing(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue('name', stage?.name);
  }, [stage?.name]);

  const toggleEdit = useCallback(() => {
    if (isStageNameEditor) {
      setIsEditing(!isEditing);
    }
  }, [isEditing, setIsEditing, isStageNameEditor]);

  return (
    <HeaderContainer maxWidth={false}>
      <IconButton
        onClick={() => dispatch(setIsVisibleOnMobile(true))}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>
      {!isEditing && (
        <>
          <MobileHidden>
            <Typography variant="h4">
              {!stage?.name && <CircularProgress size={30} />}
              {stage?.name}
            </Typography>
          </MobileHidden>
          <MobileVisible style={{ width: 'calc(100% - 96px)' }}>
            <Typography variant="h3" align="center">
              {!stage?.name && <CircularProgress size={30} />}
              {stage?.name}
            </Typography>
          </MobileVisible>
          {stage?.name && isStageNameEditor && (
            <IconButton onClick={toggleEdit} size="large">
              <EditIcon />
            </IconButton>
          )}
        </>
      )}
      {isEditing && (
        <StageNameForm onSubmit={formik.handleSubmit}>
          <TextField
            name="name"
            value={formik.values.name}
            placeholder="Name"
            onChange={formik.handleChange}
            error={!formik.isValid}
            helperText={formik.errors.name}
          />
          {stage?.name && (
            <IconButton
              type="submit"
              disabled={formik.isValidating || formik.isSubmitting}
              size="large"
            >
              <SaveIcon />
            </IconButton>
          )}
        </StageNameForm>
      )}
      <div style={{ flex: 1 }} />
      {isStageEditor && !isRateAdminStage && (
        <Button
          disabled={isUpdating}
          variant="contained"
          color="primary"
          onClick={async () => {
            setIsUpdating(true);
            await dispatch(addRateAdminStage(stage.id));
            setIsUpdating(false);
          }}
        >
          Add to Rate Admin
        </Button>
      )}
      {isStageEditor && isRateAdminStage && (
        <Button
          disabled={isUpdating}
          variant="contained"
          onClick={async () => {
            setIsUpdating(true);
            await dispatch(removeRateAdminStage(stage.id));
            setIsUpdating(false);
          }}
        >
          Remove from Rate Admin
        </Button>
      )}
    </HeaderContainer>
  );
};

export default memo(Head);
