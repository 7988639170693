import type { FC } from 'react';
import React, { useCallback } from 'react';
import { TableCell, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import type { CampLink, BackupCampLink } from '@myclipo/bm-admin-common';
import StyledTableRow from './styled/TableRow';
import Title from './styled/Title';
import LinkText from './styled/LinkText';

interface TableRowProps {
  active?: boolean;
  link: CampLink | BackupCampLink;
  onClick?: (id: string, url: string) => void;
  onRemove?: (id: string, url: string) => void;
}

const TableRow: FC<TableRowProps> = ({ active, link, onClick, onRemove }) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(link.id, link.url);
    }
  }, [onClick, link]);

  const handleRemove = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (onRemove) {
        onRemove(link.id, link.url);
      }
    },
    [onRemove, link]
  );

  return (
    <StyledTableRow active={active} onClick={handleClick}>
      <TableCell align="left">
        <Title active={active}>{link.title}</Title>
        <LinkText active={active}>{link.url}</LinkText>
      </TableCell>
      {onRemove && (
        <TableCell align="right">
          <IconButton color="primary" onClick={handleRemove} size="large">
            <CloseIcon />
          </IconButton>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

export default TableRow;
