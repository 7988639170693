import styled from 'styled-components';
import { DialogContent } from '@mui/material';
import FullWidthButton from '../../../styled/FullWidthButton';
import breakpoint from '../../../../breakpoint';

export default styled(DialogContent)`
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  width: 297px;

  ${FullWidthButton} {
    margin-top: 72px;
  }

  ${breakpoint('lg')`
    width: 336px;
  `}

  ${breakpoint('xl')`
    width: 396px;
  `}
`;
