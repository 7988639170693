import type { FC } from 'react';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Typography,
  Divider,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  List,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { includes, debounce, without } from 'lodash';
import { useParams } from 'react-router-dom';
import type { User } from '@myclipo/bm-admin-common';
import { UserRole } from '@myclipo/bm-admin-common';
import {
  selectDialogUsersLoading,
  selectDialogUsers,
} from '@/store/selectors/dialog';
import { getStageUsersWithAccess as getStageUsersWithAccessFunc } from '@/store/asyncThunks/stage';
import { sendInvite as sendInviteFunc } from '@/store/asyncThunks/user';
import { getDialogUsers } from '@/store/asyncThunks/dialog';
import useIsHD from '@/hooks/useIsHD';
import SearchField from '@/components/styled/SearchField';
import FullWidthButton from '@/components/styled/FullWidthButton';
import DialogContent from '@/components/styled/SearchListDialogContent';
import DialogTitle from '@/components/styled/DialogTitle';
import IconContainer from '@/components/styled/DialogIconContainer';
import StyledDialog from '@/components/styled/Dialog';
import ProgressContainer from '@/components/styled/ProgressContainer';
import Nav from '@/components/styled/Nav';
import ListItemText from '@/components/styled/ListItemText';
import ListItem from '@/components/styled/ListItem';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import useSearchValue from './useSearchValue';
import useAddUserToDisplayBySelected from './useAddUserToDisplayBySelected';
import useAddUserToDisplayByEmail from './useAddUserToDisplayByEmail';

interface DialogProps {
  onClose: (event: any) => void;
  open: boolean;
  disabledTabs?: UserRole[];
}

const tabRoleMap = new Map([
  [0, UserRole.Admin],
  [1, UserRole.DJ],
]);

const Dialog: FC<DialogProps> = ({ onClose, open, disabledTabs }) => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(selectDialogUsers);
  const usersLoading = useAppSelector(selectDialogUsersLoading);

  const [tab, setTab] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    searchValue,
    searchValueIsValidEmail,
    handleSearchValueChange,
    resetSearchValue,
  } = useSearchValue();

  const [activeItems, setActiveItems] = useState<string[]>([]);
  const params = useParams<{ id: string }>();
  const isHD = useIsHD();
  const getUsersDebounced = useMemo(
    () =>
      debounce(
        (arg?: Record<string, unknown>) => dispatch(getDialogUsers(arg)),
        500
      ),
    [dispatch]
  );

  const { handleAddUserToDisplayBySelected } =
    useAddUserToDisplayBySelected(activeItems);

  const { handleAddUserToDisplayByEmail } =
    useAddUserToDisplayByEmail(searchValue);

  const showInviteButton = useMemo(
    () => !usersLoading && users.length === 0 && searchValueIsValidEmail,
    [usersLoading, users.length, searchValueIsValidEmail]
  );

  const addButtonDisabled = useMemo(
    () =>
      (activeItems.length === 0 && !searchValueIsValidEmail) ||
      usersLoading ||
      isSubmitting,
    [activeItems.length, searchValueIsValidEmail, usersLoading, isSubmitting]
  );

  const inviteButtonDisabled = useMemo(
    () => !searchValueIsValidEmail || isSubmitting,
    [searchValueIsValidEmail, isSubmitting]
  );

  useEffect(() => {
    if (includes(disabledTabs ?? [], UserRole.Admin)) {
      setTab(1);
    }
  }, [disabledTabs]);

  useEffect(() => {
    getUsersDebounced({ email: searchValue, role: tabRoleMap.get(tab) });
  }, [searchValue, getUsersDebounced, tab]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    resetSearchValue();
  };

  const handleListItemClick = (id: string) => {
    if (includes(activeItems, id)) {
      setActiveItems(without(activeItems, id));
    } else {
      setActiveItems([...activeItems, id]);
    }
  };

  const handleClose = useCallback(() => {
    setActiveItems([]);
    resetSearchValue();
    onClose({});
  }, [onClose, resetSearchValue]);

  const handleAddUserToDisplay = useCallback(async () => {
    setIsSubmitting(true);
    if (activeItems.length > 0) {
      await handleAddUserToDisplayBySelected();
    } else if (searchValueIsValidEmail) {
      await handleAddUserToDisplayByEmail();
    }

    setIsSubmitting(false);
    handleClose();
  }, [
    activeItems,
    searchValueIsValidEmail,
    handleClose,
    handleAddUserToDisplayBySelected,
    handleAddUserToDisplayByEmail,
  ]);

  const handleInvite = useCallback(async () => {
    setIsSubmitting(true);

    await dispatch(
      sendInviteFunc({
        email: searchValue,
        role: tabRoleMap.get(tab) as UserRole,
        stageId: params.id,
      })
    );

    setIsSubmitting(false);
    handleClose();
    dispatch(getStageUsersWithAccessFunc({ stageId: params.id! }));
  }, [handleClose, searchValue, dispatch]);

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <DialogTitle>
        <IconContainer>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </IconContainer>
        <Typography align="center" variant="h5">
          Add user to screen
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Tabs
          centered
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          {!includes(disabledTabs ?? [], UserRole.Admin) && (
            <Tab value={0} label="Display moderator" />
          )}
          {!includes(disabledTabs ?? [], UserRole.DJ) && (
            <Tab value={1} label="FX moderator" />
          )}
        </Tabs>
        <Divider />
        <SearchField
          placeholder="Search user"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
        {usersLoading && (
          <ProgressContainer>
            <CircularProgress />
          </ProgressContainer>
        )}
        {!usersLoading && (
          <List component={Nav} style={{ maxHeight: isHD ? 180 : 120 }}>
            {users.map((user: User) => (
              <ListItem
                key={user.uid}
                button
                selected={includes(activeItems, user.uid)}
                onClick={() => handleListItemClick(user.uid)}
              >
                <ListItemText primary={user.email} />
              </ListItem>
            ))}
          </List>
        )}
        {showInviteButton ? (
          <FullWidthButton
            disabled={inviteButtonDisabled}
            onClick={handleInvite}
          >
            Invite a user
          </FullWidthButton>
        ) : (
          <FullWidthButton
            disabled={addButtonDisabled}
            onClick={handleAddUserToDisplay}
          >
            Add user to screen
          </FullWidthButton>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default Dialog;
