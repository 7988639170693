import type { FC } from 'react';
import React from 'react';
import { Typography, CircularProgress, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setIsVisibleOnMobile as setIsVisibleOnMobileFunc } from '@/store/drawer';
import { selectCamp } from '@/store/selectors/camp';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import HeaderContainer from '../styled/HeaderContainer';
import MobileHidden from '../styled/MobileHidden';
import MobileVisible from '../styled/MobileVisible';

const Head: FC = () => {
  const dispatch = useAppDispatch();
  const camp = useAppSelector(selectCamp);

  return (
    <HeaderContainer maxWidth={false}>
      <IconButton
        onClick={() => dispatch(setIsVisibleOnMobileFunc(true))}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>
      <MobileHidden>
        <Typography variant="h4">
          {!camp?.name && <CircularProgress size={30} />}
          {camp?.name}
        </Typography>
      </MobileHidden>
      <MobileVisible style={{ width: 'calc(100% - 96px)' }}>
        <Typography variant="h3" align="center">
          {!camp?.name && <CircularProgress size={30} />}
          {camp?.name}
        </Typography>
      </MobileVisible>
    </HeaderContainer>
  );
};

export default Head;
