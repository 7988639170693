import type { FC } from 'react';
import React from 'react';
import { CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Card from '../styled/Card';
import CardActions from '../styled/CardActions';
import Button from '../styled/FullWidthButton';
import Stepper from '../styled/Stepper';
import CompleteText from './styled/CompleteText';

const Complete: FC = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom align="center">
          Account recovery
        </Typography>
        <Stepper
          steps={3}
          activeStep={2}
          nextButton={<div />}
          backButton={<div />}
        />
        <CompleteText align="center">
          We have sent a message to your email address. Click the link to set a
          new password.
        </CompleteText>
      </CardContent>
      <CardActions>
        <Button onClick={() => navigate('/login')}>Go to Log In</Button>
      </CardActions>
    </Card>
  );
};

export default Complete;
