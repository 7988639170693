import { createAsyncThunk } from '@reduxjs/toolkit';
import type { NewUser, UpdateUser, User } from '@myclipo/bm-admin-common';
import { UserRole } from '@myclipo/bm-admin-common';
import axios from 'axios';
import {
  auth as firebaseAuth,
  googleAuthProvider,
  userCampNamesCollection,
  userRolesCollection,
} from '@/firebaseConfig';
import mapUser from '@/helpers/mapUser';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore/lite';
import { resetDrawerCamps, resetDrawerStages } from '../drawer';

const apiService: ApiService = apiServiceFactory();

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    dispatch(resetDrawerStages());
    dispatch(resetDrawerCamps());
    await firebaseAuth.signOut();
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }) => {
    const { user } = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    if (user) {
      const [token, userRoleDoc, campNameDoc] = await Promise.all([
        user.getIdToken(),
        getDoc(doc(userRolesCollection, user.uid)),
        getDoc(doc(userCampNamesCollection, user.uid)),
      ]);

      const userRole = userRoleDoc.data() as { role: UserRole[] };
      const campName = (campNameDoc.data() ?? { campName: '' }) as {
        campName: string;
      };

      const apiUser = mapUser(
        user,
        userRole,
        campName,
        userRole.role.includes(UserRole.FireworksAdmin),
        userRole.role.includes(UserRole.ObsAdmin)
      );

      return { token, apiUser };
    }

    return { token: null, apiUser: null };
  }
);

export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async () => {
    const { user } = await signInWithPopup(firebaseAuth, googleAuthProvider);

    if (user) {
      const [token, userRoleDoc, campNameDoc] = await Promise.all([
        user.getIdToken(),
        getDoc(doc(userRolesCollection, user.uid)),
        getDoc(doc(userCampNamesCollection, user.uid)),
      ]);

      const userRole = userRoleDoc.data() as { role: UserRole[] };
      const campName = (campNameDoc.data() ?? { campName: '' }) as {
        campName: string;
      };

      const apiUser = mapUser(
        user,
        userRole,
        campName,
        userRole.role.includes(UserRole.FireworksAdmin),
        userRole.role.includes(UserRole.ObsAdmin)
      );

      return { token, apiUser };
    }
    return { token: null, apiUser: null };
  }
);

export const updateAccount = createAsyncThunk(
  'users/updateAccount',
  async (
    { displayName, password, token, campName }: NewUser,
    { rejectWithValue }
  ) => {
    try {
      return await apiService.update<UpdateUser, User>('users', token, {
        displayName,
        password,
        campName,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data);
      }

      throw err;
    }
  }
);
