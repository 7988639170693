import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Typography, CircularProgress, Divider } from '@mui/material';
import { get } from 'lodash';
import {
  selectCurrentlyPlayed,
  selectStageLoading,
} from '@/store/selectors/stage';
import useIsMobile from '@/hooks/useIsMobile';
import { useAppSelector } from '@/store/hooks';
import Container from './styled/Container';
import InfoContainer from './styled/InfoContainer';
import LinkText from './styled/LinkText';
import Username from './styled/Username';
import Duration from './styled/Duration';
import ProgressContainer from '../styled/ProgressContainer';
import Player from './Player';

const CurrentVideo: FC = () => {
  const currentlyPlayed = useAppSelector(selectCurrentlyPlayed);
  const stageLoading = useAppSelector(selectStageLoading);
  const isMobile = useIsMobile();
  const videoId = useMemo(
    () => get(currentlyPlayed, 'videoId') ?? get(currentlyPlayed, 'id', ''),
    [currentlyPlayed]
  );
  const isLive = useMemo(
    () => get(currentlyPlayed, 'isLive', false),
    [currentlyPlayed]
  );

  return (
    <>
      {stageLoading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      <Divider />
      <Container hidden={stageLoading} isMobile={isMobile}>
        {currentlyPlayed && videoId.length > 0 && (
          <Player url={currentlyPlayed?.url} />
        )}
        {videoId.length > 0 && (
          <InfoContainer>
            <Typography variant="h6" gutterBottom>
              {currentlyPlayed?.title}
            </Typography>
            <LinkText>{currentlyPlayed?.url}</LinkText>
            {isLive && <Duration>LIVE STREAM</Duration>}
            {!isLive && <Duration>{get(currentlyPlayed, 'duration')}</Duration>}
            <Username>{get(currentlyPlayed, 'username')}</Username>
          </InfoContainer>
        )}
      </Container>
    </>
  );
};

export default React.memo(CurrentVideo);
