import {
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAreFireworksEnabled,
  selectFireworksLoading,
  setEnabled,
} from '@/store/fireworks';
import { useAppDispatch } from '@/store';
import useNewSockets from '@/hooks/useNewSockets';
import { WebsocketEvents } from '@myclipo/bm-admin-common';
import HeaderContainer from './styled/HeaderContainer';
import ProgressContainer from './styled/ProgressContainer';

const Fireworks: FC = () => {
  const dispatch = useAppDispatch();
  const newSocketsClient = useNewSockets();

  const areFireworksEnabled = useSelector(selectAreFireworksEnabled);
  const loading = useSelector(selectFireworksLoading);

  useEffect(() => {
    newSocketsClient.emit(WebsocketEvents.Incomming.GetFireworks, {});
  }, [newSocketsClient]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      newSocketsClient.emit(WebsocketEvents.Incomming.ChangeFireworks, {
        enabled: checked,
      });
    },
    [newSocketsClient]
  );

  useEffect(() => {
    newSocketsClient.on(
      WebsocketEvents.Outgoing.FireworksChanged,
      (data: { enabled: boolean }) => {
        dispatch(setEnabled(data.enabled));
      }
    );

    return () => {
      newSocketsClient.off(WebsocketEvents.Outgoing.FireworksChanged);
    };
  }, [newSocketsClient, dispatch]);

  return (
    <>
      <HeaderContainer maxWidth={false}>
        <Typography variant="h4">Fireworks</Typography>
      </HeaderContainer>
      <Divider />
      {!loading && (
        <Container
          style={{ paddingTop: 16, paddingBottom: 16, maxWidth: '100%' }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={areFireworksEnabled}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Fireworks enabled"
          />
        </Container>
      )}
      {loading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
    </>
  );
};

export default Fireworks;
