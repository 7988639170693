import type { FC } from 'react';
import React from 'react';
import BackupLinkComponent from '@/components/CampAddLink/BackupLink';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';

const BackupLink: FC = () => <BackupLinkComponent />;

export default Sentry.withErrorBoundary(BackupLink, {
  fallback: ErrorFallbackComponent,
});
