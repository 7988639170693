import type { FC } from 'react';
import React from 'react';
import Complete from '@/components/CreateAccountCard/Complete';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import CardPageContainer from './styled/CardPageContainer';

const CreateAccountComplete: FC = () => (
  <CardPageContainer>
    <Complete />
  </CardPageContainer>
);

export default Sentry.withErrorBoundary(CreateAccountComplete, {
  fallback: ErrorFallbackComponent,
});
