import { createBreakpoint } from 'styled-components-breakpoint';

export default createBreakpoint({
  xs: 0,
  sm: 300,
  md: 1280,
  lg: 1600,
  xl: 1920,
});

export const tabletBreakpoint = (styles: TemplateStringsArray) => `
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1) {
  ${styles}
}
`;

export const smartphoneBreakpoint = (styles: TemplateStringsArray) => `
/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 812px) {
  ${styles}
}
`;
