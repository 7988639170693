import styled from 'styled-components';
import { smartphoneBreakpoint } from '../../../breakpoint';

export default styled.div`
  max-width: 100%;
  padding-left: 32px;

  ${smartphoneBreakpoint`
    padding-left: 0;
    padding-top: 16px;
  `}
`;
