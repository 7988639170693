import type { FC } from 'react';
import React from 'react';
import { CardContent, Typography } from '@mui/material';
import Card from '../styled/Card';
import CardActions from '../styled/CardActions';
import Button from '../styled/FullWidthButton';
import Stepper from '../styled/Stepper';
import CompleteText from './styled/CompleteText';

const Complete: FC = () => (
  <Card>
    <CardContent>
      <Typography variant="h4" gutterBottom align="center">
        Creating account
      </Typography>
      <Stepper
        steps={3}
        activeStep={2}
        nextButton={<div />}
        backButton={<div />}
      />
      <CompleteText align="center">Account has been created.</CompleteText>
    </CardContent>
    <CardActions>
      <Button href="/login">Go to Log In</Button>
    </CardActions>
  </Card>
);

export default Complete;
