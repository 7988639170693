/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { assign, get, includes, pull, set } from 'lodash';
import type { Stage } from '@myclipo/bm-admin-common';
import { RateControl, StageEvent, VideoType } from '@myclipo/bm-admin-common';
import type { StageState } from './interfaces';
import {
  addBackupVideo,
  addRateAdminStage,
  addUserWithAccess,
  addUserWithAccessBulk,
  addVideo,
  changeCurrentlyPlayed,
  getAcceptedInvitesForUsersWithAccess,
  getBackupVideos,
  getCurrentlyPlayed,
  getFestivalDates,
  getLiveVideos,
  getRateAdminStageIds,
  getSchedulerPlaylist,
  getStage,
  getStageUsersWithAccess,
  getStageVideos,
  refreshBackupVideos,
  removeBackupVideo,
  removeRateAdminStage,
  removeUserWithAccess,
  removeVideo,
  removeVideosBulk,
  updateBackupVideo,
  updateBurningManStage,
  updateSchedulerPlaylistSettings,
  updateStage,
  updateUserWithAccess,
  updateVideo,
} from './asyncThunks/stage';

const initialState: StageState = {
  current: {
    id: '',
    number: 0,
    name: '',
    backupVideo: null,
    obs: null,
    image: null,
    schedulerPlaylist: null,
    isBurningMan: false,
    screenIsHidden: false,
    screenIsPlaying: false,
    burnFinished: false,
    burnStarted: false,
    isLaserOn: false,
    rate: 0,
    crowdEnabled: false,
    rateControl: RateControl.Manual,
    event: StageEvent.Burningman,
    schedulerControlled: false,
  },
  currentNotFound: false,
  schedulerPlaylist: {
    url: '',
    title: '',
    id: '',
    screenSize: 100,
    screenOffsetY: 0,
    screenBrightness: 1,
    audio: { muted: false },
    greenScreen: false,
    type: VideoType.SchedulerPlaylist,
  },
  schedulerPlaylistLoading: false,
  usersWithAccess: [],
  videos: {
    groupedVideos: {},
  },
  festivalDates: [],
  liveVideos: [],
  backupVideos: [],
  currentlyPlayed: null,
  acceptedInvites: [],
  stageLoading: false,
  videosLoading: false,
  liveVideosLoading: false,
  backupVideosLoading: false,
  rateAdminStages: [],
};

export const stageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    setLaser(state, { payload }) {
      state.current.isLaserOn = payload;
    },
    updateCurrent(state, { payload }) {
      Object.assign(state.current, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBackupVideo.fulfilled, (state, { payload }) => {
      const { id, ...values } = payload;
      state.backupVideos = state.backupVideos.map((video) =>
        video.id === id ? { ...video, ...values } : video
      );
    });
    builder.addCase(getFestivalDates.fulfilled, (state, action) => {
      state.festivalDates = action.payload;
    });

    builder
      .addCase(getStageUsersWithAccess.fulfilled, (state, action) => {
        state.stageLoading = false;
        state.usersWithAccess = action.payload;
      })
      .addCase(getStageUsersWithAccess.pending, (state) => {
        state.stageLoading = true;
      })
      .addCase(getStageUsersWithAccess.rejected, (state) => {
        state.stageLoading = false;
      });

    builder
      .addCase(getStageVideos.fulfilled, (state, action) => {
        state.videos = action.payload;
        state.videosLoading = false;
      })
      .addCase(getStageVideos.pending, (state) => {
        state.videosLoading = true;
      })
      .addCase(getStageVideos.rejected, (state) => {
        state.videosLoading = false;
      });

    builder
      .addCase(getCurrentlyPlayed.fulfilled, (state, action) => {
        state.stageLoading = false;
        state.currentlyPlayed = action.payload;
      })
      .addCase(getCurrentlyPlayed.pending, (state) => {
        state.stageLoading = true;
        state.currentlyPlayed = null;
      })
      .addCase(getCurrentlyPlayed.rejected, (state) => {
        state.stageLoading = false;
        state.currentlyPlayed = null;
      });

    builder.addCase(updateBurningManStage.fulfilled, (state, action) => {
      state.current = assign(state.current, action.payload);
    });
    builder.addCase(updateStage.fulfilled, (state, action) => {
      Object.assign(state.current, action.payload);
    });

    builder
      .addCase(getLiveVideos.fulfilled, (state, action) => {
        state.liveVideosLoading = false;
        state.liveVideos = action.payload;
      })
      .addCase(getLiveVideos.pending, (state) => {
        state.liveVideosLoading = true;
        state.liveVideos = [];
      })
      .addCase(getLiveVideos.rejected, (state) => {
        state.liveVideosLoading = false;
        state.liveVideos = [];
      });

    builder.addCase(refreshBackupVideos.fulfilled, (state, action) => {
      state.backupVideos = action.payload;
    });

    builder
      .addCase(getBackupVideos.fulfilled, (state, action) => {
        state.backupVideosLoading = false;
        state.backupVideos = action.payload;
      })
      .addCase(getBackupVideos.pending, (state) => {
        state.backupVideosLoading = true;
        state.backupVideos = [];
      });

    builder.addCase(addBackupVideo.fulfilled, (state, action) => {
      state.backupVideos.push(action.payload);
    });
    builder.addCase(removeBackupVideo.fulfilled, (state, { payload }) => {
      state.backupVideos = [
        ...state.backupVideos.filter(
          (backupVideo) => backupVideo.id !== payload.id
        ),
      ];
    });

    builder
      .addCase(getStage.fulfilled, (state, action) => {
        state.stageLoading = false;
        state.current = action.payload;
        state.currentNotFound = false;
      })
      .addCase(getStage.pending, (state) => {
        state.stageLoading = true;
        state.current = initialState.current;
        state.currentNotFound = false;
      })
      .addCase(getStage.rejected, (state, action) => {
        state.stageLoading = false;
        state.current = initialState.current;
        state.currentNotFound = get(action, 'payload.statusCode') === 404;
      });

    builder.addCase(addUserWithAccess.fulfilled, (state, action) => {
      state.usersWithAccess.push(action.payload);
    });
    builder.addCase(addUserWithAccessBulk.fulfilled, (state, action) => {
      state.usersWithAccess = [...state.usersWithAccess, ...action.payload];
    });

    builder.addCase(updateUserWithAccess.fulfilled, (state, { payload }) => {
      state.usersWithAccess = state.usersWithAccess.map((user) =>
        user.id === payload.id ? payload : user
      );
    });
    builder.addCase(removeUserWithAccess.fulfilled, (state, { payload }) => {
      state.usersWithAccess = [
        ...state.usersWithAccess.filter((user) => user.id !== payload.id),
      ];
    });
    builder.addCase(addVideo.fulfilled, (state, { payload }) => {
      const { videos } = state;

      if (!get(videos, `groupedVideos[${payload.date}]`)) {
        set(videos, `groupedVideos[${payload.date}]`, []);
      }
      videos.groupedVideos[payload.date].push(payload);
      state.videos = { ...videos };
    });
    builder.addCase(removeVideo.fulfilled, (state, { payload }) => {
      const { videos } = state;
      videos.groupedVideos[payload.date] = [
        ...state.videos.groupedVideos[payload.date].filter(
          (video) => video.id !== payload.id
        ),
      ];
      state.videos = videos;
    });
    builder.addCase(removeVideosBulk.fulfilled, (state, { payload }) => {
      const { videos } = state;
      videos.groupedVideos[payload.date] = [
        ...state.videos.groupedVideos[payload.date].filter(
          (video) => !includes(payload.ids, video.id)
        ),
      ];
      state.videos = videos;
    });
    builder.addCase(updateVideo.fulfilled, (state, { payload }) => {
      const { videos } = state;

      videos.groupedVideos[payload.date] = [
        ...state.videos.groupedVideos[payload.date].map((video) =>
          video.id === payload.id ? payload : video
        ),
      ];
      state.videos = { ...videos };
    });
    builder.addCase(
      getAcceptedInvitesForUsersWithAccess.fulfilled,
      (state, { payload }) => {
        state.acceptedInvites = payload;
      }
    );

    builder
      .addCase(getSchedulerPlaylist.pending, (state) => {
        state.schedulerPlaylistLoading = true;
      })
      .addCase(getSchedulerPlaylist.rejected, (state) => {
        state.schedulerPlaylistLoading = false;
      })
      .addCase(getSchedulerPlaylist.fulfilled, (state, { payload }) => {
        Object.assign(state.schedulerPlaylist, payload);
        state.schedulerPlaylistLoading = false;
      });

    builder.addCase(
      changeCurrentlyPlayed.fulfilled,
      (state, { payload: { type, id } }) => {
        const data: Partial<Stage> = {
          obs: null,
          backupVideo: null,
          image: null,
          schedulerPlaylist: null,
        };

        if (type !== 'none') {
          data[type] = { active: true, id: id! };
        }

        Object.assign(state.current, data);
      }
    );

    builder.addCase(
      updateSchedulerPlaylistSettings.fulfilled,
      (state, { payload }) => {
        Object.assign(state.schedulerPlaylist, payload);
      }
    );

    builder
      .addCase(getRateAdminStageIds.fulfilled, (state, { payload }) => {
        state.rateAdminStages = payload;
      })
      .addCase(getRateAdminStageIds.rejected, (state) => {
        state.rateAdminStages = [];
      });

    builder.addCase(addRateAdminStage.fulfilled, (state, { payload }) => {
      state.rateAdminStages.push(payload.stageId);
    });

    builder.addCase(removeRateAdminStage.fulfilled, (state, { payload }) => {
      state.rateAdminStages = pull(state.rateAdminStages, payload.stageId);
    });
  },
});

export const { setLaser, updateCurrent } = stageSlice.actions;

export default stageSlice.reducer;
