import styled from 'styled-components';
import { Button } from '@mui/material';
import breakpoint from '../../../breakpoint';

export default styled(Button).attrs({ fullWidth: true, variant: 'contained' })`
  margin: 24px 32px;
  height: 50px;
  background: #37393c 0% 0% no-repeat padding-box;
  color: #fff;
  text-transform: none;
  font-size: 15px;
  width: calc(100% - 64px);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  ${breakpoint('lg')`
     height: 58px;
     font-size: 16px;
  `}

  ${breakpoint('xl')`
    height: 68px;
    font-size: 22px;
  `}
`;
