import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Typography, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { CampLink } from '@myclipo/bm-admin-common';
import { useAppDispatch } from '@/store';
import { updateCampLink as updateCampLinkFunc } from '@/store/asyncThunks/camp';
import FullWidthButton from '../../styled/FullWidthButton';
import DialogTitle from '../../styled/DialogTitle';
import StyledDialog from '../../styled/Dialog';
import DialogIconContainer from '../../styled/DialogIconContainer';
import DialogContent from './styled/DialogContent';

interface DialogProps {
  onClose: (event: any) => void;
  open: boolean;
  link: CampLink | null;
}

const validationSchema = Yup.object().shape({
  url: Yup.string().url().min(2, 'Too short').required('Required'),
});

const EditLink: FC<DialogProps> = ({ onClose, open, link }) => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { url: link?.url ?? '' },
    validationSchema,
    onSubmit: async ({ url }, { resetForm }) => {
      if (link) {
        await dispatch(updateCampLinkFunc({ id: link.id, link: { url } }));
        resetForm();
        onClose({});
      }
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose({});
  }, [onClose, formik]);

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <DialogTitle>
        <DialogIconContainer>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </DialogIconContainer>
        <Typography align="center" variant="h5">
          Edit link
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            name="url"
            placeholder="Enter URL"
            value={formik.values.url}
            onChange={formik.handleChange}
            error={!!formik.errors.url}
            helperText={formik.errors.url}
          />
          <FullWidthButton disabled={formik.isSubmitting} type="submit">
            Edit link
          </FullWidthButton>
        </DialogContent>
      </form>
    </StyledDialog>
  );
};

export default EditLink;
