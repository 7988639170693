import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { selectStage } from '@/store/selectors/stage';
import { addStageImage } from '@/store/asyncThunks/image';
import { useAppDispatch } from '@/store';
import EnterURLContainer from './styled/EnterURLContainer';
import AddLinkButton from './styled/AddLinkButton';

const whitelist = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

const validationSchema = Yup.object().shape({
  title: Yup.string().min(2, 'Too short').required('Required'),
  description: Yup.string().min(2, 'Too short').optional(),
  file: Yup.mixed()
    .required('Required')
    .test(
      'fileFormat',
      'Images only',
      (value) => value && whitelist.includes(value.type)
    ),
});

const AddImage: FC = () => {
  const dispatch = useAppDispatch();
  const stage = useSelector(selectStage);

  const formik = useFormik({
    validationSchema,
    initialValues: { file: null as File | null, title: '', description: '' },
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(
          addStageImage({
            ...values,
            file: values.file as File,
            stageId: stage.id,
          })
        );

        resetForm();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <EnterURLContainer>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              name="title"
              label="Title"
              placeholder="Title"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.title}
              onChange={formik.handleChange}
              error={!!formik.errors.title}
              helperText={formik.errors.title}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="description"
              label="Description"
              placeholder="Description"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={!!formik.errors.description}
              helperText={formik.errors.description}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.file}>
              <InputLabel shrink>File</InputLabel>
              <Input
                name="file"
                type="file"
                inputProps={{ accept: 'image/*' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.files !== null) {
                    formik.setFieldValue('file', event.target.files[0]);
                  } else {
                    formik.setFieldValue('file', null);
                  }
                }}
              />
              <FormHelperText>{formik.errors.file}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </EnterURLContainer>
      <AddLinkButton disabled={formik.isSubmitting} type="submit">
        Add image to display
      </AddLinkButton>
    </form>
  );
};

export default AddImage;
