import React from 'react';
import styled from 'styled-components';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import breakpoint, { smartphoneBreakpoint } from '../../breakpoint';

export default styled(TextField).attrs({
  variant: 'outlined',
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon color="primary" />
      </InputAdornment>
    ),
  },
})`
  margin: 16px 24px 4px;
  min-height: 64px;

  .MuiInputBase-root {
    height: 48px;
  }

  ${breakpoint('xl')`
    margin: 24px 32px 16px;
  `}

  ${smartphoneBreakpoint`
    width: calc(100% - 48px);
  `}
`;
