import styled from 'styled-components';
import { tabletBreakpoint, smartphoneBreakpoint } from '@/breakpoint';

export default styled.div`
  display: none;

  ${tabletBreakpoint`
    display: block;
  `}

  ${smartphoneBreakpoint`
    display: block;
  `}
`;
