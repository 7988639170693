import type { FirebaseUser } from '@/types';
import type { User } from '@myclipo/bm-admin-common';
import { UserRole } from '@myclipo/bm-admin-common';

export default (
  user: FirebaseUser,
  { role }: { role: UserRole[] },
  { campName }: { campName: string },
  fireworksAdmin = false,
  obsAdmin = false
): User => ({
  uid: user.uid,
  displayName: user.displayName as string,
  photoURL: user.photoURL,
  email: user.email as string,
  emailVerified: user.emailVerified,
  role,
  lastSignInTime: user.metadata.lastSignInTime as string,
  creationTime: user.metadata.creationTime as string,
  campName,
  fireworksAdmin,
  obsAdmin: obsAdmin && role.includes(UserRole.SuperAdmin),
});
