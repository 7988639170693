import type { FC } from 'react';
import React, { useCallback } from 'react';
import { FormControlLabel, Switch, Container } from '@mui/material';
import type { Stage } from '@myclipo/bm-admin-common';
import { useSelector } from 'react-redux';
import { selectStage } from '@/store/selectors/stage';
import { updateBurningManStage } from '@/store/asyncThunks/stage';
import { useAppDispatch } from '@/store';

const BurningManOptions: FC = () => {
  const stage: Stage = useSelector(selectStage);
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateBurningManStage({
          id: stage.id,
          stage: { [event.target.name]: event.target.checked },
        })
      );
    },
    [stage, dispatch]
  );

  return (
    <Container style={{ paddingTop: 16, paddingBottom: 16, maxWidth: '100%' }}>
      <FormControlLabel
        control={
          <Switch
            checked={stage.screenIsHidden}
            onChange={handleChange}
            name="screenIsHidden"
            color="primary"
          />
        }
        label="Screen is hidden"
      />
      <FormControlLabel
        control={
          <Switch
            checked={stage.screenIsPlaying}
            onChange={handleChange}
            name="screenIsPlaying"
            color="primary"
          />
        }
        label="Screen is playing"
      />
      <FormControlLabel
        control={
          <Switch
            checked={stage.burnStarted}
            onChange={handleChange}
            name="burnStarted"
            color="primary"
          />
        }
        label="Burn started"
      />
      <FormControlLabel
        control={
          <Switch
            checked={stage.burnFinished}
            onChange={handleChange}
            name="burnFinished"
            color="primary"
          />
        }
        label="Burn finished"
      />
    </Container>
  );
};

export default BurningManOptions;
