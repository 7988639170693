import { Backdrop, CircularProgress, Divider } from '@mui/material';
import type { FC } from 'react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getSalvationSettings,
  getStageSettings,
  updateSalvationSettings,
  updateStageSettings,
} from '@/store/asyncThunks/settings';
import type { SalvationSettings, UpdateSettings } from '@/store/interfaces';
import {
  selectCurrentStageSettings,
  selectCurrentStageSettingsLoading,
  selectSalvationSettings,
} from '@/store/selectors/settings';
import { useAppDispatch } from '@/store';
import config from '@/config';
import Control from './Control';
import Header from './Header';
import useTowersFlamesState from './hooks/useFlamesState';
import HeaderBasic from './HeaderBasic';

interface Props {
  withBottomPadding?: boolean;
  stageId: string | null;
}

const SalvationStageSettings: FC<Props> = ({ withBottomPadding, stageId }) => {
  const dispatch = useAppDispatch();
  const settings = useSelector(selectSalvationSettings);
  const currentSettings = useSelector(selectCurrentStageSettings);
  const loading = useSelector(selectCurrentStageSettingsLoading);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const { state: flameState, handleFlamesChange } = useTowersFlamesState();

  useEffect(() => {
    dispatch(getSalvationSettings());
  }, []);

  useEffect(() => {
    if (stageId) {
      dispatch(getStageSettings(stageId));
    }
  }, [stageId]);

  const update = useCallback(
    async (values: Partial<SalvationSettings>) => {
      setPendingAction(true);
      await dispatch(updateSalvationSettings(values));
      setPendingAction(false);
    },
    [dispatch]
  );

  const updateForStage = useCallback(
    async (values: UpdateSettings) => {
      if (stageId) {
        setPendingAction(true);
        await dispatch(updateStageSettings({ stageId, values }));
        setPendingAction(false);
      }
    },
    [dispatch, stageId]
  );

  return (
    <div style={{ paddingBottom: withBottomPadding ? 128 : undefined }}>
      <Divider />
      <HeaderBasic />
      <Divider />
      <Control
        name="Fog Monster (behind the bridge)"
        on={currentSettings.leftFogMonster.on}
        onChange={(on) => {
          updateForStage({ leftFogMonster: { on } });
        }}
      />
      <Divider />
      <Control
        name="Fog Monster (behind the scene)"
        on={currentSettings.rightFogMonster.on}
        onChange={(on) => {
          updateForStage({ rightFogMonster: { on } });
        }}
      />
      <Divider />
      <Header />
      <Divider />
      <Control
        name="Main stage"
        bpm={currentSettings.mainStage.bpm}
        lightColor={currentSettings.mainStage.lightColor}
        lightEnabled={currentSettings.mainStage.lightEnabled}
        rateAutomaticCalculation={
          currentSettings.mainStage.rateAutomaticCalculation
        }
        flames={flameState[config.salvation.mainStageId].flames}
        onLightEnabledChange={(lightEnabled) => {
          updateForStage({
            mainStage: {
              lightEnabled,
            },
          });
        }}
        onLightColorChange={(lightColor) => {
          updateForStage({
            mainStage: {
              lightColor,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            mainStage: {
              rateAutomaticCalculation,
            },
          });
        }}
        onBPMChange={(bpm) => {
          updateForStage({
            mainStage: {
              bpm,
            },
          });
        }}
        onFlamesChange={handleFlamesChange(config.salvation.mainStageId)}
      />
      <Divider />
      <Control
        name="Left Tower"
        bpm={currentSettings.leftTower.bpm}
        lightColor={currentSettings.leftTower.lightColor}
        lightEnabled={currentSettings.leftTower.lightEnabled}
        rateAutomaticCalculation={
          currentSettings.leftTower.rateAutomaticCalculation
        }
        flames={flameState[config.salvation.leftTowerId].flames}
        onLightColorChange={(color) => {
          update({
            leftTower: {
              ...settings.leftTower,
              color,
            },
          });
          updateForStage({
            leftTower: {
              lightColor: color,
            },
          });
        }}
        onLightEnabledChange={(on) => {
          update({
            leftTower: {
              ...settings.leftTower,
              on,
            },
          });

          updateForStage({
            leftTower: {
              lightEnabled: on,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            leftTower: {
              rateAutomaticCalculation,
            },
          });
        }}
        onBPMChange={(bpm) => {
          updateForStage({
            leftTower: {
              bpm,
            },
          });
        }}
        onFlamesChange={handleFlamesChange(config.salvation.leftTowerId)}
      />
      <Divider />
      <Control
        name="Right Tower"
        bpm={currentSettings.rightTower.bpm}
        lightColor={currentSettings.rightTower.lightColor}
        lightEnabled={currentSettings.rightTower.lightEnabled}
        rateAutomaticCalculation={
          currentSettings.rightTower.rateAutomaticCalculation
        }
        flames={flameState[config.salvation.rightTowerId].flames}
        onLightColorChange={(color) => {
          update({
            rightTower: {
              ...settings.rightTower,
              color,
            },
          });

          updateForStage({
            rightTower: {
              lightColor: color,
            },
          });
        }}
        onLightEnabledChange={(on) => {
          update({
            rightTower: {
              ...settings.rightTower,
              on,
            },
          });

          updateForStage({
            rightTower: {
              lightEnabled: on,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            rightTower: {
              rateAutomaticCalculation,
            },
          });
        }}
        onBPMChange={(bpm) => {
          updateForStage({
            rightTower: {
              bpm,
            },
          });
        }}
        onFlamesChange={handleFlamesChange(config.salvation.rightTowerId)}
      />
      <Divider />
      <Control
        name="Trees"
        bpm={currentSettings.trees.bpm}
        lightColor={currentSettings.trees.lightColor}
        lightEnabled={currentSettings.trees.lightEnabled}
        rateAutomaticCalculation={
          currentSettings.trees.rateAutomaticCalculation
        }
        onLightColorChange={(color) => {
          update({
            trees: {
              ...settings.rightTower,
              color,
            },
          });

          updateForStage({
            trees: {
              lightColor: color,
            },
          });
        }}
        onLightEnabledChange={(on) => {
          update({
            trees: {
              ...settings.trees,
              on,
            },
          });

          updateForStage({
            trees: {
              lightEnabled: on,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            trees: {
              rateAutomaticCalculation,
            },
          });
        }}
        onBPMChange={(bpm) => {
          updateForStage({
            trees: {
              bpm,
            },
          });
        }}
      />
      <Divider />
      <Control
        name="Title"
        lightColor={settings.title.color}
        lightEnabled={settings.title.on}
        rateAutomaticCalculation={
          currentSettings.title.rateAutomaticCalculation
        }
        onLightEnabledChange={(lightEnabled) => {
          update({
            title: {
              ...settings.title,
              on: lightEnabled,
            },
          });

          updateForStage({
            title: {
              lightEnabled,
            },
          });
        }}
        onLightColorChange={(lightColor) => {
          update({
            title: {
              ...settings.title,
              color: lightColor,
            },
          });

          updateForStage({
            title: {
              lightColor,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            title: {
              rateAutomaticCalculation,
            },
          });
        }}
      />
      <Divider />
      <Control
        name="Zeurobo (left)"
        bpm={currentSettings.leftZeurobo.bpm}
        lightColor={currentSettings.leftZeurobo.lightColor}
        lightEnabled={currentSettings.leftZeurobo.lightEnabled}
        rateAutomaticCalculation={
          currentSettings.leftZeurobo.rateAutomaticCalculation
        }
        onLightColorChange={(color) => {
          updateForStage({
            leftZeurobo: {
              lightColor: color,
            },
          });
        }}
        onLightEnabledChange={(on) => {
          updateForStage({
            leftZeurobo: {
              lightEnabled: on,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            leftZeurobo: {
              rateAutomaticCalculation,
            },
          });
        }}
        onBPMChange={(bpm) => {
          updateForStage({
            leftZeurobo: {
              bpm,
            },
          });
        }}
      />
      <Divider />
      <Control
        name="Zeurobo (right)"
        bpm={currentSettings.rightZeurobo.bpm}
        lightColor={currentSettings.rightZeurobo.lightColor}
        lightEnabled={currentSettings.rightZeurobo.lightEnabled}
        rateAutomaticCalculation={
          currentSettings.rightZeurobo.rateAutomaticCalculation
        }
        onLightColorChange={(color) => {
          updateForStage({
            rightZeurobo: {
              lightColor: color,
            },
          });
        }}
        onLightEnabledChange={(on) => {
          updateForStage({
            rightZeurobo: {
              lightEnabled: on,
            },
          });
        }}
        onRateAutomaticCalculationChange={(rateAutomaticCalculation) => {
          updateForStage({
            rightZeurobo: {
              rateAutomaticCalculation,
            },
          });
        }}
        onBPMChange={(bpm) => {
          updateForStage({
            rightZeurobo: {
              bpm,
            },
          });
        }}
      />
      <Backdrop style={{ zIndex: 5000 }} open={pendingAction || loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default memo(SalvationStageSettings);
