import type { FC } from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import type { SnackbarCloseReason } from '@mui/material';
import { Snackbar } from '@mui/material';
import { selectErrorMessage } from '@/store/selectors/flashMessage';
import { setErrorMessage } from '@/store/flashMessage';
import { useAppDispatch } from '@/store';
import Alert from './Alert';

const Error: FC = () => {
  const dispatch = useAppDispatch();
  const error = useSelector(selectErrorMessage);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setSnackbarOpen(!!error);
  }, [error]);

  const handleSnackbarClose = useCallback(
    (
      event: Event | React.SyntheticEvent<any, Event>,
      reason?: SnackbarCloseReason
    ) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackbarOpen(false);
      dispatch(setErrorMessage(null));
    },
    []
  );

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  );
};

export default Error;
