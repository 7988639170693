import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDialogUsers } from '@/store/selectors/dialog';

export default () => {
  const users = useSelector(selectDialogUsers);

  const userEmailsMap = useMemo(
    () => new Map(users.map((user) => [user.uid, user.email])),
    [users]
  );

  return {
    userEmailsMap,
  };
};
