import type { FC } from 'react';
import React from 'react';
import LiveComponent from '@/components/CampAddLink/Live';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';

const Live: FC = () => <LiveComponent />;

export default Sentry.withErrorBoundary(Live, {
  fallback: ErrorFallbackComponent,
});
