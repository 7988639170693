import { StageEvent } from '@myclipo/bm-admin-common';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NotFound from '@/components/NotFound';
import SalvationStageSettings from '@/components/SalvationStageSettings';
import { selectStage } from '@/store/selectors/stage';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';

const Settings: FC = () => {
  const params = useParams<{ id?: string }>();
  const stage = useSelector(selectStage);

  if (stage.event !== StageEvent.Salvation) {
    return <NotFound />;
  }

  return <SalvationStageSettings stageId={params.id ?? null} />;
};

export default Sentry.withErrorBoundary(Settings, {
  fallback: ErrorFallbackComponent,
});
