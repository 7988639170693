import type { FC } from 'react';
import React from 'react';
import LoginCard from '@/components/LoginCard';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import CardPageContainer from './styled/CardPageContainer';

const Login: FC = () => (
  <CardPageContainer>
    <LoginCard />
  </CardPageContainer>
);

export default Sentry.withErrorBoundary(Login, {
  fallback: ErrorFallbackComponent,
});
