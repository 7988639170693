import type { FC } from 'react';
import React from 'react';
import AccountRecoveryCard from '@/components/AccountRecoveryCard';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import * as Sentry from '@sentry/react';
import CardPageContainer from './styled/CardPageContainer';

const AccountRecovery: FC = () => (
  <CardPageContainer>
    <AccountRecoveryCard />
  </CardPageContainer>
);

export default Sentry.withErrorBoundary(AccountRecovery, {
  fallback: ErrorFallbackComponent,
});
