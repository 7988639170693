import type { FC } from 'react';
import React, { memo } from 'react';
import type { Camp } from '@myclipo/bm-admin-common';
import BackupBadge from './styled/BackupBadge';
import DisplayNameContainer from './styled/DisplayNameContainer';

interface CampNameProps {
  camp: Camp;
}

const CampName: FC<CampNameProps> = ({ camp }) => (
  <DisplayNameContainer>
    {camp.name}
    {camp.backupLink?.active && <BackupBadge>Backup link</BackupBadge>}
  </DisplayNameContainer>
);

export default memo(CampName);
