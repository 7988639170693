import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '..';

export const selectOBSVideos = (state: RootState) => state.obs.list;
export const selectOBSIds = (state: RootState) => state.obs.ids;

export const selectFirstOBSVideo = createSelector(
  (state: RootState) => state.obs.list,
  (current) => (current.length > 0 ? current[0] : null)
);

export const selectOBSLoading = (state: RootState) => state.obs.loading;
export const selectOBSUpdating = (state: RootState) => state.obs.updating;

export const selectOBSChannelStatus = (state: RootState) =>
  state.obs.channelStatus;
export const selectOBSStages = (state: RootState) => state.obs.stages;

export const selectOBSUsedChannelIds = (state: RootState) =>
  state.obs.usedChannelIds;

export const selectOBSChannelHistory = (state: RootState) =>
  state.obs.channelHistory;
