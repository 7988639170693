import type { FC } from 'react';
import React, { useEffect } from 'react';
import { CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setLoginError as setLoginErrorFunc } from '@/store/auth';
import { useAppDispatch } from '@/store';
import Card from '../styled/Card';
import CardActions from '../styled/CardActions';
import Button from '../styled/FullWidthButton';
import ErrorText from './styled/ErrorText';

const Error: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLoginErrorFunc(false));
  }, [dispatch]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom align="center">
          Log In
        </Typography>
        <ErrorText align="center">Wrong email address or password.</ErrorText>
      </CardContent>
      <CardActions>
        <Button onClick={() => navigate('/login')}>Back to Log In</Button>
      </CardActions>
    </Card>
  );
};

export default Error;
