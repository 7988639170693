/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  addBackupLink,
  addCampLink,
  addUserWithAccess,
  getBackupLinks,
  getCamp,
  getCampLinks,
  getCampUsersWithAccess,
  getLiveLinks,
  removeBackupLink,
  removeCampLink,
  removeUserWithAccess,
  updateCamp,
  updateCampLink,
} from './asyncThunks/camp';
import type { CampState } from './interfaces';

const initialState: CampState = {
  current: {
    id: '',
    number: 0,
    name: '',
    backupLink: null,
  },
  links: [],
  backupLinks: [],
  liveLinks: [],
  backupLinksLoading: false,
  liveLinksLoading: false,
  campLoading: false,
  campLinksLoading: false,
  usersWithAccess: [],
};

export const campSlice = createSlice({
  name: 'camp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCamp.fulfilled, (state, action) => {
      state.current = action.payload;
    });

    builder
      .addCase(getLiveLinks.pending, (state) => {
        state.liveLinksLoading = true;
      })
      .addCase(getLiveLinks.rejected, (state) => {
        state.liveLinksLoading = false;
      })
      .addCase(getLiveLinks.fulfilled, (state, action) => {
        state.liveLinks = action.payload;
        state.liveLinksLoading = false;
      });

    builder
      .addCase(getBackupLinks.pending, (state) => {
        state.backupLinksLoading = true;
      })
      .addCase(getBackupLinks.rejected, (state) => {
        state.backupLinksLoading = false;
      })
      .addCase(getBackupLinks.fulfilled, (state, action) => {
        state.backupLinks = action.payload;
        state.backupLinksLoading = false;
      });

    builder.addCase(removeBackupLink.fulfilled, (state, action) => {
      state.backupLinks = state.backupLinks.filter(
        (link) => link.id !== action.payload.id
      );
      state.current = { ...state.current, backupLink: null };
    });
    builder.addCase(addBackupLink.fulfilled, (state, action) => {
      state.backupLinks.push(action.payload);
    });
    builder.addCase(updateCampLink.fulfilled, (state, action) => {
      const { links } = state;

      state.links = links.map((video) =>
        video.id === action.payload.id ? action.payload : video
      );
    });
    builder.addCase(removeCampLink.fulfilled, (state, action) => {
      const { links } = state;
      state.links = links.filter((link) => link.id !== action.payload.id);
    });
    builder.addCase(addCampLink.fulfilled, (state, action) => {
      state.links.push(action.payload);
    });

    builder
      .addCase(getCamp.pending, (state) => {
        state.campLoading = true;
      })
      .addCase(getCamp.rejected, (state) => {
        state.campLoading = false;
      })
      .addCase(getCamp.fulfilled, (state, action) => {
        state.current = action.payload;
        state.campLoading = false;
      });

    builder
      .addCase(getCampLinks.pending, (state) => {
        state.campLinksLoading = true;
      })
      .addCase(getCampLinks.rejected, (state) => {
        state.campLinksLoading = false;
      })
      .addCase(getCampLinks.fulfilled, (state, action) => {
        state.links = action.payload;
        state.campLinksLoading = false;
      });

    builder.addCase(getCampUsersWithAccess.fulfilled, (state, action) => {
      state.usersWithAccess = action.payload;
    });

    builder.addCase(addUserWithAccess.fulfilled, (state, action) => {
      state.usersWithAccess = [...state.usersWithAccess, action.payload];
    });

    builder.addCase(removeUserWithAccess.fulfilled, (state, action) => {
      state.usersWithAccess = [
        ...state.usersWithAccess.filter(
          (user) => user.id !== action.payload.id
        ),
      ];
    });
  },
});

export default campSlice.reducer;
