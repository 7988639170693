import styled from 'styled-components';
import breakpoint from '../../../../breakpoint';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 720px;
  min-height: 405px;

  ${breakpoint('lg')`
     min-width: 816px;
     min-height: 459px;
  `}

  ${breakpoint('xl')`
    min-width: 960px;
    min-height: 540px;
  `}
`;
