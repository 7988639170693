import StyledTableRow from '@/components/AddLink/Playlist/TableRow/styled/TableRow';
import {
  Checkbox,
  FormControlLabel,
  Slider,
  TableCell,
  Typography,
} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Title from '@/components/AddLink/Playlist/TableRow/styled/Title';
import BottomText from '@/components/AddLink/Playlist/TableRow/styled/BottomText';
import ControlsContainer from '@/components/AddLink/styled/ControlsContainer';
import SliderContainer from '@/components/AddLink/Playlist/TableRow/styled/SliderContainer';
import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import type {
  SchedulerPlaylist as SchedulerPlaylistEntity,
  UpdateSchedulerPlaylist,
} from '@myclipo/bm-admin-common';
import SchedulerControlledContext from '@/context/SchedulerControlledContext';

interface TableRowProps {
  schedulerPlaylist: SchedulerPlaylistEntity;
  active: boolean;
  onRowClick: (id: string) => void;
  onSettingChange: (data: UpdateSchedulerPlaylist) => void;
}
const TableRow: FC<TableRowProps> = ({
  schedulerPlaylist,
  active,
  onRowClick,
  onSettingChange,
}) => {
  const { schedulerControlled } = useContext(SchedulerControlledContext);

  const handleGreenScreenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSettingChange({ greenScreen: checked });
    },
    [onSettingChange]
  );

  const handleMuteAudioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSettingChange({ audio: { muted: checked } });
    },
    [onSettingChange]
  );

  const handleScreenSizeChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange({
        screenSize: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange]
  );

  const handleScreenOffsetYChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange({
        screenOffsetY: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, schedulerPlaylist.id]
  );

  const handleScreenBrightnessChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange({
        screenBrightness: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, schedulerPlaylist.id]
  );

  const handleClick = useCallback(() => {
    if (!schedulerControlled && schedulerPlaylist.id.length > 0) {
      onRowClick(schedulerPlaylist.id);
    }
  }, [onRowClick, schedulerPlaylist.id, schedulerControlled]);

  return (
    <StyledTableRow active={!!active} onClick={handleClick}>
      <TableCell style={{ width: 180, textAlign: 'center' }} align="left">
        <PlayCircleOutlineIcon
          style={{ fontSize: 50 }}
          color={active ? 'primary' : undefined}
        />
      </TableCell>
      <TableCell align="left" onClick={(event) => event.stopPropagation()}>
        <Title active={!!active}>Scheduler</Title>
        <BottomText active={!!active}>
          Playlist: {schedulerPlaylist.title}
        </BottomText>
        <BottomText active={!!active}>{schedulerPlaylist.url}</BottomText>

        <ControlsContainer>
          <FormControlLabel
            style={{ flex: 1 }}
            control={
              <Checkbox
                checked={schedulerPlaylist.greenScreen}
                onChange={handleGreenScreenChange}
                color="primary"
                onClick={(event) => event.stopPropagation()}
              />
            }
            label="Green screen"
          />

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen size
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}%`}
              defaultValue={schedulerPlaylist.screenSize}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              step={10}
              marks
              min={50}
              max={200}
              onChangeCommitted={handleScreenSizeChange}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen offset Y
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}cm`}
              defaultValue={schedulerPlaylist.screenOffsetY}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}cm`}
              step={10}
              marks
              min={-250}
              max={250}
              onChangeCommitted={handleScreenOffsetYChange}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen brightness
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}`}
              defaultValue={schedulerPlaylist.screenBrightness}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}`}
              step={0.1}
              marks
              min={0}
              max={1}
              onChangeCommitted={handleScreenBrightnessChange}
            />
          </SliderContainer>

          <FormControlLabel
            style={{ flex: 1, marginLeft: 8 }}
            control={
              <Checkbox
                checked={schedulerPlaylist.audio.muted}
                onChange={handleMuteAudioChange}
                color="primary"
                onClick={(event) => event.stopPropagation()}
              />
            }
            label="Mute audio"
          />
        </ControlsContainer>
      </TableCell>
    </StyledTableRow>
  );
};

export default TableRow;
