import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default styled(LazyLoadImage).attrs({ width: 220, height: 'auto' })`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 500;
  margin: auto;
  object-fit: contain;
`;
