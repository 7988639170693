const salvationMainStageId =
  import.meta.env.REACT_APP_SALVATION_MAIN_STAGE || '';
const salvationLeftTowerId =
  import.meta.env.REACT_APP_SALVATION_LEFT_TOWER_STAGE || '';
const salvationRightTowerId =
  import.meta.env.REACT_APP_SALVATION_RIGHT_TOWER_STAGE || '';

const laserOn = 'FylcnYIwolhjnM8Kxt4v';
const laserOff = 'ZfBHPMxOTOwwODi1TYbr';

export default {
  api: { url: import.meta.env.REACT_APP_API_URL },
  websocket: {
    newUrl: import.meta.env.REACT_APP_NEW_WEBSOCKET_URL,
    url: import.meta.env.REACT_APP_WEBSOCKET_URL,
  },
  effects: { laserOn, laserOff },
  salvation: {
    mainStageId: salvationMainStageId,
    leftTowerId: salvationLeftTowerId,
    rightTowerId: salvationRightTowerId,
    towerIds: [
      salvationLeftTowerId,
      salvationRightTowerId,
      salvationMainStageId,
    ],
    flamesOnEffect: import.meta.env.REACT_APP_SALVATION_FLAMES_ON_EFFECT || '',
    flamesOffEffect:
      import.meta.env.REACT_APP_SALVATION_FLAMES_OFF_EFFECT || '',
  },
  timezone: import.meta.env.REACT_APP_TIMEZONE as string,
};
