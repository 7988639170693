import { createAsyncThunk } from '@reduxjs/toolkit';
import type {
  User,
  Stage,
  Camp,
  UpdateOBSVideo,
  OBSVideo,
} from '@myclipo/bm-admin-common';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import { obsLinksCollection } from '@/firebaseConfig';
import mapOBSLink from '@/helpers/mapOBSLink';
import { addDoc, deleteDoc, doc, getDoc } from 'firebase/firestore/lite';
import axios from 'axios';
import type { RootState } from '..';

const apiService: ApiService = apiServiceFactory();

export const addOBSLink = createAsyncThunk(
  'drawer/addOBSLink',
  async (obs: UpdateOBSVideo) => {
    const ref = await addDoc(obsLinksCollection, obs);
    const d = await getDoc(ref);

    return mapOBSLink(d);
  }
);

export const removeOBSLink = createAsyncThunk(
  'drawer/removeOBSLink',
  async (id: string) => {
    await deleteDoc(doc(obsLinksCollection, id));

    return id;
  }
);

export const getDrawerUsers = createAsyncThunk(
  'drawer/getUsers',
  async (params: Record<string, unknown> = {}, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAll<User>('users', params, false, source.token);
  }
);

export const getDrawerStages = createAsyncThunk(
  'drawer/getStages',
  async (params: Record<string, unknown> = {}, { getState, signal }) => {
    const state = getState() as RootState;

    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllPaginated<Stage>(
      'stages',
      params,
      state.drawer.forceRefreshStages,
      source.token
    );
  }
);

export const getMoreDrawerStages = createAsyncThunk(
  'drawer/getMoreStages',
  async (params: Record<string, unknown> = {}, { getState, signal }) => {
    const state = getState() as RootState;

    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const newParams = { ...params, from: state.drawer.nextStage };
    return apiService.getAllPaginated<Stage>(
      'stages',
      newParams,
      false,
      source.token
    );
  }
);

export const getDrawerCamps = createAsyncThunk(
  'drawer/getCamps',
  async (params: Record<string, unknown> = {}, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllPaginated<Camp>(
      'camps',
      params,
      false,
      source.token
    );
  }
);

export const getMoreDrawerCamps = createAsyncThunk(
  'drawer/getMoreCamps',
  async (params: Record<string, unknown> = {}, { getState, signal }) => {
    const state = getState() as RootState;

    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const newParams = { ...params, from: state.drawer.nextCamp };
    return apiService.getAllPaginated<Camp>(
      'camps',
      newParams,
      false,
      source.token
    );
  }
);

export const getDrawerObsLinks = createAsyncThunk(
  'drawer/getDrawerObsLinks',
  async (_, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAll<OBSVideo>('obs', {}, false, source.token);
  }
);
