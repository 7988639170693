import type { FC } from 'react';
import React from 'react';
import { Typography, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAppDispatch } from '@/store';
import FullWidthButton from '../../styled/FullWidthButton';
import DialogTitle from '../../styled/DialogTitle';
import StyledDialog from '../../styled/Dialog';
import DialogIconContainer from '../../styled/DialogIconContainer';
import DialogContent from './styled/DialogContent';
import { addCampLink as addCampLinkFunc } from '../../../store/asyncThunks/camp';

interface DialogProps {
  onClose: (event: any) => void;
  date: string;
  open: boolean;
}

const validationSchema = Yup.object().shape({
  url: Yup.string().url().min(2, 'Too short').required('Required'),
});

const Dialog: FC<DialogProps> = ({ onClose, open, date }) => {
  const dispatch = useAppDispatch();

  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: { url: '' },
    validationSchema,
    onSubmit: async ({ url }, { resetForm }) => {
      await dispatch(addCampLinkFunc({ campId: params.id!, url, date }));
      onClose({});
      resetForm();
    },
  });

  return (
    <StyledDialog onClose={onClose} open={open}>
      <DialogTitle>
        <DialogIconContainer>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </DialogIconContainer>
        <Typography align="center" variant="h5">
          Add link to camp
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            name="url"
            placeholder="Enter URL"
            value={formik.values.url}
            onChange={formik.handleChange}
            error={!!formik.errors.url}
            helperText={formik.errors.url}
          />
          <FullWidthButton disabled={formik.isSubmitting} type="submit">
            Add link to camp
          </FullWidthButton>
        </DialogContent>
      </form>
    </StyledDialog>
  );
};

export default Dialog;
