import styled from 'styled-components';
import { Container } from '@mui/material';
import { smartphoneBreakpoint } from '../../../breakpoint';

export default styled(Container)<{ isMobile?: boolean }>`
  padding: 32px;
  width: 100%;
  max-width: 100%;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: left;

  ${({ isMobile }) => isMobile && `flex-direction: column;`}

  ${smartphoneBreakpoint`
    padding: 16px;
  `}
`;
