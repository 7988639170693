import type { FC } from 'react';
import React from 'react';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { UserRole } from '@myclipo/bm-admin-common';
import EffectsComponent from '@/components/AddEffects/Effects';
import CurrentVideo from '@/components/AddEffects/CurrentVideo';
import { selectUserRole } from '@/store/selectors/auth';
import NotFound from '@/components/NotFound';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import useEffectsData from './useEffectsData';

const Effects: FC = () => {
  const userRole = useSelector(selectUserRole);
  useEffectsData();

  if (!userRole.includes(UserRole.DJ)) {
    return <NotFound />;
  }

  return (
    <>
      <Divider />
      <CurrentVideo />
      <Divider />
      <EffectsComponent />
    </>
  );
};

export default Sentry.withErrorBoundary(Effects, {
  fallback: ErrorFallbackComponent,
});
