import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getStage,
  getStageUsersWithAccess,
  getFestivalDates,
  getAcceptedInvitesForUsersWithAccess,
  getBackupVideos,
  getSchedulerPlaylist,
  getRateAdminStageIds,
} from '@/store/asyncThunks/stage';
import { selectToken } from '@/store/selectors/auth';
import useIsMobile from '@/hooks/useIsMobile';
import { setIsVisibleOnMobile } from '@/store/drawer';
import { selectStage, selectUsersWithAccess } from '@/store/selectors/stage';
import { getOBSLinks } from '@/store/asyncThunks/obs';
import { selectOBSIds } from '@/store/selectors/obs';
import { setChannelStatus } from '@/store/obs';
import { getStageImages } from '@/store/asyncThunks/image';
import { updateCurrent } from '@/store/stage';
import { useAppDispatch } from '@/store';
import useTabInactive from '@/hooks/useTabInactive';
import config from '@/config';
import useNewSockets from '@/hooks/useNewSockets';
import { useAppSelector } from '@/store/hooks';

const useStageData = () => {
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const isMobile = useIsMobile();
  const usersWithAccess = useAppSelector(selectUsersWithAccess);
  const obsIds = useAppSelector(selectOBSIds);
  const stage = useAppSelector(selectStage);
  const tabInactive = useTabInactive();
  const newSocketsClient = useNewSockets();

  useEffect(() => {
    newSocketsClient.on('currently-played-changed', (data: any) => {
      if (data.stageId === stage.id) {
        // eslint-disable-next-line no-console
        console.log('[websocket]', '[currently-played-changed]', data);
      }
    });
    newSocketsClient.on('video-settings-changed', (data: any) => {
      if (data.stageId === stage.id) {
        // eslint-disable-next-line no-console
        console.log('[websocket]', '[video-settings-changed]', data);
      }
    });

    return () => {
      newSocketsClient.off('currently-played-changed');
      newSocketsClient.off('video-settings-changed');
    };
  }, [stage.id, newSocketsClient, dispatch]);

  useEffect(() => {
    if (!tabInactive) {
      const eventSource = new EventSource(
        `${config.api.url}/stages/${params.id}/status`
      );

      eventSource.onmessage = ({ data }) => {
        const parsedData = JSON.parse(data);
        dispatch(updateCurrent(parsedData));
      };

      return () => {
        eventSource.close();
      };
    }

    return () => {};
  }, [params.id, dispatch, tabInactive]);

  useEffect(() => {
    if (!tabInactive) {
      const eventSources: EventSource[] = [];
      if (obsIds.length > 0) {
        obsIds.forEach((obsId) => {
          const eventSource = new EventSource(
            `${config.api.url}/obs/channel-status?id=${obsId}`
          );

          eventSource.onmessage = ({ data }) => {
            const parsedData = JSON.parse(data);
            dispatch(
              setChannelStatus({ status: parsedData.status, id: obsId })
            );
          };

          eventSources.push(eventSource);
        });
      }

      return () => {
        eventSources.forEach((eventSource) => {
          eventSource.close();
        });

        obsIds.forEach((obsId) => {
          dispatch(setChannelStatus({ id: obsId, status: '' }));
        });
      };
    }

    return () => {};
  }, [obsIds, dispatch, tabInactive]);

  useEffect(() => {
    if (isMobile && !params.id) {
      dispatch(setIsVisibleOnMobile(true));
    }
  }, [isMobile, dispatch, params.id]);

  useEffect(() => {
    const promise = dispatch(getFestivalDates());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id && params.id.length && token) {
      const promises = [
        dispatch(getStage(params.id)),
        dispatch(getStageImages(params.id)),
        dispatch(getOBSLinks(params.id)),
        dispatch(getBackupVideos(params.id)),
        dispatch(getStageUsersWithAccess({ stageId: params.id })),
        dispatch(getSchedulerPlaylist(params.id)),
      ];

      return () => {
        promises.forEach((promise) => {
          promise.abort();
        });
      };
    }

    return () => {};
  }, [params.id, dispatch, token]);

  useEffect(() => {
    if (params.id && token && usersWithAccess.length > 0) {
      const promise = dispatch(
        getAcceptedInvitesForUsersWithAccess(
          usersWithAccess.map(({ userId }) => userId)
        )
      );

      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [params.id, dispatch, token, usersWithAccess]);

  useEffect(() => {
    const promise = dispatch(getRateAdminStageIds());

    return () => {
      promise.abort();
    };
  }, []);
};

export default useStageData;
