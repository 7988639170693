import type { AxiosInstance, CancelToken } from 'axios';
import qs from 'qs';
import type { PaginatedResponse } from '@/interfaces/PaginatedResponse';

export default class ApiService {
  // eslint-disable-next-line
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async create<T, S>(resource: string, data: T): Promise<S> {
    return this.request<S, T>('post', `/${resource}`, data);
  }

  async update<T, S>(
    resource: string,
    id: string,
    data: Partial<T>
  ): Promise<S> {
    return this.request<S, Partial<T>>('patch', `/${resource}/${id}`, data);
  }

  async remove<T>(
    resource: string,
    id: string,
    params: object = {}
  ): Promise<T> {
    return this.request<T>('delete', `/${resource}/${id}`, {}, params);
  }

  async removeSubresource<T>(
    resource: string,
    id: string,
    subresource: string,
    subresourceId: string,
    params: object = {}
  ): Promise<T> {
    return this.request<T>(
      'delete',
      `/${resource}/${id}/${subresource}/${subresourceId}`,
      {},
      params
    );
  }

  async get<T>(
    resource: string,
    id: string,
    params: object = {},
    cancelToken?: CancelToken
  ): Promise<T> {
    return this.request<T>(
      'get',
      `/${resource}/${id}`,
      {},
      params,
      false,
      cancelToken
    );
  }

  async getAll<T>(
    resource: string,
    params: object = {},
    noCache: boolean = false,
    cancelToken?: CancelToken
  ): Promise<T[]> {
    return this.request<T[]>(
      'get',
      `/${resource}`,
      {},
      params,
      noCache,
      cancelToken
    );
  }

  async getAllPaginated<T>(
    resource: string,
    params: object = {},
    noCache: boolean = false,
    cancelToken?: CancelToken
  ): Promise<PaginatedResponse<T>> {
    return this.request<PaginatedResponse<T>>(
      'get',
      `/${resource}`,
      {},
      params,
      noCache,
      cancelToken
    );
  }

  async getAllSubresources<T>(
    resource: string,
    id: string,
    subresource: string,
    params: object = {},
    cancelToken?: CancelToken
  ): Promise<T[]> {
    return this.request<T[]>(
      'get',
      `/${resource}/${id}/${subresource}`,
      {},
      params,
      false,
      cancelToken
    );
  }

  async getSubresource<T>(
    resource: string,
    id: string,
    subresource: string,
    params: object = {},
    cancelToken?: CancelToken
  ): Promise<T> {
    return this.request<T>(
      'get',
      `/${resource}/${id}/${subresource}`,
      {},
      params,
      false,
      cancelToken
    );
  }

  async request<T, S = {}>(
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    url: string,
    data: object | S = {},
    params: object = {},
    noCache: boolean = false,
    cancelToken?: CancelToken
  ): Promise<T> {
    const { data: responseData } = await this.axiosInstance.request<T>({
      method,
      url,
      data,
      params,
      cancelToken,
      paramsSerializer: (p: object) => qs.stringify(p),
      headers: {
        ...(noCache && {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        }),
      },
    });
    return responseData;
  }

  // eslint-disable-next-line class-methods-use-this
  set token(token: string) {
    // this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}
