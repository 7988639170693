import { UserRole } from '@myclipo/bm-admin-common';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '..';

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserRole = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user?.role ?? []
);
export const selectIsSuperAdmin = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user?.role.includes(UserRole.SuperAdmin)
);
export const selectIsObsAdmin = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user?.obsAdmin ?? false
);
export const selectToken = (state: RootState) => state.auth.token;
export const selectLoginError = (state: RootState) => state.auth.loginError;
export const selectRegistrationError = (state: RootState) =>
  state.auth.registrationError;
