import styled from 'styled-components';
import { Button } from '@mui/material';
import breakpoint from '../../breakpoint';

export default styled(Button).attrs({
  size: 'large',
  variant: 'contained',
  color: 'primary',
})`
  height: 50px;
  font-size: 15px;
  text-transform: none;

  ${breakpoint('lg')`
     height: 58px;
     font-size: 16px;
  `}

  ${breakpoint('xl')`
      height: 68px;
      font-size: 22px;
  `}
`;
