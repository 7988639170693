import type { OBSVideo } from '@myclipo/bm-admin-common';
import { VideoType } from '@myclipo/bm-admin-common';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): OBSVideo => {
  const data = doc.data();

  return {
    id: doc.id,
    region: data?.region,
    account: data?.account,
    channelId: data?.channelId,
    stageId: data?.stageId,
    urlObs: data?.urlObs,
    url: data?.url,
    title: `Clipo Streaming Service (${data?.region})`,
    greenScreen: data?.greenScreen,
    screenSize: data?.screenSize ?? 100,
    screenOffsetY: data?.screenOffsetY ?? 0,
    screenBrightness: data?.screenBrightness ?? 1,
    isLive: false,
    isStreaming: !!data?.isStreaming,
    audio: { muted: data?.audio?.muted ?? false },
    serviceType: data?.serviceType ?? 'medialive',
    stages: [],
    cloudfrontDistributionId: data?.cloudfrontDistributionId ?? null,
    type: VideoType.ObsVideo,
  };
};
