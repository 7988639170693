import type { FC } from 'react';
import React from 'react';
import Error from '@/components/LoginCard/Error';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import CardPageContainer from './styled/CardPageContainer';

const LoginError: FC = () => (
  <CardPageContainer>
    <Error />
  </CardPageContainer>
);

export default Sentry.withErrorBoundary(LoginError, {
  fallback: ErrorFallbackComponent,
});
