import styled from 'styled-components';
import ReactPlayer from 'react-player';
import breakpoint, { smartphoneBreakpoint } from '../../../../breakpoint';

export default styled(ReactPlayer)`
  min-width: 720px;
  min-height: 405px;

  ${breakpoint('lg')`
     min-width: 816px;
     min-height: 459px;
  `}

  ${breakpoint('xl')`
    min-width: 960px;
    min-height: 540px;
  `}

  ${smartphoneBreakpoint`
    min-width: 320px;
    max-width: 100%;
  `}
`;
