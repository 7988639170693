import { get } from 'lodash';
import { useEffect, useState } from 'react';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

const getHiddenPropertyName = (prefix: string) =>
  prefix ? `${prefix}Hidden` : 'hidden';

const getVisibilityEvent = (prefix: string) =>
  `${prefix || ''}visibilitychange`;

const getBrowserPrefix = () => {
  for (let i = 0; i < browserPrefixes.length; i += 1) {
    if (getHiddenPropertyName(browserPrefixes[i]) in document) {
      return browserPrefixes[i];
    }
  }

  return '';
};

const browserPrefix = getBrowserPrefix();

const useTabInactive = (): boolean => {
  const [inactive, setInactive] = useState(false);

  const handleVisibilityChange = () => {
    if (get(document, getHiddenPropertyName(browserPrefix))) {
      setInactive(true);
    } else {
      setInactive(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      getVisibilityEvent(browserPrefix),
      handleVisibilityChange,
      false
    );
    return () => {
      document.removeEventListener(
        getVisibilityEvent(browserPrefix),
        handleVisibilityChange
      );
    };
  }, []);

  return inactive;
};

export default useTabInactive;
