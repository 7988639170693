/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import type { UserWithAccess } from '@myclipo/bm-admin-common';
import { getDocs, orderBy, query } from 'firebase/firestore/lite';
import { usersWithAccessCollection } from '@/firebaseConfig';
import mapUserWithAccess from '@/helpers/mapUserWithAccess';
import type { RootState } from '.';

export interface UserWithAccessState {
  list: UserWithAccess[];
}

export const getUsersWithAccess = createAsyncThunk(
  'userWithAccessS/getUsersWithAccess',
  async () => {
    const { docs } = await getDocs(
      query(usersWithAccessCollection, orderBy('email'))
    );

    return docs.map(mapUserWithAccess);
  }
);

const initialState: UserWithAccessState = {
  list: [],
};

export const userWithAccessSlice = createSlice({
  name: 'userWithAccess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersWithAccess.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export const selectUserWithAccessList = (state: RootState) =>
  state.userWithAccess.list;

export default persistReducer(
  { key: 'userWithAccess', storage },
  userWithAccessSlice.reducer
);
