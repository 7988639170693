import type { FC } from 'react';
import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';
import Color from './styled/Color';
import PickerContainer from './styled/PickerContainer';
import Swatch from './styled/Swatch';

interface Props {
  color?: string;
  disabled?: boolean;
  onChange: (color: string) => void;
}

const ColorPicker: FC<Props> = ({ color, disabled = false, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <PickerContainer>
      <Swatch onClick={() => setOpen(!open)}>
        {!disabled && <Color color={color ?? '#fff'} />}
        {disabled && <Color color="#B3B3B3" />}
      </Swatch>
      {open && !disabled && (
        <div style={{ left: '-225px', position: 'absolute', zIndex: 5 }}>
          <TwitterPicker
            triangle="top-right"
            color={color ?? '#fff'}
            onChangeComplete={(c) => {
              onChange(c.hex);
              setOpen(false);
            }}
          />
        </div>
      )}
    </PickerContainer>
  );
};

export default ColorPicker;
