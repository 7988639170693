/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { get } from 'lodash';
import type { AuthState } from './interfaces';
import {
  login,
  loginWithGoogle,
  logout,
  updateAccount,
} from './asyncThunks/auth';

const initialState: AuthState = {
  user: null,
  token: null,
  registrationError: null,
  loginError: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setRegistrationError: (state, action) => {
      state.registrationError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.token = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const { token, apiUser } = action.payload;
      state.user = apiUser;
      state.token = token;
    });
    builder.addCase(login.rejected, (state) => {
      state.loginError = true;
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      const { token, apiUser } = action.payload;
      state.user = apiUser;
      state.token = token;
    });
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.registrationError = get(action, 'payload.message', null);
    });
  },
});

export const { setUser, setToken, setLoginError, setRegistrationError } =
  authSlice.actions;

export default persistReducer({ key: 'auth', storage }, authSlice.reducer);
