import type { RootState } from '..';

export const selectUserStages = (state: RootState) => state.user.stages;
export const selectUserCurrentlyPlayed = (state: RootState) =>
  state.user.currentlyPlayed;
export const selectUserCurrentlyPlayedLoading = (state: RootState) =>
  state.user.currentlyPlayedLoading;
export const selectUserStagesLoading = (state: RootState) =>
  state.user.stagesLoading;
export const selectUser = (state: RootState) => state.user.current;
