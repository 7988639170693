import styled from 'styled-components';
import { smartphoneBreakpoint } from '@/breakpoint';

export default styled.nav`
  overflow-y: auto;
  scrollbar-color: #ffffff42 #1e1f22;

  ::-webkit-scrollbar {
    background-color: #1e1f22;
    width: 0.6em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffffff42;
    border-radius: 1.25em;
  }

  ${smartphoneBreakpoint`
    max-width: 100vw;
  `}
`;
