import {
  FormControlLabel,
  Grid,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import ColorPicker from './ColorPicker';
import PickerContainer from './styled/ControlContainer';

interface Props {
  name: string;
  lightColor?: string;
  lightEnabled?: boolean;
  rateAutomaticCalculation?: boolean;
  bpm?: number;
  flames?: boolean;
  on?: boolean;
  onLightColorChange?: (color: string) => void;
  onLightEnabledChange?: (value: boolean) => void;
  onRateAutomaticCalculationChange?: (value: boolean) => void;
  onBPMChange?: (bpm: number) => void;
  onFlamesChange?: (value: boolean) => void;
  onChange?: (value: boolean) => void;
}

const Control: FC<Props> = ({
  name,
  bpm,
  lightColor,
  lightEnabled,
  rateAutomaticCalculation,
  flames,
  on,
  onLightColorChange,
  onLightEnabledChange,
  onRateAutomaticCalculationChange,
  onBPMChange,
  onFlamesChange,
  onChange,
}) => (
  <PickerContainer>
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={2}>
        <div style={{ padding: 8 }}>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
        </div>
      </Grid>
      {onLightEnabledChange && (
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={lightEnabled}
                onChange={(event, checked) => onLightEnabledChange(checked)}
              />
            }
            label={lightEnabled ? 'On' : 'Off'}
          />
        </Grid>
      )}
      {onLightColorChange && (
        <Grid item xs={1}>
          <ColorPicker
            color={lightColor}
            onChange={onLightColorChange}
            disabled={!lightEnabled}
          />
        </Grid>
      )}
      {onRateAutomaticCalculationChange && (
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                disabled={!lightEnabled}
                checked={rateAutomaticCalculation}
                onChange={(event, checked) =>
                  onRateAutomaticCalculationChange(checked)
                }
              />
            }
            label={rateAutomaticCalculation ? 'On' : 'Off'}
          />
        </Grid>
      )}
      {onBPMChange && (
        <Grid item xs={3}>
          <div style={{ paddingRight: 32 }}>
            <Slider
              valueLabelDisplay="auto"
              value={bpm}
              step={10}
              marks
              min={0}
              max={110}
              disabled={!lightEnabled}
              onChangeCommitted={(event, value) => {
                onBPMChange(Array.isArray(value) ? value[0] : value);
              }}
            />
          </div>
        </Grid>
      )}
      {onFlamesChange && (
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={flames}
                onChange={(event, checked) => onFlamesChange(checked)}
              />
            }
            label={flames ? 'On' : 'Off'}
          />
        </Grid>
      )}
      {onChange && (
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={on}
                onChange={(event, checked) => onChange(checked)}
              />
            }
            label={on ? 'On' : 'Off'}
          />
        </Grid>
      )}
    </Grid>
  </PickerContainer>
);

export default Control;
