import type { FC } from 'react';
import React from 'react';
import { Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import Head from '@/components/Camp/Head';
import UsersWithAccess from '@/components/Camp/UsersWithAccess';
import HeadSmall from '@/components/Camp/HeadSmall';
import ScheduledOnCamp from '@/components/Camp/ScheduledOnCamp';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import useCampData from './useCampData';
import PanelContainer from '../styled/PanelContainer';

const Camp: FC = () => {
  const params = useParams<{ id?: string }>();
  useCampData();

  return params.id ? (
    <PanelContainer>
      <Head />
      <Divider />
      <UsersWithAccess />
      <Divider />
      <HeadSmall />
      <ScheduledOnCamp />
    </PanelContainer>
  ) : (
    <div />
  );
};

export default Sentry.withErrorBoundary(Camp, {
  fallback: ErrorFallbackComponent,
});
