import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import {
  WebsocketEvents,
  type Effect,
  type Stage,
} from '@myclipo/bm-admin-common';
import { selectEffects } from '@/store/effect';
import { setLaser as setLaserFunc } from '@/store/stage';
import { selectStage } from '@/store/selectors/stage';
import { setSuccessMessage as setSuccessMessageFunc } from '@/store/flashMessage';
import useNewSockets from '@/hooks/useNewSockets';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import config from '@/config';
import Button from '../styled/Button';
import EffectButtonsContainer from './styled/EffectButtonsContainer';

const Effects: FC = () => {
  const dispatch = useAppDispatch();
  const effects = useAppSelector(selectEffects);
  const stage = useAppSelector(selectStage);
  const newSocketsClient = useNewSockets();

  useEffect(() => {
    newSocketsClient.on(
      'effects',
      ({ stage: effectStage, effect }: { stage: Stage; effect: Effect }) => {
        if (effectStage.id === stage.id) {
          dispatch(setSuccessMessageFunc(effect.name));

          if (effect.id === config.effects.laserOn) {
            dispatch(setLaserFunc(true));
          }

          if (effect.id === config.effects.laserOff) {
            dispatch(setLaserFunc(false));
          }
        }
      }
    );

    newSocketsClient.on(
      WebsocketEvents.Outgoing.StagesEffectsState,
      (data: any[]) => {
        data.forEach((d) => {
          if (d.stageId === stage.id) {
            if (d.ids.includes(config.effects.laserOn)) {
              dispatch(setLaserFunc(true));
            }

            if (d.ids.includes(config.effects.laserOff)) {
              dispatch(setLaserFunc(false));
            }
          }
        });
      }
    );

    return () => {
      newSocketsClient.off(WebsocketEvents.Outgoing.Effects);
      newSocketsClient.off(WebsocketEvents.Outgoing.StagesEffectsState);
    };
  }, [stage.id, newSocketsClient, dispatch]);

  const send = useCallback(
    (effectId: string) => {
      newSocketsClient.emit(WebsocketEvents.Incomming.AddEffect, {
        stageId: stage.id.trim(),
        effectId,
      });
    },
    [stage.id, newSocketsClient]
  );

  return (
    <EffectButtonsContainer>
      {effects.map((effect) => (
        <Button key={effect.id} onClick={() => send(effect.id)}>
          {effect.name}
        </Button>
      ))}
    </EffectButtonsContainer>
  );
};

export default Effects;
