import type { FC } from 'react';
import React, {
  useState,
  useEffect,
  createRef,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useMatch,
  Outlet,
} from 'react-router-dom';
import { Tabs, Tab, Divider, Typography } from '@mui/material';
import { find, includes, reverse } from 'lodash';
import { UserRole, StageEvent } from '@myclipo/bm-admin-common';
import Drawer from '@/components/Drawer';
import Head from '@/components/AddLink/Head';
import { selectUser } from '@/store/selectors/auth';
import {
  selectStage,
  selectStageLoading,
  selectStageNotFound,
  selectUsersWithAccess,
} from '@/store/selectors/stage';
import BurningManOptions from '@/components/AddLink/BurningManOptions';
import { selectDrawerStages } from '@/store/selectors/drawer';
import NotFound from '@/components/NotFound';
import { useAppSelector } from '@/store/hooks';
import NoAccessContainer from '../styled/NoAccessContainer';
import useAddLinkData from './useAddLinkData';
import PanelContainer from '../styled/PanelContainer';

const map: Array<any> = [
  [0, ''],
  [1, 'users-with-access'],
  [2, 'effects'],
  [3, 'settings'],
];

const tabToPathMap = new Map(map);
const pathToTabMap = new Map(map.map((value) => reverse(value)));

const AddLink: FC = () => {
  const user = useAppSelector(selectUser);
  const stage = useAppSelector(selectStage);
  const notFound = useAppSelector(selectStageNotFound);
  const stages = useAppSelector(selectDrawerStages);
  const usersWithAccess = useAppSelector(selectUsersWithAccess);
  const stageLoading = useAppSelector(selectStageLoading);

  const matchWithoutId = useMatch('/add-link');
  const match = useMatch('/add-link/:id/*');
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const enterURLRef = createRef<HTMLButtonElement>();
  const [tab, setTab] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const hasAccess = useMemo(
    () => !!find(usersWithAccess, { userId: user?.uid }),
    [usersWithAccess, user]
  );

  useAddLinkData();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [params.id, containerRef.current]);

  useEffect(() => {
    if (matchWithoutId !== null && stages.length > 0) {
      navigate(`/add-link/${stages[0].id}`);
    }
  }, [stages, navigate, matchWithoutId]);

  useEffect(() => {
    if (match) {
      const path = location.pathname.replace(match.pathnameBase, '');
      if (pathToTabMap.has(path)) {
        const tabIndex = pathToTabMap.get(path);
        setTab(tabIndex as number);
      }
    }
  }, [location.pathname, match]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);

      if (tabToPathMap.has(newValue)) {
        const path = tabToPathMap.get(newValue) as string;
        navigate(path);
      }
    },
    [navigate]
  );

  const handleDrawerAddLinkClick = () => {
    setTab(0);
    if (!enterURLRef.current) {
      return;
    }
    enterURLRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  if (notFound) {
    return (
      <>
        <Drawer
          itemPath="/add-link"
          onAddLinkClick={handleDrawerAddLinkClick}
          showAddLink
        />
        <PanelContainer ref={containerRef}>
          <NotFound />
        </PanelContainer>
      </>
    );
  }

  return (
    <>
      <Drawer
        itemPath="/add-link"
        onAddLinkClick={handleDrawerAddLinkClick}
        showAddLink
      />
      {params.id && !stageLoading && !hasAccess && (
        <PanelContainer>
          <NoAccessContainer>
            <Typography variant="h5" gutterBottom>
              User doesn&apos;t have access.
            </Typography>
          </NoAccessContainer>
        </PanelContainer>
      )}
      {params.id && hasAccess && (
        <PanelContainer ref={containerRef}>
          <Head />
          {!stage?.isBurningMan && (
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab value={0} label="Streaming now" />
              <Tab value={1} label="Users with access" />
              {includes(user?.role, UserRole.DJ) && (
                <Tab value={2} label="Effects" />
              )}
              {stage?.event === StageEvent.Salvation &&
                stage.id === import.meta.env.REACT_APP_SALVATION_MAIN_STAGE && (
                  <Tab value={3} label="Settings" />
                )}
            </Tabs>
          )}
          {stage?.isBurningMan && (
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab label="Streaming now" />
            </Tabs>
          )}
          {stage?.isBurningMan && (
            <>
              <Divider />
              <BurningManOptions />
            </>
          )}
          <Outlet />
        </PanelContainer>
      )}
    </>
  );
};

export default AddLink;
