import styled from 'styled-components';
import { Container } from '@mui/material';
import { smartphoneBreakpoint } from '@/breakpoint';

export default styled(Container)`
  &.MuiContainer-root {
    padding-top: 43px;
    padding-bottom: 56px;
    display: flex;
    align-items: center;

    ${smartphoneBreakpoint`
    padding-top: 16px;
    padding-bottom: 16px;
  `}
  }
`;
