import type { FC } from 'react';
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Typography, IconButton, CircularProgress, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { debounce, includes, without } from 'lodash';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import type { Stage, UserWithAccess } from '@myclipo/bm-admin-common';
import InfiniteScroll from 'react-infinite-scroller';
import { resetStages as resetStagesFunc } from '@/store/dialog';
import {
  selectDialogStagesLoading,
  selectDialogStages,
  selectDialogHasMoreStages,
} from '@/store/selectors/dialog';
import {
  getDialogStages as getDialogStagesFunc,
  getMoreDialogStages as getMoreDialogStagesFunc,
} from '@/store/asyncThunks/dialog';
import { addStage as addStageFunc } from '@/store/user';
import { addUserWithAccess as addUserWithAccessFunc } from '@/store/asyncThunks/stage';
import { getUsersWithAccess as getUsersWithAccessFunc } from '@/store/userWithAccess';
import useIsHD from '@/hooks/useIsHD';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import StyledDialog from '../styled/Dialog';
import DialogTitle from '../styled/DialogTitle';
import IconContainer from '../styled/DialogIconContainer';
import SearchListDialogContent from '../styled/SearchListDialogContent';
import SearchField from '../styled/SearchField';
import FullWidthButton from '../styled/FullWidthButton';
import ProgressContainer from '../styled/ProgressContainer';
import ListItemText from '../styled/ListItemText';
import ListItem from '../styled/ListItem';
import Nav from '../styled/Nav';

interface DialogProps {
  onClose: (event: any) => void;
  open: boolean;
}

const Loader = () => (
  <ProgressContainer>
    <CircularProgress />
  </ProgressContainer>
);

const Dialog: FC<DialogProps> = ({ onClose, open }) => {
  const dispatch = useAppDispatch();

  const stagesLoading = useAppSelector(selectDialogStagesLoading);
  const stages = useAppSelector(selectDialogStages);
  const hasMoreStages = useAppSelector(selectDialogHasMoreStages);

  const listRef = useRef<HTMLElement>();
  const params = useParams<{ id: string }>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeItems, setActiveItems] = useState<string[]>([]);
  const isHD = useIsHD();

  const getStagesDebounced = useMemo(
    () =>
      debounce(
        (arg: Record<string, unknown>) => dispatch(getDialogStagesFunc(arg)),
        500
      ),
    [dispatch]
  );
  const getMoreStagesDebounced = useMemo(
    () =>
      debounce(
        (arg: Record<string, unknown>) =>
          dispatch(getMoreDialogStagesFunc(arg)),
        500
      ),
    [dispatch]
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    getStagesDebounced({ name: searchValue });
  }, [searchValue, getStagesDebounced]);

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const stagesMap = useMemo(
    () => new Map(stages.map((stage) => [stage.id, stage])),
    [stages]
  );

  const handleAddDisplayToUser = useCallback(async () => {
    Promise.all(
      activeItems.map(async (activeItem): Promise<UserWithAccess> => {
        const userWithAccess = {
          userId: params.id!,
        };
        return unwrapResult(
          await dispatch(
            addUserWithAccessFunc({ stageId: activeItem, userWithAccess })
          )
        );
      })
    )
      .then((added: UserWithAccess[]) => {
        added.forEach(({ stageId }: UserWithAccess) => {
          if (stagesMap.has(stageId)) {
            const stage = { ...stagesMap.get(stageId) } as Stage;
            dispatch(addStageFunc(stage));
          }
        });
        dispatch(getUsersWithAccessFunc());
      })
      .catch(() => {});

    onClose({});
    setSearchValue('');
    setActiveItems([]);
  }, [activeItems, params.id, onClose, stagesMap, dispatch]);

  const handleClose = useCallback(() => {
    onClose({});
    dispatch(resetStagesFunc());

    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [onClose, dispatch]);

  const handleListItemClick = (id: string) => {
    if (includes(activeItems, id)) {
      setActiveItems(without(activeItems, id));
    } else {
      setActiveItems([...activeItems, id]);
    }
  };

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <DialogTitle>
        <IconContainer>
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </IconContainer>
        <Typography align="center" variant="h5">
          Add screen to user
        </Typography>
      </DialogTitle>
      <SearchListDialogContent>
        <SearchField
          placeholder="Search screen"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
        {stagesLoading && <Loader />}
        {!stagesLoading && (
          <List
            component={Nav}
            style={{ maxHeight: isHD ? 230 : 160 }}
            ref={listRef}
          >
            <InfiniteScroll
              loadMore={() =>
                getMoreStagesDebounced({
                  name: searchValue,
                })
              }
              hasMore={hasMoreStages}
              loader={<Loader key="loader" />}
              useWindow={false}
              getScrollParent={() => listRef.current ?? null}
            >
              {stages.map((stage: Stage) => (
                <ListItem
                  key={stage.id}
                  button
                  selected={includes(activeItems, stage.id)}
                  onClick={() => handleListItemClick(stage.id)}
                >
                  <ListItemText primary={stage.name} />
                </ListItem>
              ))}
            </InfiniteScroll>
          </List>
        )}
        <FullWidthButton onClick={handleAddDisplayToUser}>
          Add screen to user
        </FullWidthButton>
      </SearchListDialogContent>
    </StyledDialog>
  );
};

export default Dialog;
