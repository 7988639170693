/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  addStageImage,
  deleteStageImage,
  getStageImages,
  updateStageImage,
} from './asyncThunks/image';
import type { ImageState } from './interfaces/image';

const initialState: ImageState = {
  list: [],
  loading: false,
};

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStageImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStageImages.rejected, (state) => {
        state.loading = false;
        state.list = [];
      })
      .addCase(getStageImages.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = payload;
      });

    builder.addCase(addStageImage.fulfilled, (state, { payload }) => {
      state.list.push(payload);
    });
    builder.addCase(updateStageImage.fulfilled, (state, { payload }) => {
      state.list = state.list.map((image) =>
        image.id === payload.id ? Object.assign(image, payload) : image
      );
    });
    builder.addCase(deleteStageImage.fulfilled, (state, { payload }) => {
      state.list = state.list.filter((image) => image.id !== payload);
    });
  },
});

export default imageSlice.reducer;
