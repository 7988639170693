import styled from 'styled-components';
import withTheme from '@mui/styles/withTheme';

export default withTheme(styled.span`
  color: ${(props) => props.theme.palette.primary.main};
  text-transform: uppercase;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 600;
`);
