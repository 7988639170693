import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { UserRole } from '@myclipo/bm-admin-common';
import { getCurrentlyPlayed } from '@/store/asyncThunks/stage';
import { selectToken, selectUserRole } from '@/store/selectors/auth';
import { selectStage } from '@/store/selectors/stage';
import { getEffects } from '@/store/effect';
import { useAppDispatch } from '@/store';

const emptyPromise = {
  abort() {},
};

const useEffectsData = () => {
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const userRole = useSelector(selectUserRole);
  const stage = useSelector(selectStage);

  const hasRole = useMemo(
    () =>
      userRole.includes(UserRole.DJ) || userRole.includes(UserRole.SuperAdmin),
    [userRole]
  );

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && hasRole && token) {
      promise = dispatch(getCurrentlyPlayed(params.id));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, hasRole, dispatch, token]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && hasRole && token && stage) {
      promise = dispatch(getEffects({ stageId: stage.id, event: stage.event }));
    }

    return () => {
      promise.abort();
    };
  }, [params.id, hasRole, dispatch, token, stage.id, stage.event]);
};

export default useEffectsData;
