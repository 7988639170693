import styled from 'styled-components';
import Button from '../../styled/Button';
import FullWidthButton from '../../styled/FullWidthButton';

export default styled.div`
  padding-bottom: 116px;

  ${Button} {
    margin-left: 32px;
    margin-top: 32px;
  }

  ${FullWidthButton} {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;

    width: calc(100% - 16px);
  }
`;
