import axios from 'axios';
import type { StreamImage } from '@myclipo/bm-admin-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';

const apiService: ApiService = apiServiceFactory();

export const getStageImages = createAsyncThunk(
  'image/getStageImages',
  async (stageId: string, { rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    try {
      return await apiService.getAllSubresources<StreamImage>(
        'stages',
        stageId,
        'stream-images',
        {},
        source.token
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const addStageImage = createAsyncThunk(
  'image/addStageImage',
  async (
    {
      stageId,
      title,
      description,
      file,
    }: { stageId: string; title: string; description: string; file: File },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('file', file);

      const result = await apiService.create<FormData, StreamImage>(
        `stages/${stageId}/stream-images`,
        formData
      );

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateStageImage = createAsyncThunk(
  'image/updateStageImage',
  async (
    {
      id,
      ...rest
    }: {
      id: string;
      greenScreen?: boolean;
      screenSize?: number;
      screenOffsetY?: number;
      screenBrightness?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      await apiService.update('stream-images', id, rest);

      return { id, ...rest };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const deleteStageImage = createAsyncThunk(
  'image/deleteStageImage',
  async (id: string, { rejectWithValue }) => {
    try {
      await apiService.remove('stream-images', id);

      return id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);
