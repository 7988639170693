import type { FC } from 'react';
import React from 'react';
import { CardContent, Typography, TextField, Link } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { selectLoginError } from '@/store/selectors/auth';
import {
  login as loginFunc,
  loginWithGoogle as loginWithGoogleFunc,
} from '@/store/asyncThunks/auth';
import Card from '@/components/styled/Card';
import LoginButton from '@/components/styled/FullWidthButton';
import CardActions from '@/components/styled/CardActions';
import CardForm from '@/components/styled/CardForm.';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import SignInWithGoogleButton from './SignInWithGoogleButton';
import ForgotPasswordTypography from './styled/ForgotPasswordTypography';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const LoginCard: FC = () => {
  const dispatch = useAppDispatch();

  const loginError = useAppSelector(selectLoginError);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: ({ email, password }) => {
      dispatch(loginFunc({ email, password }));
    },
  });

  if (loginError) {
    return <Navigate to="/login/error" />;
  }

  return (
    <CardForm onSubmit={formik.handleSubmit}>
      <Card raised>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center">
            Log In
          </Typography>
          <TextField
            required
            name="email"
            label="Enter your email"
            fullWidth
            margin="normal"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
          />
          <TextField
            required
            name="password"
            label="Password"
            fullWidth
            margin="normal"
            type="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
          />
          <ForgotPasswordTypography>
            <Link color="primary" href="/account-recovery">
              Forgot password?
            </Link>
          </ForgotPasswordTypography>
        </CardContent>
        <CardActions>
          <LoginButton disabled={formik.isSubmitting} type="submit">
            Log In
          </LoginButton>
          <SignInWithGoogleButton
            onClick={() => dispatch(loginWithGoogleFunc())}
          />
        </CardActions>
      </Card>
    </CardForm>
  );
};

export default LoginCard;
