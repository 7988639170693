import { useState, useLayoutEffect, useEffect } from 'react';

export default () => {
  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    setClientWidth(document.documentElement.clientWidth);
  }, []);

  useLayoutEffect(() => {
    const resize = () => {
      setClientWidth(document.documentElement.clientWidth);
    };
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  return clientWidth;
};
