import styled from 'styled-components';
import { Container } from '@mui/material';

export default styled(Container)`
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 64px;
  background-image: url('/assets/bm_background_blur.webp');
  background-repeat: no-repeat;
  background-size: cover;
`;
