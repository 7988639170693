import type { FC } from 'react';
import React, { memo } from 'react';
import type { StreamImage } from '@myclipo/bm-admin-common';
import CurrentImageContainer from '@/components/AddLink/Image/styled/CurrentImageContainer';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CurrentImage: FC<{ image: StreamImage }> = ({ image }) => (
  <CurrentImageContainer>
    <LazyLoadImage
      loading="lazy"
      alt={image.title}
      src={image.url}
      style={{ maxWidth: '100%' }}
      height="auto"
      placeholderSrc="https://placehold.co/600x400"
    />
  </CurrentImageContainer>
);

export default memo(CurrentImage);
