/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Drawer } from '@mui/material';
import breakpoint, {
  tabletBreakpoint,
  smartphoneBreakpoint,
} from '@/breakpoint';

interface DrawerProps {
  isVisibleOnMobile?: boolean;
}

export default styled(({ isVisibleOnMobile, ...props }) => (
  <Drawer {...props} />
))<DrawerProps>`
  ${({ isVisibleOnMobile }) =>
    !isVisibleOnMobile &&
    tabletBreakpoint`
    display: none;
  `}

  ${({ isVisibleOnMobile }) =>
    !isVisibleOnMobile &&
    smartphoneBreakpoint`
    display: none;
  `}

  .MuiPaper-root {
    background: #1e1f22 0% 0% no-repeat padding-box;
    min-width: 260px;
    max-width: 260px;
    margin-top: 64px;
    height: calc(100% - 64px);

    ${breakpoint('lg')`
       min-width: 314px;
       max-width: 314px;
    `}

    ${tabletBreakpoint`
      min-width: 100%;
      max-width: 100%;
    `}

    ${smartphoneBreakpoint`
      min-width: 100%;
      max-width: 100%;
    `}
  }
`;
