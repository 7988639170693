/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import type {
  Stage,
  UpdateOBSVideo,
  UpdateStage,
} from '@myclipo/bm-admin-common';
import { concat, find, merge } from 'lodash';
import {
  addOBSLink,
  getDrawerCamps,
  getDrawerObsLinks,
  getDrawerStages,
  getDrawerUsers,
  getMoreDrawerCamps,
  getMoreDrawerStages,
  removeOBSLink,
} from './asyncThunks/drawer';
import type { DrawerState } from './interfaces';

const initialState: DrawerState = {
  usersSearchValue: '',
  stagesSearchValue: '',
  users: [],
  stages: [],
  camps: [],
  obsLinks: [],
  usersLoading: false,
  stagesLoading: false,
  campsLoading: false,
  isVisibleOnMobile: true,
  nextStage: null,
  previousStage: null,
  nextCamp: null,
  previousCamp: null,
  forceRefreshStages: false,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setUsersSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.usersSearchValue = payload;
    },
    setStagesSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.stagesSearchValue = payload;
    },
    setIsVisibleOnMobile: (state, action: PayloadAction<boolean>) => {
      state.isVisibleOnMobile = action.payload;
    },
    resetDrawerStages: (state) => {
      state.stages = [];
    },
    resetDrawerCamps: (state) => {
      state.camps = [];
    },
    updateDrawerStage: (
      state,
      action: PayloadAction<UpdateStage & { id: string }>
    ) => {
      const { payload } = action;
      state.stages = state.stages.map((stage) =>
        stage.id === payload.id ? merge(stage, payload) : stage
      );
    },
    updateDrawerOBS: (
      state,
      action: PayloadAction<UpdateOBSVideo & { id: string }>
    ) => {
      const { payload } = action;
      state.obsLinks = state.obsLinks.map((obs) =>
        obs.id === payload.id ? merge(obs, payload) : obs
      );
    },
    setForceRefreshStages: (state, { payload }: PayloadAction<boolean>) => {
      state.forceRefreshStages = payload;
    },
    addDrawerOBSStage: (
      state,
      { payload: { id, stage } }: PayloadAction<{ id: string; stage: Stage }>
    ) => {
      const obsLink = find(state.obsLinks, { id });
      if (obsLink) {
        obsLink.stages?.push({ ...stage, streamingNow: false });
      }
    },
    removeDrawerOBSStage: (
      state,
      {
        payload: { id, stageId },
      }: PayloadAction<{ id: string; stageId: string }>
    ) => {
      const obsLink = find(state.obsLinks, { id });
      if (obsLink) {
        obsLink.stages = obsLink.stages?.filter(
          (stage) => stage.id !== stageId
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeOBSLink.fulfilled, (state, action) => {
      state.obsLinks = state.obsLinks.filter(
        (obs) => obs.id !== action.payload
      );
    });
    builder.addCase(addOBSLink.fulfilled, (state, action) => {
      state.obsLinks.push(action.payload);
    });

    builder
      .addCase(getDrawerCamps.pending, (state) => {
        if (state.camps.length === 0) {
          state.campsLoading = true;
        }
      })
      .addCase(getDrawerCamps.rejected, (state) => {
        state.campsLoading = false;
      })
      .addCase(getDrawerCamps.fulfilled, (state, action) => {
        const {
          payload: { items, next, previous },
        } = action;
        state.camps = items;
        state.nextCamp = next;
        state.previousCamp = previous;
        state.campsLoading = false;
      });

    builder
      .addCase(getDrawerUsers.pending, (state) => {
        if (state.users.length === 0) {
          state.usersLoading = true;
        }
      })
      .addCase(getDrawerUsers.rejected, (state) => {
        state.usersLoading = false;
      })
      .addCase(getDrawerUsers.fulfilled, (state, action) => {
        const users = action.payload;
        state.users = users;
        state.usersLoading = false;
      });

    builder
      .addCase(getDrawerStages.pending, (state) => {
        if (state.stages.length === 0) {
          state.stagesLoading = true;
        }
      })
      .addCase(getDrawerStages.rejected, (state) => {
        state.stagesLoading = false;
      })
      .addCase(getDrawerStages.fulfilled, (state, action) => {
        const {
          payload: { items, next, previous },
        } = action;
        state.stages = items;
        state.nextStage = next;
        state.previousStage = previous;
        state.stagesLoading = false;
        state.forceRefreshStages = false;
      });

    builder.addCase(getMoreDrawerStages.fulfilled, (state, action) => {
      const {
        payload: { items, next, previous },
      } = action;
      state.stages = concat(state.stages, items);
      state.nextStage = next;
      state.previousStage = previous;
      state.stagesLoading = false;
    });

    builder.addCase(getMoreDrawerCamps.fulfilled, (state, action) => {
      const {
        payload: { items, next, previous },
      } = action;
      state.camps = concat(state.camps, items);
      state.nextCamp = next;
      state.previousCamp = previous;
      state.campsLoading = false;
    });
    builder.addCase(getDrawerObsLinks.fulfilled, (state, action) => {
      state.obsLinks = action.payload;
    });
  },
});

export const {
  setUsersSearchValue,
  setStagesSearchValue,
  setIsVisibleOnMobile,
  resetDrawerStages,
  resetDrawerCamps,
  updateDrawerStage,
  updateDrawerOBS,
  setForceRefreshStages,
  addDrawerOBSStage,
  removeDrawerOBSStage,
} = drawerSlice.actions;

export default persistReducer({ key: 'drawer', storage }, drawerSlice.reducer);
