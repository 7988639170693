import styled from 'styled-components';
import { Dialog } from '@mui/material';
import { smartphoneBreakpoint } from '@/breakpoint';

export default styled(Dialog)`
  .MuiPaper-root {
    background: #1e1f22 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 30px #00000066;
    border-radius: 3px;

    ${smartphoneBreakpoint`
      height: 100vh;
      margin: 0;
      width: 100%;
      max-height: unset;
    `}
  }
`;
