import styled from 'styled-components';
import { TableRow } from '@mui/material';
import { smartphoneBreakpoint } from '@/breakpoint';

export default styled(TableRow)`
  td:first-child,
  th:first-child {
    padding-left: 32px;
  }

  td:last-child,
  th:last-child {
    padding-right: 56px;

    ${smartphoneBreakpoint`
      padding-right: 24px;
    `}
  }
`;
