import styled from 'styled-components';
import { DialogTitle } from '@mui/material';
import breakpoint, { smartphoneBreakpoint } from '@/breakpoint';

export default styled(DialogTitle)`
  height: 90px;

  ${breakpoint('xl')`
    height: 121px;
  `}

  ${smartphoneBreakpoint`
    padding-top: 64px;
  `}
`;
