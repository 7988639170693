/* eslint-disable no-param-reassign */
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import { auth } from '@/firebaseConfig';
import appConfig from '@/config';
import ApiService from './ApiService';

let apiService: ApiService;

export default (): ApiService => {
  if (!apiService) {
    const axiosInstance: AxiosInstance = axios.create({
      baseURL: appConfig.api.url,
      timeout: 60000,
      headers: {
        Accept: 'application/json',
      },
    });

    axiosInstance.interceptors.request.use((axiosConfig) => {
      const token = window.localStorage.getItem('token');
      axiosConfig.headers!.Authorization = token ? `Bearer ${token}` : '';

      return axiosConfig;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401 && auth.currentUser) {
          const token = await auth.currentUser.getIdToken();
          window.localStorage.setItem('token', token);
          error.config.headers.Authorization = `Bearer ${token}`;
          return axios(error.config);
        }

        return Promise.reject(error);
      }
    );

    apiService = new ApiService(axiosInstance);
  }
  return apiService;
};
