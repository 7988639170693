import styled from 'styled-components';
import breakpoint from '@/breakpoint';

export default styled.div`
  padding-left: 8px;

  h6 {
    padding-top: 8px;
    padding-left: 8px;
  }

  ${breakpoint('lg')`
    padding-left: 16px;
  `}

  ${breakpoint('xl')`
    padding-left: 24px;
  `}
`;
