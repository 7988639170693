import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { selectToken } from '@/store/selectors/auth';
import config from '@/config';

export default () => {
  const token = useSelector(selectToken);

  const newSocketsClient = useMemo(
    () =>
      io(config.websocket.newUrl as string, {
        reconnectionAttempts: 3,
        autoConnect: false,
        secure: true,
        transports: ['websocket'],
        query: { token },
      }),
    [token]
  );

  useEffect(() => {
    newSocketsClient.open();

    return () => {
      newSocketsClient.disconnect();
    };
  }, [newSocketsClient]);

  return newSocketsClient;
};
