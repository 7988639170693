import styled from 'styled-components';
import Button from '../../styled/Button';

export default styled.div`
  padding-bottom: 32px;

  ${Button} {
    margin-left: 32px;
    margin-top: 32px;
  }
`;
