import styled from 'styled-components';
import logo from '../logo.svg';
import breakpoint from '../breakpoint';

export default styled.img.attrs({
  src: logo,
  alt: '',
})`
  height: 24px;
  left: 0;
  right: 0;
  margin: auto;

  ${breakpoint('lg')`
    height: 27px;
  `}

  ${breakpoint('xl')`
    height: 32px;
  `}
`;
