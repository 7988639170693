import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { memo } from 'react';
import PickerContainer from './styled/PickerContainer';

const Header: FC = () => (
  <PickerContainer>
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={2} />
      <Grid item xs={1}>
        <div style={{ paddingLeft: 8 }}>
          <Typography variant="h6">Lights</Typography>
        </div>
      </Grid>
      <Grid item xs={1}>
        <div style={{ paddingLeft: 8 }}>
          <Typography variant="h6">Base color</Typography>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div style={{ paddingLeft: 8 }}>
          <Typography variant="h6">Rate automatic calculation</Typography>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div style={{ paddingLeft: 8 }}>
          <Typography variant="h6">BPM</Typography>
        </div>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Flames</Typography>
      </Grid>
    </Grid>
  </PickerContainer>
);

export default memo(Header);
