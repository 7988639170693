import type { Effect, Stage } from '@myclipo/bm-admin-common';
import { useEffect, useCallback, useReducer } from 'react';
import config from '@/config';
import useNewSockets from '@/hooks/useNewSockets';

const getFlamesEffectId = (on: boolean) =>
  on ? config.salvation.flamesOnEffect : config.salvation.flamesOffEffect;

const initialState = {
  [config.salvation.leftTowerId]: { flames: false },
  [config.salvation.rightTowerId]: { flames: false },
  [config.salvation.mainStageId]: { flames: false },
};

type Action = { type: 'on' | 'off'; stageId: string };

const reducer = (
  state: typeof initialState,
  { type, stageId }: Action
): typeof initialState => {
  switch (type) {
    case 'on':
      return { ...state, [stageId]: { flames: true } };
    case 'off':
      return { ...state, [stageId]: { flames: false } };
    default:
      throw new Error();
  }
};

export default () => {
  const newSocketsClient = useNewSockets();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    newSocketsClient.on(
      'effects',
      ({ stage: effectStage, effect }: { stage: Stage; effect: Effect }) => {
        if (config.salvation.towerIds.includes(effectStage.id)) {
          if (effect.id === config.salvation.flamesOnEffect) {
            dispatch({ type: 'on', stageId: effectStage.id });
          } else if (effect.id === config.salvation.flamesOffEffect) {
            dispatch({ type: 'off', stageId: effectStage.id });
          }
        }
      }
    );

    newSocketsClient.on('stages-effects-state', (data: any[]) => {
      data.forEach((d) => {
        if (config.salvation.towerIds.includes(d.stageId)) {
          if (d.ids.includes(config.salvation.flamesOnEffect)) {
            dispatch({ type: 'on', stageId: d.stageId });
          } else if (d.ids.includes(config.salvation.flamesOffEffect)) {
            dispatch({ type: 'off', stageId: d.stageId });
          }
        }
      });
    });

    return () => {
      newSocketsClient.off('effects');
      newSocketsClient.off('stages-effects-state');
    };
  }, [newSocketsClient]);

  const handleFlamesChange = useCallback(
    (stageId: string) => (on: boolean) => {
      const effectId = getFlamesEffectId(on);

      newSocketsClient.emit('add-effect', {
        stageId,
        effectId,
      });
    },
    [newSocketsClient]
  );

  return { state, handleFlamesChange };
};
