import styled from 'styled-components';
import { Container } from '@mui/material';
import Button from '../../styled/Button';
import { smartphoneBreakpoint } from '../../../breakpoint';

export default styled(Container)`
  padding: 32px;
  width: 100%;
  max-width: 100%;

  ${smartphoneBreakpoint`
    padding: 8px;
  `}

  ${Button} {
    ${smartphoneBreakpoint`
      margin: 8px;
    `}
  }

  ${Button}:not(:first-child) {
    margin-left: 32px;

    ${smartphoneBreakpoint`
      margin: 8px;
    `}
  }
`;
