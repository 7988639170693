import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserStages, getUser } from '@/store/asyncThunks/user';
import { getUsersWithAccess } from '@/store/userWithAccess';
import { selectToken } from '@/store/selectors/auth';
import { useAppDispatch } from '@/store';

const useAccessToDisplaysData = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);

  useEffect(() => {
    const promises = [
      dispatch(getUsersWithAccess()),
      dispatch(getUser({ id: params.id! })),
      dispatch(getUserStages({ id: params.id! })),
    ];

    return () => {
      promises.forEach((promise) => {
        promise.abort();
      });
    };
  }, [params.id, dispatch, token]);
};

export default useAccessToDisplaysData;
