import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BackupVideoComponent from '@/components/AddLink/BackupVideo';
import { selectOBSVideos } from '@/store/selectors/obs';
import { setChannelStatus } from '@/store/obs';
import { useAppDispatch } from '@/store';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import config from '@/config';

const BackupVideo: FC = () => {
  const dispatch = useAppDispatch();
  const obses = useSelector(selectOBSVideos);

  useEffect(() => {
    const eventSources: EventSource[] = [];
    if (obses.length > 0) {
      obses.forEach((obs) => {
        if (obs.channelId) {
          const eventSource = new EventSource(
            `${config.api.url}/obs/channel-status?id=${obs.id}`
          );

          eventSource.onmessage = ({ data }) => {
            const parsedData = JSON.parse(data);
            dispatch(
              setChannelStatus({ status: parsedData.status, id: obs.id })
            );
          };

          eventSources.push(eventSource);
        }
      });
    }

    return () => {
      eventSources.forEach((eventSource) => {
        eventSource.close();
      });

      obses.forEach((obs) => {
        dispatch(setChannelStatus({ id: obs.id, status: '' }));
      });
    };
  }, [obses, dispatch]);

  return <BackupVideoComponent />;
};

export default Sentry.withErrorBoundary(BackupVideo, {
  fallback: ErrorFallbackComponent,
});
