import styled from 'styled-components';
import { DialogContent } from '@mui/material';
import FullWidthButton from '../../../styled/FullWidthButton';
import breakpoint from '../../../../breakpoint';

export default styled(DialogContent)`
  width: 297px;
  min-height: 202px;
  padding-bottom: 100px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${FullWidthButton} {
    width: calc(100% - 64px);
    position: absolute;
    bottom: 32px;
  }

  ${breakpoint('lg')`
    width: 336px;
    min-height: 228px;
  `}

  ${breakpoint('xl')`
    width: 396px;
    min-height: 269px;
  `}
`;
