import type {
  BackupVideo,
  LiveVideo,
  UpdateStage,
  UserRole,
  User,
} from '@myclipo/bm-admin-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { flatten, chunk } from 'lodash';
import { stagesCollection, usersWithAccessCollection } from '@/firebaseConfig';
import mapStage from '@/helpers/mapStage';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import {
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore/lite';
import { getDrawerStages } from './drawer';
import { showMessage } from '../actions/flashMessage';

const apiService: ApiService = apiServiceFactory();

export const getUserStages = createAsyncThunk(
  'user/getUserStages',
  async ({ id }: { id: string }) => {
    const q = query(usersWithAccessCollection, where('userId', '==', id));
    const stagesWithAccess = await getDocs(q);

    if (stagesWithAccess.size === 0) {
      return [];
    }

    const stagesIds = stagesWithAccess.docs.map((d) => d.data().stageId);
    const chunkPromises = chunk(stagesIds, 10).map(async (stageIdsChunk) => {
      const stagesQ = query(
        stagesCollection,
        where(documentId(), 'in', stageIdsChunk)
      );
      const snapshot = await getDocs(stagesQ);

      return snapshot.docs.map(mapStage);
    });

    const result = await Promise.all(chunkPromises);

    return flatten(result);
  }
);

export const getUserCurrentlyPlayed = createAsyncThunk(
  'user/getUserCurrentlyPlayed',
  async (stageIds: string[], { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAll<BackupVideo | LiveVideo>(
      'videos/currently-played',
      {
        stageIds,
      },
      false,
      source.token
    );
  }
);

export const getUser = createAsyncThunk(
  'user/getUser',
  async ({ id }: { id: string }, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.get<User>('users', id, {}, source.token);
  }
);

export const updateUserStage = createAsyncThunk(
  'user/updateUserStage',
  async (
    { id, stage }: { id: string; stage: UpdateStage },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const ref = doc(stagesCollection, id);
      await updateDoc(ref, { ...stage });
      const updatedStage = await getDoc(ref);
      const result = mapStage(updatedStage);

      dispatch(getDrawerStages({}));

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const sendInvite = createAsyncThunk(
  'user/sendInvite',
  async (
    data: { email: string; role: UserRole; stageId?: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { status } = await apiService.create<
        typeof data,
        { status: string }
      >('invites', data);
      dispatch(showMessage(status, 'success'));

      return { status };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);
