import type { FC } from 'react';
import React from 'react';
import { Divider, CircularProgress } from '@mui/material';
import {
  selectLiveLinks,
  selectLiveLinksLoading,
} from '@/store/selectors/camp';
import { useAppSelector } from '@/store/hooks';
import ProgressContainer from '../styled/ProgressContainer';
import Linklist from './Linklist';

const Live: FC = () => {
  const liveLinks = useAppSelector(selectLiveLinks);
  const liveLinksLoading = useAppSelector(selectLiveLinksLoading);

  return (
    <>
      <Divider />
      {liveLinksLoading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      {!liveLinksLoading && <Linklist links={liveLinks} />}
    </>
  );
};

export default Live;
