import type { ForwardRefRenderFunction } from 'react';
import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { addCampLink as addCampLinkFunc } from '@/store/asyncThunks/camp';
import AddLinkButton from '../AddLink/styled/AddLinkButton';
import EnterURLContainer from '../AddLink/styled/EnterURLContainer';

const validationSchema = Yup.object().shape({
  url: Yup.string().url().min(2, 'Too short').required('Required'),
});

interface EnterURLProps {
  date: string;
}

const EnterURL: ForwardRefRenderFunction<HTMLButtonElement, EnterURLProps> = (
  { date },
  ref
) => {
  const dispatch = useAppDispatch();

  const params = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: { url: '' },
    validationSchema,
    onSubmit: async ({ url }, { resetForm }) => {
      await dispatch(addCampLinkFunc({ campId: params.id!, url, date }));
      resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <EnterURLContainer>
        <TextField
          name="url"
          label="Enter URL"
          placeholder="Enter URL"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.url}
          onChange={formik.handleChange}
          error={!!formik.errors.url}
          helperText={formik.errors.url}
        />
      </EnterURLContainer>
      <AddLinkButton disabled={formik.isSubmitting} ref={ref} type="submit">
        Add link to camp
      </AddLinkButton>
    </form>
  );
};

export default forwardRef<HTMLButtonElement, EnterURLProps>(EnterURL);
