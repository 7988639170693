import type { FC } from 'react';
import React, { useEffect } from 'react';
import type { Theme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Link,
  Routes,
} from 'react-router-dom';
import { AppBar, Button, Divider, Toolbar } from '@mui/material';
import { UserRole } from '@myclipo/bm-admin-common';
import Login from '@/pages/Login';
import AppContainer from '@/styled/AppContainer';
import CreateAccount from '@/pages/CreateAccount';
import CreateAccountComplete from '@/pages/CreateAccountComplete';
import LoginError from '@/pages/LoginError';
import AccountRecovery from '@/pages/AccountRecovery';
import AccountRecoveryComplete from '@/pages/AccountRecoveryComplete';
import AddLink from '@/pages/AddLink';
import { auth as firebaseAuth } from '@/firebaseConfig';
import { setToken as setTokenFunc } from '@/store/auth';
import { selectUser, selectUserRole } from '@/store/selectors/auth';
import Logo from '@/styled/Logo';
import Stage from '@/pages/Stage';
import AccessToDisplays from '@/pages/AccessToDisplays';
import AdminDrawer from '@/components/Drawer/AdminDrawer';
import AddEffects from '@/pages/AddEffects';
import theme from '@/theme';
import Error from '@/components/Error';
import Success from '@/components/Success';
import TermsOfUse from '@/pages/TermsOfUse';
import Camp from '@/pages/Camp';
import CampAddLink from '@/pages/CampAddLink';
import Fireworks from '@/pages/Fireworks';
import ObsConfiguration from '@/pages/ObsConfiguration';
import { authStateChanged as authStateChangedFunc } from '@/store/actions/auth';
import BackupVideo from '@/pages/AddLink/BackupVideo';
import UsersWithAccess from '@/components/Stage/UsersWithAccess';
import Effects from '@/pages/__common/Effects';
import Settings from '@/pages/AddLink/Settings';
import { selectStage } from '@/store/selectors/stage';
import CreateScreen from '@/pages/CreateScreen';
import ObsChannelHistory from '@/pages/ObsChannelHistory';
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import Teleport from '@/pages/Teleport';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUserRole);
  const stage = useAppSelector(selectStage);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (firebaseUser) => {
      dispatch(authStateChangedFunc(firebaseUser));
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, async (firebaseUser) => {
      if (firebaseUser) {
        const newToken = await firebaseUser.getIdToken();
        dispatch(setTokenFunc(newToken));
      } else {
        dispatch(setTokenFunc(null));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <AppBar style={{ zIndex: 5000 }} position="fixed" color="secondary">
            <Toolbar>
              {user?.obsAdmin && (
                <Button component={Link} to="/" color="inherit">
                  Home
                </Button>
              )}
              <Logo />
              {user?.obsAdmin && (
                <Button
                  component={Link}
                  to="/obs-configuration"
                  color="inherit"
                >
                  OBS Configuration
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <AppContainer>
            {user && userRole.includes(UserRole.SuperAdmin) && <AdminDrawer />}
            <Routes>
              {user?.obsAdmin && (
                <>
                  {['/obs-configuration/:id', '/obs-configuration'].map(
                    (path) => (
                      <Route
                        key={path}
                        path={path}
                        element={<ObsConfiguration />}
                      />
                    )
                  )}
                  <Route
                    path="/obs-channel-history/:id"
                    element={<ObsChannelHistory />}
                  />
                </>
              )}
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route
                path="/create-account/complete"
                element={<CreateAccountComplete />}
              />
              <Route
                path="/create-account/:token"
                element={<CreateAccount />}
              />
              {!user && (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/error" element={<LoginError />} />
                  <Route
                    path="/account-recovery"
                    element={<AccountRecovery />}
                  />
                  <Route
                    path="/account-recovery/complete"
                    element={<AccountRecoveryComplete />}
                  />

                  <Route path="*" element={<Navigate to="/login" />} />
                </>
              )}
              {user &&
                userRole.includes(UserRole.DJ) &&
                !userRole.includes(UserRole.Admin) && (
                  <>
                    {['/add-effects/:id', '/add-effects'].map((path) => (
                      <Route key={path} path={path} element={<AddEffects />} />
                    ))}

                    <Route path="*" element={<Navigate to="/add-effects" />} />
                  </>
                )}
              {user && userRole.includes(UserRole.Admin) && (
                <>
                  <Route path="/fireworks" element={<Fireworks />} />
                  {['/add-link/:id', '/add-link'].map((path) => (
                    <Route key={path} path={path} element={<AddLink />}>
                      <Route path="" element={<BackupVideo />} />
                      {!stage?.isBurningMan && (
                        <>
                          <Route
                            path="users-with-access"
                            element={
                              <>
                                <Divider />
                                <UsersWithAccess />
                              </>
                            }
                          />
                          <Route path="effects" element={<Effects />} />
                          <Route path="settings" element={<Settings />} />
                        </>
                      )}
                    </Route>
                  ))}
                  <Route path="/camp/add-link/:id" element={<CampAddLink />} />
                  <Route
                    path="/camp/:id"
                    element={<Navigate to="/add-link" />}
                  />
                  <Route path="*" element={<Navigate to="/add-link" />} />
                </>
              )}
              {user && userRole.includes(UserRole.SuperAdmin) && (
                <>
                  <Route path="/teleport" element={<Teleport />} />
                  <Route path="/fireworks" element={<Fireworks />} />
                  {['/stage/:id/*', '/stage'].map((path) => (
                    <Route key={path} path={path} element={<Stage />} />
                  ))}
                  <Route path="/camp/:id" element={<Camp />} />
                  <Route
                    path="/access-to-screens/:id"
                    element={<AccessToDisplays />}
                  />
                  <Route path="/create-screen" element={<CreateScreen />} />

                  <Route path="*" element={<Navigate to="/stage" />} />
                </>
              )}
            </Routes>
            <Error />
            <Success />
          </AppContainer>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
