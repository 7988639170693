import type { Effect } from '@myclipo/bm-admin-common';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): Effect => {
  const data = doc.data();

  return {
    id: doc.id,
    name: data?.name as string,
    disabledForStages: data?.disabledForStages ?? [],
  };
};
