/* eslint-disable import/prefer-default-export */
import type { Dispatch } from '@reduxjs/toolkit';
import { UserRole } from '@myclipo/bm-admin-common';
import {
  userCampNamesCollection,
  userRolesCollection,
  auth as firebaseAuth,
} from '@/firebaseConfig';
import type ApiService from '@/services/ApiService';
import mapUser from '@/helpers/mapUser';
import apiServiceFactory from '@/services/apiServiceFactory';
import { doc, getDoc } from 'firebase/firestore/lite';
import type { User } from 'firebase/auth';
import { setUser } from '../auth';
import persisitor from '../persisitor';

const apiService: ApiService = apiServiceFactory();

export const authStateChanged =
  (user: User | null) => async (dispatch: Dispatch) => {
    if (user) {
      const token = await user.getIdToken();
      apiService.token = token;
      window.localStorage.setItem('token', token);

      try {
        const [userRoleDoc, campNameDoc] = await Promise.all([
          getDoc(doc(userRolesCollection, user.uid)),
          getDoc(doc(userCampNamesCollection, user.uid)),
        ]);
        const userRole = userRoleDoc.data() as { role: UserRole[] };
        const campName = (campNameDoc.data() ?? { campName: '' }) as {
          campName: string;
        };

        const apiUser = mapUser(
          user,
          userRole,
          campName,
          userRole.role.includes(UserRole.FireworksAdmin),
          userRole.role.includes(UserRole.ObsAdmin)
        );

        dispatch(setUser(apiUser));
      } catch (error) {
        await firebaseAuth.signOut();
        await persisitor().purge();
      }
    } else {
      dispatch(setUser(null));
    }
  };
