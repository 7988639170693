import { createSelector } from '@reduxjs/toolkit';
import { concat, sortBy } from 'lodash';
import type { Camp, OBSVideo, Stage } from '@myclipo/bm-admin-common';
import type { RootState } from '..';

export const selectDrawerObsLinks = createSelector(
  (state: RootState) => state.drawer.obsLinks,
  (obsLinks) => sortBy(obsLinks, 'stages.0.name') as OBSVideo[]
);
export const selectDrawerUsers = (state: RootState) => state.drawer.users;
export const selectDrawerStages = (state: RootState) => state.drawer.stages;
export const selectDrawerHasMoreStages = (state: RootState) =>
  state.drawer.nextStage !== null;
export const selectDrawerHasMoreCamps = (state: RootState) =>
  state.drawer.nextCamp !== null;
export const selectDrawerCamps = (state: RootState) => state.drawer.camps;
export const selectDrawerUsersLoading = (state: RootState) =>
  state.drawer.usersLoading;
export const selectDrawerStagesLoading = (state: RootState) =>
  state.drawer.stagesLoading;
export const selectDrawerCampsLoading = (state: RootState) =>
  state.drawer.campsLoading;
export const selectIsVisibleOnMobile = (state: RootState) =>
  state.drawer.isVisibleOnMobile;
export const selectUsersSearchValue = (state: RootState) =>
  state.drawer.usersSearchValue;
export const selectStagesSearchValue = (state: RootState) =>
  state.drawer.stagesSearchValue;
export const selectDrawerLoading = createSelector(
  selectDrawerStagesLoading,
  selectDrawerCampsLoading,
  (stagesLoading, campsLoading) => stagesLoading || campsLoading
);
export const selectDrawerItems = createSelector(
  selectDrawerStages,
  selectDrawerCamps,
  (stages, camps) =>
    concat<(Stage & { type: 'stage' }) | (Camp & { type: 'camp' })>(
      stages.map((stage) => ({ ...stage, type: 'stage' })),
      camps.map((camp) => ({ ...camp, type: 'camp' }))
    )
);
