import type { Stage, User } from '@myclipo/bm-admin-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import axios from 'axios';
import type { RootState } from '..';

const apiService: ApiService = apiServiceFactory();

export const getDialogUsers = createAsyncThunk(
  'dialog/getUsers',
  async (params: Record<string, unknown> = {}, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAll<User>('users', params, false, source.token);
  }
);

export const getDialogStages = createAsyncThunk(
  'dialog/getStages',
  async (params: Record<string, unknown> = {}, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllPaginated<Stage>(
      'stages',
      params,
      false,
      source.token
    );
  }
);

export const getMoreDialogStages = createAsyncThunk(
  'dialog/getMoreStages',
  async (params: Record<string, unknown> = {}, { getState, signal }) => {
    const state = getState() as RootState;

    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const newParams = { ...params, from: state.dialog.nextStage };
    return apiService.getAllPaginated<Stage>(
      'stages',
      newParams,
      false,
      source.token
    );
  }
);
