import styled from 'styled-components';
import { Card } from '@mui/material';
import breakpoint, { smartphoneBreakpoint } from '../../breakpoint';

export default styled(Card)`
  width: 314px;
  padding: 16px;
  padding-top: 42px;
  background: #1e1f22 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 40px #000000bf;

  ${breakpoint('lg')`
    width: 358px;
    padding-top: 47px;
  `}

  ${breakpoint('xl')`
    width: 428px;
    padding-top: 56px;
  `}

  ${smartphoneBreakpoint`
    background: none;
    box-shadow: none;
    padding: 8px;
    padding-top: 42px;
    width: calc(100% - 16px);
    height: calc(100% - 128px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;
