import type { FC } from 'react';
import React, { memo } from 'react';
import { Typography } from '@mui/material';
import HeaderSmallContainer from '@/components/CampAndStageCommon/styled/HeaderSmallContainer';

const HeadSmall: FC<{ title: string }> = ({ title }) => (
  <HeaderSmallContainer maxWidth={false}>
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
  </HeaderSmallContainer>
);

export default memo(HeadSmall);
