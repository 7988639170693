import type { Camp } from '@myclipo/bm-admin-common';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): Camp => {
  const data = doc.data();

  return {
    id: doc.id,
    number: data?.number,
    name: data?.name,
    backupLink: data?.backupLink ?? null,
  };
};
