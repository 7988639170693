import type { Settings } from '@/store/interfaces';

export default (): Settings => ({
  leftFogMonster: { on: false },
  rightFogMonster: { on: false },
  leftZeurobo: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
    bpm: 0,
  },
  rightZeurobo: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
    bpm: 0,
  },
  leftTower: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
    bpm: 0,
  },
  rightTower: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
    bpm: 0,
  },
  trees: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
    bpm: 0,
  },
  title: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
  },
  mainStage: {
    lightColor: '',
    lightEnabled: false,
    rateAutomaticCalculation: false,
    bpm: 0,
  },
});
