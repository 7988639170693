import { useState, useLayoutEffect } from 'react';

export default () => {
  const mediaMatch = window.matchMedia('(min-width: 1920px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useLayoutEffect(() => {
    const handler = (e: any) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  return matches;
};
