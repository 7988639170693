import type { FC } from 'react';
import React, { memo } from 'react';
import type { Stage } from '@myclipo/bm-admin-common';
import BackupBadge from './styled/BackupBadge';
import DisplayNameContainer from './styled/DisplayNameContainer';

interface DisplayNameProps {
  stage: Stage;
}

const DisplayName: FC<DisplayNameProps> = ({ stage }) => (
  <DisplayNameContainer>
    {stage.name}
    {stage.streamingNow && <BackupBadge>Streaming now</BackupBadge>}
  </DisplayNameContainer>
);

export default memo(DisplayName);
