import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Divider, TextField, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { BackupCampLink } from '@myclipo/bm-admin-common';
import {
  selectCampLoading,
  selectBackupLinksLoading,
  selectBackupLinks,
  selectCamp,
} from '@/store/selectors/camp';
import {
  addBackupLink as addBackupLinkFunc,
  updateCamp as updateCampFunc,
  removeBackupLink as removeBackupLinkFunc,
} from '@/store/asyncThunks/camp';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import EnterURLContainer from './styled/EnterURLContainer';
import AddLinkButton from './styled/AddLinkButton';
import Linklist from './Linklist';
import ProgressContainer from '../styled/ProgressContainer';
import ChangeActiveLink from './ChangeActiveLink';

const validationSchema = Yup.object().shape({
  url: Yup.string().url().min(2, 'Too short').required('Required'),
});

const BackupLink: FC = () => {
  const dispatch = useAppDispatch();

  const campLoading = useAppSelector(selectCampLoading);
  const backupLinksLoading = useAppSelector(selectBackupLinksLoading);
  const backupLinks = useAppSelector(selectBackupLinks);
  const camp = useAppSelector(selectCamp);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<string | null>(null);

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setCurrent(null);
  };

  const handleRowClick = (id: string) => {
    setCurrent(id);
    setConfirmOpen(true);
  };

  const handleAddLink = useCallback(
    async (url: string) => {
      await dispatch(addBackupLinkFunc({ campId: camp.id, url }));
    },
    [dispatch, camp]
  );

  const formik = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema,
    onSubmit: async ({ url }, { resetForm }) => {
      await handleAddLink(url);
      resetForm();
    },
  });

  const handleConfirm = useCallback(() => {
    if (current) {
      if (camp.backupLink?.id === current) {
        dispatch(
          updateCampFunc({
            id: camp.id,
            camp: {
              backupLink: { id: current, active: !camp.backupLink?.active },
            },
          })
        );
      } else {
        dispatch(
          updateCampFunc({
            id: camp.id,
            camp: { backupLink: { id: current, active: true } },
          })
        );
      }
    }

    setConfirmOpen(false);
    setCurrent(null);
  }, [dispatch, camp.id, current, camp.backupLink]);

  const handleRowRemove = useCallback(
    (id: string) => {
      dispatch(removeBackupLinkFunc({ id, campId: camp.id }));
    },
    [dispatch, camp.id]
  );

  const links = useMemo<BackupCampLink[]>(
    () =>
      backupLinks.map((backupLink) => ({
        ...backupLink,
        isActive:
          camp.backupLink?.id === backupLink.id && !!camp.backupLink?.active,
      })),
    [backupLinks, camp.backupLink]
  );

  return (
    <>
      <Divider />
      {(campLoading || backupLinksLoading) && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      {!campLoading && !backupLinksLoading && (
        <Linklist
          onRowClick={handleRowClick}
          links={links}
          onRowRemove={handleRowRemove}
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <EnterURLContainer>
          <TextField
            name="url"
            label="Enter URL"
            placeholder="Enter URL"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.url}
            onChange={formik.handleChange}
            error={!!formik.errors.url}
            helperText={formik.errors.url}
          />
        </EnterURLContainer>
        <AddLinkButton disabled={formik.isSubmitting} type="submit">
          Add link to screen
        </AddLinkButton>
      </form>
      <ChangeActiveLink
        open={confirmOpen}
        onConfirm={handleConfirm}
        onClose={handleConfirmClose}
      />
    </>
  );
};

export default BackupLink;
