/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { concat } from 'lodash';
import type { DialogState } from './interfaces';
import {
  getDialogStages,
  getDialogUsers,
  getMoreDialogStages,
} from './asyncThunks/dialog';

const initialState: DialogState = {
  users: [],
  usersLoading: false,
  stages: [],
  stagesLoading: false,
  nextStage: null,
  previousStage: null,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    resetStages: (state) => {
      state.stages = [];
      state.nextStage = null;
      state.previousStage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDialogUsers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(getDialogUsers.rejected, (state) => {
        state.usersLoading = false;
      })
      .addCase(getDialogUsers.fulfilled, (state, action) => {
        const users = action.payload;
        state.users = users;
        state.usersLoading = false;
      });

    builder
      .addCase(getDialogStages.pending, (state) => {
        state.stagesLoading = true;
      })
      .addCase(getDialogStages.rejected, (state) => {
        state.stagesLoading = false;
      })
      .addCase(getDialogStages.fulfilled, (state, action) => {
        const {
          payload: { items, next, previous },
        } = action;
        state.stages = items;
        state.nextStage = next;
        state.previousStage = previous;
        state.stagesLoading = false;
      });

    builder.addCase(getMoreDialogStages.fulfilled, (state, action) => {
      const {
        payload: { items, next, previous },
      } = action;
      state.stages = concat(state.stages, items);
      state.nextStage = next;
      state.previousStage = previous;
      state.stagesLoading = false;
    });
  },
});

export const { resetStages } = dialogSlice.actions;

export default dialogSlice.reducer;
