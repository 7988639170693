import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

export default () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueIsValidEmail, setSearchValueIsValidEmail] =
    useState<boolean>(false);

  useEffect(() => {
    setSearchValueIsValidEmail(Yup.string().email().isValidSync(searchValue));
  }, [setSearchValueIsValidEmail, searchValue]);

  const handleSearchValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchValue(event.target.value);
    },
    [setSearchValue]
  );

  const resetSearchValue = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  return {
    searchValue,
    handleSearchValueChange,
    searchValueIsValidEmail,
    resetSearchValue,
  };
};
