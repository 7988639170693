import styled from 'styled-components';
import Button from '../../../styled/Button';
import breakpoint from '../../../../breakpoint';

export default styled.div`
  margin-top: 74px;
  display: flex;
  justify-content: space-between;

  ${Button} {
    flex: 1;
    max-width: 140px;

    ${breakpoint('lg')`
        max-width: 160px;
    `}

    ${breakpoint('xl')`
        max-width: 190px;
    `}
  }
`;
