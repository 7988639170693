import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { selectOBSStages } from '@/store/selectors/obs';
import { removeStageFromObs } from '@/store/asyncThunks/obs';
import { useAppSelector } from '@/store/hooks';

interface Props {
  obsId: string;
}

const StagesTable: FC<Props> = ({ obsId }) => {
  const dispatch = useAppDispatch();
  const stages = useAppSelector(selectOBSStages);

  const remove = useCallback(
    (stageId: string) => {
      dispatch(removeStageFromObs({ id: obsId, stageId }));
    },
    [obsId, dispatch]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell align="right">Name</TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {stages.map((s) => (
          <TableRow key={s.id}>
            <TableCell>
              <Link component={RouterLink} to={`/stage/${s.id}`}>
                {s.id}
              </Link>
            </TableCell>
            <TableCell align="right">{s.name}</TableCell>
            <TableCell align="right">
              <IconButton onClick={() => remove(s.id)} size="large">
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default StagesTable;
