import type { CampLink } from '@myclipo/bm-admin-common';
import type { FirebaseDoc } from '@/types';

export default (doc: FirebaseDoc): CampLink => {
  const data = doc.data();

  return {
    id: doc.id,
    campId: data?.campId,
    url: data?.url,
    title: data?.title,
    customTitle: data?.customTitle,
    siteName: data?.siteName,
    description: data?.description,
    thumbnailHref: data?.thumbnailHref,
    date: data?.date,
    startDate: data?.startDate,
    endDate: data?.endDate,
  };
};
