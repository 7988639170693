/* eslint-disable import/no-duplicates */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { collection, initializeFirestore } from 'firebase/firestore/lite';
import { getDatabase, ref } from 'firebase/database';

// firebase init goes here
const config = {
  appId: import.meta.env.REACT_APP_FIREBASE_API_ID,
  apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
  projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(config);

const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const getGoogleAuthProvider = () => {
  const googleAuthProvider = new GoogleAuthProvider();
  googleAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
  googleAuthProvider.addScope(
    'https://www.googleapis.com/auth/userinfo.profile'
  );
  return googleAuthProvider;
};

export const googleAuthProvider = getGoogleAuthProvider();

export const db = initializeFirestore(app, { ignoreUndefinedProperties: true });
export const realtimeDb = getDatabase(app);

// firebase collections
export const usersCollection = collection(db, 'users');
export const userRolesCollection = collection(db, 'userRoles');
export const usersWithAccessCollection = collection(db, 'usersWithAccess');
export const acceptedInvitesCollection = collection(db, 'acceptedInvites');
export const effectsCollection = collection(db, 'effects');
export const stagesCollection = collection(db, 'stages');
export const rateAdminStagesCollection = collection(db, 'rateAdminStages');
export const stageSettingsCollection = collection(db, 'stageSettings');
export const campsCollection = collection(db, 'camps');
export const campLinksCollection = collection(db, 'campLinks');
export const obsLinksCollection = collection(db, 'obsLinks');
export const backupCampLinksCollection = collection(db, 'backupCampLinks');
export const campUsersWithAccessCollection = collection(
  db,
  'campUsersWithAccess'
);
export const videosCollection = collection(db, 'videos');
export const festivalDatesCollection = collection(db, 'festivalDates');
export const backupVideosCollection = collection(db, 'backupVideos');
export const userCampNamesCollection = collection(db, 'userCampNames');
export const obsEventsCollection = collection(db, 'obsEvents');
export const fireworksCollection = collection(db, 'fireworks');
export const teleportCollection = collection(db, 'teleport');

export const salvationSettings = ref(realtimeDb, 'settings/salvation');

export default {
  analytics,
  db,
  auth,
  googleAuthProvider,
  usersCollection,
  effectsCollection,
  userRolesCollection,
  usersWithAccessCollection,
  acceptedInvitesCollection,
  stagesCollection,
  stageSettingsCollection,
  videosCollection,
  festivalDatesCollection,
  backupVideosCollection,
  userCampNamesCollection,
  campsCollection,
  campUsersWithAccessCollection,
  campLinksCollection,
  backupCampLinksCollection,
  obsLinksCollection,
  fireworksCollection,
  salvationSettings,
};
