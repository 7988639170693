import styled from 'styled-components';
import { DialogContent } from '@mui/material';
import FullWidthButton from './FullWidthButton';
import SearchField from './SearchField';
import Nav from './Nav';
import breakpoint, { smartphoneBreakpoint } from '../../breakpoint';

export default styled(DialogContent)`
  width: 345px;
  min-height: 327px;
  padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  ${FullWidthButton} {
    margin-left: 32px;
    margin-right: 32px;
    width: calc(100% - 64px);
    position: absolute;
    bottom: 32px;
  }

  ${SearchField} {
    margin-left: 32px;
    margin-right: 32px;
    width: calc(100% - 64px);
  }

  ${Nav} {
    max-height: 324px;
  }

  ${breakpoint('lg')`
    width: 391px;
    min-height: 370px;
  `}

  ${breakpoint('xl')`
    width: 460px;
    min-height: 436px;
  `}

  ${smartphoneBreakpoint`
    width: 100%;
  `}
`;
