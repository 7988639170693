/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';

export default withTheme(styled(({ active, ...rest }) => (
  <Typography {...rest} />
)).attrs({
  variant: 'h6',
  gutterBottom: true,
})<{ active: boolean }>`
  color: ${(props) => props.active && props.theme.palette.primary.main};
`);
