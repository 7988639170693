import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import type { FallbackRender } from '@sentry/react';

const ErrorFallbackComponent: FallbackRender = ({ resetError, error }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    resetError();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>An error has occurred.</DialogTitle>
      <DialogContent>
        <DialogContentText>{error.toString()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorFallbackComponent;
