import type { FC } from 'react';
import React, { useState, Suspense } from 'react';
import { Divider, Tabs, Tab } from '@mui/material';
import { selectIsVisibleOnMobile } from '@/store/selectors/drawer';
import { useAppSelector } from '@/store/hooks';
import Profile from '../Profile';
import StyledDrawer from '../styled/Drawer';

const DisplayList = React.lazy(() => import('./DisplayList'));
const UserList = React.lazy(() => import('./UserList'));

const AdminDrawer: FC = () => {
  const isVisibleOnMobile = useAppSelector(selectIsVisibleOnMobile);
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <StyledDrawer
      isVisibleOnMobile={isVisibleOnMobile}
      anchor="left"
      open
      variant="persistent"
    >
      <Profile />
      <Divider />
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="inherit"
        onChange={handleChange}
      >
        <Tab label="Screen list" />
        <Tab label="User list" />
      </Tabs>
      <Divider />
      <Suspense fallback={<div />}>
        <DisplayList show={tab === 0} />
        <UserList show={tab === 1} />
      </Suspense>
    </StyledDrawer>
  );
};

export default AdminDrawer;
