import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getStage,
  getBackupVideos,
  getStageUsersWithAccess,
  getFestivalDates,
  getAcceptedInvitesForUsersWithAccess,
} from '@/store/asyncThunks/stage';
import { selectToken, selectUser } from '@/store/selectors/auth';
import { getOBSLinks } from '@/store/asyncThunks/obs';
import { selectUsersWithAccess } from '@/store/selectors/stage';
import { getStageImages } from '@/store/asyncThunks/image';
import { updateCurrent } from '@/store/stage';
import { useAppDispatch } from '@/store';
import config from '@/config';

const emptyPromise = {
  abort() {},
};

const useAddLinkData = () => {
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const usersWithAccess = useSelector(selectUsersWithAccess);

  useEffect(() => {
    const promise = dispatch(getFestivalDates());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    const eventSource = new EventSource(
      `${config.api.url}/stages/${params.id}/status`
    );

    eventSource.onmessage = ({ data }) => {
      const parsedData = JSON.parse(data);
      dispatch(updateCurrent(parsedData));
    };

    return () => {
      eventSource.close();
    };
  }, [params.id, dispatch]);

  useEffect(() => {
    const promises: any[] = [];

    if (params.id && token) {
      promises.push(
        dispatch(getStage(params.id)),
        dispatch(getStageImages(params.id)),
        dispatch(getOBSLinks(params.id)),
        dispatch(getBackupVideos(params.id))
      );
    }

    return () => {
      promises.forEach((promise) => {
        promise.abort();
      });
    };
  }, [params.id, dispatch, token]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && token && user) {
      promise = dispatch(
        getStageUsersWithAccess({ stageId: params.id, role: user.role })
      );
    }
    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token, user]);

  useEffect(() => {
    let promise: any = emptyPromise;

    if (params.id && token && usersWithAccess.length > 0) {
      promise = dispatch(
        getAcceptedInvitesForUsersWithAccess(
          usersWithAccess.map(({ userId }) => userId)
        )
      );
    }
    return () => {
      promise.abort();
    };
  }, [params.id, dispatch, token, usersWithAccess]);
};

export default useAddLinkData;
