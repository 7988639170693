import { useState, useEffect } from 'react';
import useClientWidth from './useClientWidth';

export default () => {
  const [visible, setVisible] = useState(false);
  const clientWidth = useClientWidth();

  useEffect(() => {
    if (clientWidth >= 320 && clientWidth <= 812) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [clientWidth]);

  return visible;
};
