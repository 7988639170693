import type {
  BackupCampLink,
  CampLink,
  CampUserWithAccess,
  NewCampLink,
  NewUserWithAccess,
  UpdateCamp,
  UpdateCampLink,
} from '@myclipo/bm-admin-common';
import { UserRole } from '@myclipo/bm-admin-common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  backupCampLinksCollection,
  campLinksCollection,
  campsCollection,
  campUsersWithAccessCollection,
  db,
} from '@/firebaseConfig';
import mapBackupCampLink from '@/helpers/mapBackupCampLink';
import mapCamp from '@/helpers/mapCamp';
import mapCampLink from '@/helpers/mapCampLink';
import mapCampUserWithAccess from '@/helpers/mapCampUserWithAccess';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore/lite';
import { getDrawerCamps } from './drawer';

const apiService: ApiService = apiServiceFactory();

export const getCamp = createAsyncThunk('camp/getCamp', async (id: string) => {
  const d = await getDoc(doc(campsCollection, id));

  return mapCamp(d);
});

export const getCampLinks = createAsyncThunk(
  'camp/getCampLinks',
  async (campId: string) => {
    const q = query(campLinksCollection, where('campId', '==', campId));
    const snapshot = await getDocs(q);

    return snapshot.docs.map(mapCampLink);
  }
);

export const getCampUsersWithAccess = createAsyncThunk(
  'camp/getCampUsersWithAccess',
  async ({ campId, role }: { campId: string; role?: UserRole }) => {
    const conditions = [where('campId', '==', campId)];

    if (role === UserRole.DJ) {
      conditions.push(where('isDJ', '==', true));
    }
    if (role === UserRole.Admin) {
      conditions.push(where('isDJ', '==', false));
    }
    const snapshot = await getDocs(
      query(campUsersWithAccessCollection, orderBy('email'), ...conditions)
    );

    return snapshot.docs.map(mapCampUserWithAccess);
  }
);

export const addUserWithAccess = createAsyncThunk(
  'camp/addUserWithAccess',
  async (
    {
      campId,
      userWithAccess,
    }: { campId: string; userWithAccess: NewUserWithAccess },
    { rejectWithValue }
  ) => {
    try {
      return await apiService.create<NewUserWithAccess, CampUserWithAccess>(
        `camps/${campId}/users-with-access`,
        userWithAccess
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateUserWithAccess = createAsyncThunk(
  'camp/updateUserWithAccess',
  async (
    {
      id,
      userWithAccess,
    }: {
      id: string;
      userWithAccess: Partial<NewUserWithAccess>;
    },
    { rejectWithValue }
  ) => {
    try {
      const userWithAccessRef = doc(campUsersWithAccessCollection, id);

      await updateDoc(userWithAccessRef, userWithAccess);
      const updatedUserWithAccess = await getDoc(userWithAccessRef);

      return mapCampUserWithAccess(updatedUserWithAccess);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const removeUserWithAccess = createAsyncThunk(
  'camp/removeUserWithAccess',
  async (id: string) => {
    await deleteDoc(doc(campUsersWithAccessCollection, id));

    return { id };
  }
);

export const removeCampLink = createAsyncThunk(
  'camp/removeCampLink',
  async ({ id, date }: { id: string; date: string }) => {
    await deleteDoc(doc(campLinksCollection, id));

    return { id, date };
  }
);

export const removeCampLinksBulk = createAsyncThunk(
  'camp/removeCampLinksBulk',
  async ({ ids, date }: { ids: string[]; date: string }) => {
    const batch = writeBatch(db);
    ids.forEach((id) => {
      batch.delete(doc(campLinksCollection, id));
    });
    await batch.commit();

    return { ids, date };
  }
);

export const addCampLink = createAsyncThunk(
  'camp/addCampLink',
  async (newLink: NewCampLink, { rejectWithValue }) => {
    try {
      return await apiService.create<NewCampLink, CampLink>(
        'campLinks',
        newLink
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateCampLink = createAsyncThunk(
  'camp/updateCampLink',
  async (
    { id, link }: { id: string; link: UpdateCampLink },
    { rejectWithValue }
  ) => {
    try {
      const result = await apiService.update<UpdateCampLink, CampLink>(
        'campLinks',
        id,
        link
      );

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const getBackupLinks = createAsyncThunk(
  'camp/getBackupLinks',
  async (campId: string) => {
    const q = query(backupCampLinksCollection, where('campId', '==', campId));
    const snapshot = await getDocs(q);

    return snapshot.docs.map(mapBackupCampLink);
  }
);

export const addBackupLink = createAsyncThunk(
  'camp/addBackupLink',
  async (
    { campId, url }: { campId: string; url: string },
    { rejectWithValue }
  ) => {
    try {
      const result = await apiService.create<{ url: string }, BackupCampLink>(
        `camps/${campId}/backup-links`,
        { url }
      );
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const updateCamp = createAsyncThunk(
  'camp/updateCamp',
  async (
    { id, camp: { backupLink } }: { id: string; camp: UpdateCamp },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const ref = doc(campsCollection, id);
      await updateDoc(ref, { backupLink });
      const updatedStage = await getDoc(ref);
      const result = mapCamp(updatedStage);

      // dispatch(getLiveVideos({ stageId: id }));
      dispatch(getBackupLinks(id));
      dispatch(getDrawerCamps({}));

      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const removeBackupLink = createAsyncThunk(
  'camp/removeBackupLink',
  async (
    { id, campId }: { id: string; campId: string },
    { rejectWithValue }
  ) => {
    try {
      await deleteDoc(doc(backupCampLinksCollection, id));
      await updateDoc(doc(campsCollection, campId), {
        backupLink: { active: false },
      });

      return { id };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }

      throw error;
    }
  }
);

export const getLiveLinks = createAsyncThunk(
  'camp/getLiveLinks',
  async (campId: string, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    return apiService.getAllSubresources<CampLink>(
      'camps',
      campId,
      'links/current',
      {},
      source.token
    );
  }
);
