import {
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import HeaderContainer from '@/components/styled/HeaderContainer';
import ProgressContainer from '@/components/styled/ProgressContainer';
import {
  selectIsTeleportEnabled,
  selectTeleportLoading,
} from '@/store/selectors/teleport';
import { useAppDispatch } from '@/store';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import useNewSockets from '@/hooks/useNewSockets';
import { setEnabled } from '@/store/teleport';
import { useAppSelector } from '@/store/hooks';
import { WebsocketEvents } from '@myclipo/bm-admin-common';
import PanelContainer from './styled/PanelContainer';

const Teleport: FC = () => {
  const dispatch = useAppDispatch();
  const newSocketsClient = useNewSockets();

  const loading = useAppSelector(selectTeleportLoading);
  const enabled = useAppSelector(selectIsTeleportEnabled);

  useEffect(() => {
    newSocketsClient.emit(WebsocketEvents.Incomming.GetTeleport, {});
  }, [newSocketsClient]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      newSocketsClient.emit(WebsocketEvents.Incomming.ChangeTeleport, {
        enabled: checked,
      });
    },
    [newSocketsClient]
  );

  useEffect(() => {
    newSocketsClient.on(
      WebsocketEvents.Outgoing.TeleportChanged,
      (data: { enabled: boolean }) => {
        dispatch(setEnabled(data.enabled));
      }
    );

    return () => {
      newSocketsClient.off(WebsocketEvents.Outgoing.TeleportChanged);
    };
  }, [newSocketsClient, dispatch]);

  return (
    <PanelContainer>
      <HeaderContainer maxWidth={false}>
        <Typography variant="h4">Teleport</Typography>
      </HeaderContainer>
      <Divider />
      {!loading && (
        <Container
          style={{ paddingTop: 16, paddingBottom: 16, maxWidth: '100%' }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Teleport enabled"
          />
        </Container>
      )}
      {loading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
    </PanelContainer>
  );
};

export default Sentry.withErrorBoundary(Teleport, {
  fallback: ErrorFallbackComponent,
});
