import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import type { SnackbarCloseReason } from '@mui/material';
import {
  CardContent,
  Typography,
  TextField,
  Snackbar,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { setRegistrationError as setRegistrationErrorFunc } from '@/store/auth';
import { selectRegistrationError } from '@/store/selectors/auth';
import { updateAccount as updateAccountFunc } from '@/store/asyncThunks/auth';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store';
import Card from '../styled/Card';
import CardActions from '../styled/CardActions';
import Button from '../styled/FullWidthButton';
import Stepper from '../styled/Stepper';
import Alert from '../Alert';
import TermsOfUseAnchor from './styled/TermsOfUseAnchor';
import CardForm from '../styled/CardForm.';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  campName: Yup.string().required('This field is required'),
  password: Yup.string()
    .min(8, 'Password is too short')
    .required('This field is required'),
  confirmPassword: Yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both password need to be the same'
    ),
  }),
  terms: Yup.boolean().oneOf([true], 'Must Accept Terms of use'),
});

const CreateAccountCard: FC = () => {
  const dispatch = useAppDispatch();

  const registrationError = useAppSelector(selectRegistrationError);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const params = useParams<{ token: string }>();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      campName: '',
      password: '',
      confirmPassword: '',
      terms: false,
    },
    validationSchema,
    onSubmit: async ({ firstName, lastName, password, campName }) => {
      await dispatch(
        updateAccountFunc({
          displayName: `${firstName} ${lastName}`,
          password,
          campName,
          token: params.token!,
        })
      );

      navigate('/create-account/complete');
    },
  });

  useEffect(() => {
    setSnackbarOpen(!!registrationError);
  }, [registrationError]);

  const handleSnackbarClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
    dispatch(setRegistrationErrorFunc(null));
  };

  const openTermsOfUse = () => {
    window.open(
      '/terms-of-use',
      '_blank',
      'toolbar=0,location=0,menubar=0,width=640,height=640'
    );
  };

  return (
    <>
      <CardForm onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom align="center">
              Creating account
            </Typography>
            <Stepper
              steps={3}
              activeStep={1}
              nextButton={<div />}
              backButton={<div />}
            />
            <TextField
              name="firstName"
              label="First name"
              fullWidth
              margin="normal"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              error={!!formik.errors.firstName}
              helperText={formik.errors.firstName}
            />
            <TextField
              name="lastName"
              label="Last name"
              fullWidth
              margin="normal"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              error={!!formik.errors.lastName}
              helperText={formik.errors.lastName}
            />
            <TextField
              name="campName"
              label="Camp name"
              fullWidth
              margin="normal"
              onChange={formik.handleChange}
              value={formik.values.campName}
              error={!!formik.errors.campName}
              helperText={formik.errors.campName}
            />
            <TextField
              name="password"
              label="Password"
              fullWidth
              margin="normal"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={!!formik.errors.password}
              helperText={formik.errors.password}
            />
            <TextField
              name="confirmPassword"
              label="Re-type password"
              fullWidth
              margin="normal"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              error={!!formik.errors.confirmPassword}
              helperText={formik.errors.confirmPassword}
            />
            <FormControl error={!!formik.errors.terms}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={formik.handleChange}
                    checked={formik.values.terms}
                    name="terms"
                    color="primary"
                  />
                }
                label={
                  <>
                    I agree with{' '}
                    <TermsOfUseAnchor onClick={openTermsOfUse}>
                      Terms of use
                    </TermsOfUseAnchor>
                  </>
                }
              />
              <FormHelperText>{formik.errors.terms}</FormHelperText>
            </FormControl>
          </CardContent>
          <CardActions>
            <Button disabled={formik.isSubmitting} type="submit">
              Next
            </Button>
          </CardActions>
        </Card>
      </CardForm>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {registrationError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateAccountCard;
