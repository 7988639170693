/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { Effect, StageEvent } from '@myclipo/bm-admin-common';
import { getDocs, query, where } from 'firebase/firestore/lite';
import { effectsCollection } from '@/firebaseConfig';
import type { RootState } from '.';
import mapEffect from '../helpers/mapEffect';

export interface EffectState {
  list: Effect[];
}

export const getEffects = createAsyncThunk(
  'effect/getEffects',
  async ({ stageId, event }: { stageId: string; event: StageEvent }) => {
    const { docs } = await getDocs(
      query(effectsCollection, where('event', 'array-contains', event))
    );

    return docs
      .map(mapEffect)
      .filter((effect) => !effect.disabledForStages.includes(stageId));
  }
);

const initialState: EffectState = {
  list: [],
};

export const effectSlice = createSlice({
  name: 'effect',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEffects.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export const selectEffects = (state: RootState) => state.effect.list;

export default effectSlice.reducer;
