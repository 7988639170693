import { useAppDispatch } from '@/store';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import type { Stage } from '@myclipo/bm-admin-common';
import type { CancelToken } from 'axios';
import axios from 'axios';
import { debounce } from 'lodash';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type ApiService from '@/services/ApiService';
import apiServiceFactory from '@/services/apiServiceFactory';
import { addStageToObs } from '@/store/asyncThunks/obs';
import { selectToken } from '@/store/selectors/auth';
import AddLinkButton from '../AddLink/styled/AddLinkButton';

const apiService: ApiService = apiServiceFactory();

interface Props {
  obsId: string;
}

const StagesSearch: FC<Props> = ({ obsId }) => {
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const [stage, setStage] = useState<Stage | null>(null);
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Stage[]>([]);

  const save = useCallback(async () => {
    await dispatch(addStageToObs({ id: obsId, stage: stage! }));
    setValue('');
    setStage(null);
  }, [obsId, stage]);

  const search = useMemo(
    () =>
      debounce(async (name: string, cancelToken: CancelToken) => {
        apiService.token = token as string;
        setLoading(true);
        try {
          const stages = await apiService.getAllPaginated<Stage>(
            'stages',
            {
              name,
            },
            false,
            cancelToken
          );
          setOptions(stages.items);
        } catch (err) {
          setOptions([]);
        } finally {
          setLoading(false);
        }
      }, 500),
    [token]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    search(value, source.token);

    return () => {
      source.cancel();
    };
  }, [search, value]);

  return (
    <>
      <div>
        <Autocomplete
          value={stage}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, v) => option.name === v.name}
          getOptionLabel={(option) => `${option.name} (${option.id})`}
          options={options}
          loading={loading}
          onChange={(event: React.ChangeEvent<{}>, v: Stage | null) => {
            setStage(v);
          }}
          onInputChange={(event: React.ChangeEvent<{}>, v: string) => {
            setValue(v);
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              name="stageName"
              label="Enter stage name"
              placeholder="Enter stage name"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <AddLinkButton withoutSideMargins type="submit" onClick={save}>
        Add stage to the configuration
      </AddLinkButton>
    </>
  );
};

export default StagesSearch;
