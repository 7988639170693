import type { FC, ChangeEvent } from 'react';
import React from 'react';
import {
  TableRow,
  TableHead as MaterialTableHead,
  TableCell,
  Checkbox,
  Button,
} from '@mui/material';

interface TableHeadProps {
  allSelected: boolean;
  onSelectAll: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onDeleteAll: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const TableHead: FC<TableHeadProps> = ({
  onSelectAll,
  onDeleteAll,
  allSelected,
}) => (
  <MaterialTableHead>
    <TableRow>
      <TableCell padding="checkbox" colSpan={2}>
        <Checkbox
          color="primary"
          checked={allSelected}
          onChange={onSelectAll}
        />
      </TableCell>
      <TableCell align="right" colSpan={3}>
        <Button onClick={onDeleteAll}>Delete</Button>
      </TableCell>
    </TableRow>
  </MaterialTableHead>
);

export default TableHead;
