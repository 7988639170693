import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import {
  TableCell,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Slider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { get } from 'lodash';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import type {
  OBSVideo as OBSVideoEntity,
  BackupVideo as BackupVideoEntity,
  LiveVideo,
  UpdateBackupVideo,
} from '@myclipo/bm-admin-common';
import SchedulerControlledContext from '@/context/SchedulerControlledContext';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '@/store/selectors/auth';
import StyledTableRow from './styled/TableRow';
import Title from './styled/Title';
import BottomText from './styled/BottomText';
import SliderContainer from './styled/SliderContainer';
import ControlsContainer from '../../styled/ControlsContainer';

interface TableRowProps {
  active?: boolean;
  playlistVideo: LiveVideo | BackupVideoEntity | OBSVideoEntity;
  onClick: (id: string, url: string | null) => void;
  onRemove?: (id: string, url: string | null) => void;
  onSettingChange: (id: string, data: UpdateBackupVideo) => void;
}

const TableRow: FC<TableRowProps> = ({
  active,
  playlistVideo,
  onClick,
  onRemove,
  onSettingChange,
}) => {
  const { schedulerControlled } = useContext(SchedulerControlledContext);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const videoId = useMemo(
    () => get(playlistVideo, 'videoId') ?? get(playlistVideo, 'id') ?? '',
    [playlistVideo]
  );

  const handleClick = useCallback(() => {
    if (videoId && (!schedulerControlled || isSuperAdmin)) {
      onClick(videoId, playlistVideo.url);
    }
  }, [onClick, videoId, playlistVideo]);

  const handleRemove = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      if (onRemove && videoId) {
        onRemove(videoId, playlistVideo.url);
      }
    },
    [onRemove, videoId]
  );

  const handleGreenScreenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (videoId) {
        onSettingChange(videoId, { greenScreen: checked });
      }
    },
    [onSettingChange, videoId]
  );

  const handleMuteAudioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSettingChange(videoId, { audio: { muted: checked } });
    },
    [onSettingChange, videoId]
  );

  const handleScreenSizeChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(videoId, {
        screenSize: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, videoId]
  );

  const handleScreenOffsetYChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(videoId, {
        screenOffsetY: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, videoId]
  );

  const handleScreenBrightnessChange = useCallback(
    (
      event: Event | React.SyntheticEvent<Element, Event>,
      value: number | number[]
    ) => {
      onSettingChange(videoId, {
        screenBrightness: Array.isArray(value) ? value[0] : value,
      });
    },
    [onSettingChange, videoId]
  );

  return (
    <StyledTableRow active={!!active} onClick={handleClick}>
      <TableCell style={{ width: 180, textAlign: 'center' }} align="left">
        {active && (
          <PauseCircleOutlineIcon style={{ fontSize: 50 }} color="primary" />
        )}
        {!active && <PlayCircleOutlineIcon style={{ fontSize: 50 }} />}
      </TableCell>
      <TableCell align="left" onClick={(event) => event.stopPropagation()}>
        <Title active={!!active}>{playlistVideo.title}</Title>
        <BottomText active={!!active}>
          <a
            target="_blank"
            href={playlistVideo.url ?? '#'}
            style={{ color: '#fff' }}
            rel="noreferrer"
          >
            {playlistVideo.url}
          </a>
        </BottomText>

        {playlistVideo.isLive && (
          <BottomText active={!!active}>LIVE STREAM</BottomText>
        )}
        <ControlsContainer>
          <FormControlLabel
            style={{ flex: 1 }}
            control={
              <Checkbox
                checked={playlistVideo.greenScreen}
                onChange={handleGreenScreenChange}
                name="checkedB"
                color="primary"
                onClick={(event) => event.stopPropagation()}
              />
            }
            label="Green screen"
          />

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen size
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}%`}
              defaultValue={playlistVideo.screenSize}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              step={10}
              marks
              min={50}
              max={200}
              onChangeCommitted={handleScreenSizeChange}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen offset Y
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}cm`}
              defaultValue={playlistVideo.screenOffsetY}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}cm`}
              step={10}
              marks
              min={-250}
              max={250}
              onChangeCommitted={handleScreenOffsetYChange}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography style={{ flex: 1, textAlign: 'center' }}>
              Screen brightness
            </Typography>
            <Slider
              style={{ flex: 2, marginLeft: 8 }}
              getAriaValueText={(value) => `${value}`}
              defaultValue={playlistVideo.screenBrightness}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}`}
              step={0.1}
              marks
              min={0}
              max={1}
              onChangeCommitted={handleScreenBrightnessChange}
            />
          </SliderContainer>

          <FormControlLabel
            style={{ flex: 1, marginLeft: 8 }}
            control={
              <Checkbox
                checked={get(playlistVideo, 'audio.muted')}
                onChange={handleMuteAudioChange}
                color="primary"
                onClick={(event) => event.stopPropagation()}
              />
            }
            label="Mute audio"
          />
        </ControlsContainer>
      </TableCell>
      <TableCell align="right">
        {onRemove && !schedulerControlled && (
          <IconButton color="primary" onClick={handleRemove} size="large">
            <CloseIcon />
          </IconButton>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

TableRow.defaultProps = {
  active: false,
};

export default TableRow;
