import type { FC } from 'react';
import React, { useEffect } from 'react';
import CreateAccountCard from '@/components/CreateAccountCard';
import { logout } from '@/store/asyncThunks/auth';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import { useAppDispatch } from '@/store';
import CardPageContainer from './styled/CardPageContainer';

const CreateAccount: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <CardPageContainer>
      <CreateAccountCard />
    </CardPageContainer>
  );
};

export default Sentry.withErrorBoundary(CreateAccount, {
  fallback: ErrorFallbackComponent,
});
