import styled from 'styled-components';
import breakpoint from '../../../breakpoint';

export default styled.span`
  background-color: #fff;
  background-image: url('/assets/Google__G__Logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 46px;
  width: 46px;

  ${breakpoint('lg')`
     height: 54px;
     width: 54px;
  `}

  ${breakpoint('xl')`
       height: 64px;
       width: 64px;
  `}
`;
