import styled from 'styled-components';
import { tabletBreakpoint, smartphoneBreakpoint } from '@/breakpoint';

export default styled.div`
  ${tabletBreakpoint`
    display: none;
  `}

  ${smartphoneBreakpoint`
    display: none;
  `}
`;
