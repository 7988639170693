import styled from 'styled-components';
import { ListItem } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import ListItemText from './ListItemText';

export default withTheme(styled(ListItem)`
  border-left: ${(props) =>
    props.selected && `6px solid ${props.theme.palette.primary.main}`};

  ${ListItemText} {
    padding-left: ${(props) => props.selected && '10px'};
  }
`);
