import { createTheme, adaptV4Theme } from '@mui/material/styles';

const env = import.meta.env.REACT_APP_ENV as 'env' | 'dev-env' | 'staging-env';
const primaryColors = {
  'dev-env': '#00838f',
  env: '#FF5252',
  'staging-env': '#6a1b9a',
};

const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'dark',
      secondary: {
        main: '#000',
      },
      primary: {
        main: primaryColors[env],
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 300,
        md: 1280,
        lg: 1600,
        xl: 1920,
      },
    },
  })
);

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '28px',
};

theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '20px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '22px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '26px',
  },
};

theme.typography.h6 = {
  ...theme.typography.h6,
  fontSize: '14px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '17px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '20px',
  },
};

theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: '14px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '17px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '20px',
  },
};

theme.typography.button = {
  ...theme.typography.button,
  fontSize: '10px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '11px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '12px',
  },
};

export default theme;
