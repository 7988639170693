import type { FC } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import Head from '@/components/AddEffects/Head';
import CurrentVideo from '@/components/AddEffects/CurrentVideo';
import Effects from '@/components/AddEffects/Effects';
import Drawer from '@/components/Drawer';
import * as Sentry from '@sentry/react';
import ErrorFallbackComponent from '@/components/ErrorFallbackComponent';
import PanelContainer from '../styled/PanelContainer';
import useAddEffectsData from './useAddEffectsData';

const AddEffects: FC = () => {
  const params = useParams<{ id: string }>();
  useAddEffectsData();

  return (
    <>
      <Drawer itemPath="/add-effects" />
      {!!params.id && (
        <PanelContainer>
          <Head />
          <Divider />
          <CurrentVideo />
          <Divider />
          <Effects />
        </PanelContainer>
      )}
    </>
  );
};

export default Sentry.withErrorBoundary(AddEffects, {
  fallback: ErrorFallbackComponent,
});
