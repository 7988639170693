import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import drawerReducer from './drawer';
import stageReducer from './stage';
import dialogReducer from './dialog';
import userReducer from './user';
import effectReducer from './effect';
import userWithAccessReducer from './userWithAccess';
import campReducer from './camp';
import obsReducer from './obs';
import fireworkReducer from './fireworks';
import settingsReducer from './settings';
import teleportReducer from './teleport';
import imageReducer from './image';
import authReducer from './auth';
import flashMessageReducer from './flashMessage';
import errorMiddleware from './middleware/error';

export const store = configureStore({
  devTools: import.meta.env.REACT_APP_ENV !== 'production',
  reducer: {
    auth: authReducer,
    drawer: drawerReducer,
    stage: stageReducer,
    dialog: dialogReducer,
    user: userReducer,
    effect: effectReducer,
    userWithAccess: userWithAccessReducer,
    camp: campReducer,
    obs: obsReducer,
    firework: fireworkReducer,
    settings: settingsReducer,
    teleport: teleportReducer,
    image: imageReducer,
    flashMessage: flashMessageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(errorMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
