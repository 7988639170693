import type { FC } from 'react';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { TextFieldProps } from '@mui/material';
import { Divider, List, CircularProgress, TextField, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import type { OBSVideo } from '@myclipo/bm-admin-common';
import { OBSVideoServiceType } from '@myclipo/bm-admin-common';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce, last, sortBy } from 'lodash';
import { useAppDispatch } from '@/store';
import {
  selectIsVisibleOnMobile,
  selectDrawerObsLinks,
} from '@/store/selectors/drawer';
import { addOBSLink, getDrawerObsLinks } from '@/store/asyncThunks/drawer';
import Nav from '@/components/styled/Nav';
import ProgressContainer from '@/components/styled/ProgressContainer';
import Profile from '../Profile';
import StyledDrawer from '../styled/Drawer';
import AddLinkButton from '../styled/AddLinkButton';
import ListSubheader from '../styled/ListSubheader';
import ListItem from './ListItem';

const ObsDrawer: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const obsLinks = useSelector(selectDrawerObsLinks);
  const isVisibleOnMobile = useSelector(selectIsVisibleOnMobile);
  const [oldOBSLinks, setOldOBSLinks] = useState<OBSVideo[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOBSLinks, setFilteredOBSLinks] = useState<OBSVideo[]>([]);

  const filterOBSLinks = useMemo(
    () =>
      debounce((value: string) => {
        const newFilteredList = obsLinks.filter(
          (link) =>
            link.stages!.filter(
              (stage) =>
                stage.name.toLowerCase().includes(value.toLowerCase()) ||
                stage.id === value
            ).length > 0
        );

        const sorted = sortBy(newFilteredList, 'stages.0.name') as OBSVideo[];

        setFilteredOBSLinks(sorted);
      }, 500),
    [obsLinks]
  );

  useEffect(() => {
    if (searchValue.length === 0) {
      setFilteredOBSLinks(obsLinks);
    } else {
      filterOBSLinks(searchValue);
    }
  }, [searchValue, obsLinks, filterOBSLinks]);

  const handleSearchValueChange: TextFieldProps['onChange'] = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    setActiveItem(params.id ?? null);
  }, [params.id]);

  useEffect(() => {
    const promise = dispatch(getDrawerObsLinks());
    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const handleAddClick = useCallback(async () => {
    setIsAdding(true);
    await dispatch(
      addOBSLink({
        stageId: [],
        url: '',
        urlObs: '',
        serviceType: OBSVideoServiceType.Ivs,
      })
    );
    setIsAdding(false);
  }, [dispatch]);

  useEffect(() => {
    if (oldOBSLinks.length !== 0 && obsLinks.length > oldOBSLinks.length) {
      const lastLink = last(obsLinks);
      navigate(`/obs-configuration/${lastLink?.id}`);
    }
    setOldOBSLinks(obsLinks);
  }, [obsLinks, setOldOBSLinks, oldOBSLinks]);

  return (
    <StyledDrawer
      isVisibleOnMobile={isVisibleOnMobile}
      anchor="left"
      open
      variant="persistent"
    >
      <Profile />
      <Divider />
      <Box m={2} mx={4}>
        <TextField
          label="Search..."
          fullWidth
          value={searchValue}
          onChange={handleSearchValueChange}
        />
      </Box>
      {false && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}
      <List
        component={Nav}
        style={{ marginBottom: 120 }}
        subheader={<ListSubheader>Configuration list</ListSubheader>}
      >
        {filteredOBSLinks.map((obs) => (
          <ListItem key={obs.id} obs={obs} activeItem={activeItem} />
        ))}
      </List>
      <AddLinkButton
        onClick={handleAddClick}
        variant="contained"
        color="primary"
        disabled={isAdding}
      >
        Add configuration
      </AddLinkButton>
    </StyledDrawer>
  );
};

export default memo(ObsDrawer);
