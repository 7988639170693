import type { FC } from 'react';
import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import type { ReactPlayerProps } from 'react-player';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';
import useTabInactive from '@/hooks/useTabInactive';

const Player: FC<{ url?: string; type: 'obs' | 'backup-video' }> = ({
  url,
  type,
}) => {
  const playerRef = createRef<ReactPlayer>();
  const videoRef = createRef<HTMLVideoElement>();
  const [loaded, setLoaded] = useState(
    url && type === 'obs' && !Hls.isSupported()
  );
  const [playing, setPlaying] = useState(false);
  const [date, setDate] = useState(Date.now());
  const inactive = useTabInactive();

  useEffect(() => {
    if (loaded && !playing && !inactive) {
      setDate(Date.now());

      const interval = setInterval(() => {
        setDate(Date.now());
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [loaded, playing, inactive]);

  useEffect(() => {
    if (videoRef.current && !inactive) {
      videoRef.current.load();
    }
  }, [date, inactive]);

  const handleReady = useCallback(() => {
    const hls = playerRef.current?.getInternalPlayer('hls') as Hls;

    if (hls) {
      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              // eslint-disable-next-line no-console
              console.log('fatal network error encountered, try to recover');
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // eslint-disable-next-line no-console
              console.log('fatal media error encountered, try to recover');
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              hls.destroy();
              break;
          }
        }
      });
    }
    setLoaded(true);
  }, []);

  if (url) {
    if (type === 'obs' && !Hls.isSupported()) {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          ref={videoRef}
          preload="metadata"
          height="300px"
          playsInline
          controls
          muted
          width="100%"
          onPlay={() => {
            setPlaying(true);
          }}
          onPause={() => {
            setPlaying(false);
          }}
          onError={(...args) => {
            // eslint-disable-next-line no-console
            console.error('video', ...args);
          }}
        >
          <source src={url} type="application/vnd.apple.mpegurl" />
          <p>This browser does not support the video element.</p>
        </video>
      );
    }

    const reactPlayerProps: ReactPlayerProps = {
      ref: playerRef,
      width: '100%',
      controls: true,
      pip: true,
      onReady: handleReady,
      onPlay: () => {
        setPlaying(true);
      },
      onPause: () => {
        setPlaying(false);
      },
      onError: (...args) => {
        // eslint-disable-next-line no-console
        console.error('ReactPlayer', ...args);
      },
    };

    if (type === 'obs') {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ReactPlayer {...reactPlayerProps} url={`${url}?v=${date}`} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ReactPlayer {...reactPlayerProps} url={url} />;
  }

  return <div />;
};

export default memo(Player);
