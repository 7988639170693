import { createSelector } from '@reduxjs/toolkit';
import { selectOBSLoading, selectOBSVideos } from '@/store/selectors/obs';
import { concat } from 'lodash';
import type {
  BackupVideo,
  OBSVideo,
  SchedulerPlaylist,
  StreamImage,
} from '@myclipo/bm-admin-common';
import { selectImages, selectImagesLoading } from '@/store/selectors/image';
import type { RootState } from '..';

export const selectStage = (state: RootState) => state.stage.current;
export const selectStageNotFound = (state: RootState) =>
  state.stage.currentNotFound;
export const selectStageLoading = (state: RootState) =>
  state.stage.stageLoading;
export const selectVideosLoading = (state: RootState) =>
  state.stage.videosLoading;
export const selectLiveVideosLoading = (state: RootState) =>
  state.stage.liveVideosLoading;
export const selectBackupVideosLoading = (state: RootState) =>
  state.stage.backupVideosLoading;
export const selectUsersWithAccess = (state: RootState) =>
  state.stage.usersWithAccess;
export const selectVideos = (state: RootState) => state.stage.videos;
export const selectLiveVideos = (state: RootState) => state.stage.liveVideos;
export const selectBackupVideos = (state: RootState) =>
  state.stage.backupVideos;
export const selectCurrentlyPlayed = (state: RootState) =>
  state.stage.currentlyPlayed;
export const selectFestivalDates = (state: RootState) =>
  state.stage.festivalDates;
export const selectAcceptedInvites = (state: RootState) =>
  state.stage.acceptedInvites;
export const selectSchedulerPlaylist = (state: RootState) =>
  state.stage.schedulerPlaylist;
export const selectSchedulerPlaylistLoading = (state: RootState) =>
  state.stage.schedulerPlaylistLoading;
export const selectRateAdminStages = (state: RootState) =>
  state.stage.rateAdminStages;
export const selectAllVideos = createSelector(
  selectBackupVideos,
  selectSchedulerPlaylist,
  selectOBSVideos,
  selectImages,
  (backupVideos, schedulerPlaylist, obsVideos, images) =>
    concat<BackupVideo | SchedulerPlaylist | OBSVideo | StreamImage>(
      obsVideos,
      [schedulerPlaylist],
      backupVideos,
      images
    )
);
export const selectAllVideosLoading = createSelector(
  selectBackupVideosLoading,
  selectOBSLoading,
  selectImagesLoading,
  selectSchedulerPlaylistLoading,
  (backupVideosLoading, obsLoading, imagesLoading, schedulerPlaylistLoading) =>
    backupVideosLoading ||
    obsLoading ||
    imagesLoading ||
    schedulerPlaylistLoading
);
