import type { FC } from 'react';
import React, { useState, Suspense, useMemo } from 'react';
import Container from '../../CampAndStageCommon/styled/Container';
import Dialog from './Dialog';
import CampSchedule from '../../CampSchedule';
import useIsMobile from '../../../hooks/useIsMobile';

const ScheduledOnCamp: FC = () => {
  const [open, setOpen] = useState(false);
  const [currentDateTab, setCurrentDateTab] = useState<string>('');
  const isMobile = useIsMobile();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ButtonComponent = useMemo(
    () =>
      !isMobile
        ? React.lazy(() => import('../../styled/Button'))
        : React.lazy(() => import('../../styled/FullWidthButton')),
    [isMobile]
  );

  return (
    <>
      <Container>
        <CampSchedule onDateTabChange={setCurrentDateTab} />
        <Suspense fallback={<div />}>
          <ButtonComponent onClick={handleClickOpen}>
            Add link to camp
          </ButtonComponent>
        </Suspense>
      </Container>
      <Dialog onClose={handleClose} open={open} date={currentDateTab} />
    </>
  );
};

export default ScheduledOnCamp;
